// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcServices.proto" (package "grpcServices", syntax proto3)
// tslint:disable
import { Int64Value } from "./google/protobuf/wrappers";
import { Int32Value } from "./google/protobuf/wrappers";
import { BoolValue } from "./google/protobuf/wrappers";
import { StringValue } from "./google/protobuf/wrappers";
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcServices.DirInfo
 */
export interface DirInfo {
    /**
     * @generated from protobuf field: string FullSize = 1 [json_name = "FullSize"];
     */
    FullSize: string;
    /**
     * @generated from protobuf field: repeated grpcServices.DirInfoStruct Dirs = 2 [json_name = "Dirs"];
     */
    Dirs: DirInfoStruct[];
}
/**
 * @generated from protobuf message grpcServices.Filters
 */
export interface Filters {
    /**
     * @generated from protobuf field: string Name = 1 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: string Value = 2 [json_name = "Value"];
     */
    Value: string;
    /**
     * @generated from protobuf field: string Condition = 3 [json_name = "Condition"];
     */
    Condition: string;
}
/**
 * @generated from protobuf message grpcServices.ListPointerQueueInfoValue
 */
export interface ListPointerQueueInfoValue {
    /**
     * @generated from protobuf field: repeated grpcServices.QueueInfo Value = 1 [json_name = "Value"];
     */
    Value: QueueInfo[];
}
/**
 * @generated from protobuf message grpcServices.QueueInfo
 */
export interface QueueInfo {
    /**
     * @generated from protobuf field: string Queue = 1 [json_name = "Queue"];
     */
    Queue: string;
    /**
     * @generated from protobuf field: int64 MemoryUsage = 2 [json_name = "MemoryUsage"];
     */
    MemoryUsage: number;
    /**
     * @generated from protobuf field: int64 Latency = 3 [json_name = "Latency"];
     */
    Latency: number;
    /**
     * @generated from protobuf field: int64 Size = 4 [json_name = "Size"];
     */
    Size: number;
    /**
     * @generated from protobuf field: int64 Groups = 5 [json_name = "Groups"];
     */
    Groups: number;
    /**
     * @generated from protobuf field: int64 Pending = 6 [json_name = "Pending"];
     */
    Pending: number;
    /**
     * @generated from protobuf field: int64 Active = 7 [json_name = "Active"];
     */
    Active: number;
    /**
     * @generated from protobuf field: int64 Scheduled = 8 [json_name = "Scheduled"];
     */
    Scheduled: number;
    /**
     * @generated from protobuf field: int64 Retry = 9 [json_name = "Retry"];
     */
    Retry: number;
    /**
     * @generated from protobuf field: int64 Archived = 10 [json_name = "Archived"];
     */
    Archived: number;
    /**
     * @generated from protobuf field: int64 Completed = 11 [json_name = "Completed"];
     */
    Completed: number;
    /**
     * @generated from protobuf field: int64 Aggregating = 12 [json_name = "Aggregating"];
     */
    Aggregating: number;
    /**
     * @generated from protobuf field: int64 Processed = 13 [json_name = "Processed"];
     */
    Processed: number;
    /**
     * @generated from protobuf field: int64 Failed = 14 [json_name = "Failed"];
     */
    Failed: number;
    /**
     * @generated from protobuf field: int64 ProcessedTotal = 15 [json_name = "ProcessedTotal"];
     */
    ProcessedTotal: number;
    /**
     * @generated from protobuf field: int64 FailedTotal = 16 [json_name = "FailedTotal"];
     */
    FailedTotal: number;
    /**
     * @generated from protobuf field: bool Paused = 17 [json_name = "Paused"];
     */
    Paused: boolean;
    /**
     * @generated from protobuf field: string Timestamp = 18 [json_name = "Timestamp"];
     */
    Timestamp: string;
}
/**
 * @generated from protobuf message grpcServices.ListTaskDefValue
 */
export interface ListTaskDefValue {
    /**
     * @generated from protobuf field: repeated grpcServices.TaskDef Value = 1 [json_name = "Value"];
     */
    Value: TaskDef[];
}
/**
 * @generated from protobuf message grpcServices.ChainLinkDef
 */
export interface ChainLinkDef {
    /**
     * @generated from protobuf field: repeated grpcServices.TaskDef Tasks = 1 [json_name = "Tasks"];
     */
    Tasks: TaskDef[];
}
/**
 * @generated from protobuf message grpcServices.DirInfoStruct
 */
export interface DirInfoStruct {
    /**
     * @generated from protobuf field: string Queue = 1 [json_name = "Queue"];
     */
    Queue: string;
    /**
     * @generated from protobuf field: string Name = 2 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: string Size = 3 [json_name = "Size"];
     */
    Size: string;
    /**
     * @generated from protobuf field: string Date = 4 [json_name = "Date"];
     */
    Date: string;
}
/**
 * @generated from protobuf message grpcServices.SchedulerTable
 */
export interface SchedulerTable {
    /**
     * @generated from protobuf field: int64 Id = 1 [json_name = "Id"];
     */
    Id: number;
    /**
     * @generated from protobuf field: string Model = 2 [json_name = "Model"];
     */
    Model: string;
    /**
     * @generated from protobuf field: string Cron = 3 [json_name = "Cron"];
     */
    Cron: string;
    /**
     * @generated from protobuf field: bool Active = 4 [json_name = "Active"];
     */
    Active: boolean;
    /**
     * @generated from protobuf field: repeated string Next = 5 [json_name = "Next"];
     */
    Next: string[];
    /**
     * @generated from protobuf field: repeated string Last = 6 [json_name = "Last"];
     */
    Last: string[];
    /**
     * @generated from protobuf field: string Desc = 7 [json_name = "Desc"];
     */
    Desc: string;
}
/**
 * @generated from protobuf message grpcServices.TaskInfo
 */
export interface TaskInfo {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    ID: string;
    /**
     * @generated from protobuf field: string Queue = 2 [json_name = "Queue"];
     */
    Queue: string;
    /**
     * @generated from protobuf field: string Type = 3 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: string Payload = 4 [json_name = "Payload"];
     */
    Payload: string;
    /**
     * @generated from protobuf field: int64 State = 5 [json_name = "State"];
     */
    State: number;
    /**
     * @generated from protobuf field: int64 MaxRetry = 6 [json_name = "MaxRetry"];
     */
    MaxRetry: number;
    /**
     * @generated from protobuf field: int64 Retried = 7 [json_name = "Retried"];
     */
    Retried: number;
    /**
     * @generated from protobuf field: string LastErr = 8 [json_name = "LastErr"];
     */
    LastErr: string;
    /**
     * @generated from protobuf field: string LastFailedAt = 9 [json_name = "LastFailedAt"];
     */
    LastFailedAt: string;
    /**
     * @generated from protobuf field: int64 Timeout = 10 [json_name = "Timeout"];
     */
    Timeout: number;
    /**
     * @generated from protobuf field: string Deadline = 11 [json_name = "Deadline"];
     */
    Deadline: string;
    /**
     * @generated from protobuf field: string Group = 12 [json_name = "Group"];
     */
    Group: string;
    /**
     * @generated from protobuf field: string NextProcessAt = 13 [json_name = "NextProcessAt"];
     */
    NextProcessAt: string;
    /**
     * @generated from protobuf field: bool IsOrphaned = 14 [json_name = "IsOrphaned"];
     */
    IsOrphaned: boolean;
    /**
     * @generated from protobuf field: int64 Retention = 15 [json_name = "Retention"];
     */
    Retention: number;
    /**
     * @generated from protobuf field: string CompletedAt = 16 [json_name = "CompletedAt"];
     */
    CompletedAt: string;
    /**
     * @generated from protobuf field: string Result = 17 [json_name = "Result"];
     */
    Result: string;
}
/**
 * @generated from protobuf message grpcServices.ListChainLinkDefValue
 */
export interface ListChainLinkDefValue {
    /**
     * @generated from protobuf field: repeated grpcServices.ChainLinkDef Value = 1 [json_name = "Value"];
     */
    Value: ChainLinkDef[];
}
/**
 * @generated from protobuf message grpcServices.ChainDef
 */
export interface ChainDef {
    /**
     * @generated from protobuf field: repeated grpcServices.ChainLinkDef ChainLinks = 1 [json_name = "ChainLinks"];
     */
    ChainLinks: ChainLinkDef[];
}
/**
 * @generated from protobuf message grpcServices.ListFiltersValue
 */
export interface ListFiltersValue {
    /**
     * @generated from protobuf field: repeated grpcServices.Filters Value = 1 [json_name = "Value"];
     */
    Value: Filters[];
}
/**
 * @generated from protobuf message grpcServices.SchedulerTableResult
 */
export interface SchedulerTableResult {
    /**
     * @generated from protobuf field: repeated grpcServices.SchedulerTable Row = 1 [json_name = "Row"];
     */
    Row: SchedulerTable[];
    /**
     * @generated from protobuf field: int64 Count = 2 [json_name = "Count"];
     */
    Count: number;
}
/**
 * @generated from protobuf message grpcServices.TaskStreamMessage
 */
export interface TaskStreamMessage {
    /**
     * @generated from protobuf field: repeated grpcServices.TaskInfo Tasks = 1 [json_name = "Tasks"];
     */
    Tasks: TaskInfo[];
}
/**
 * @generated from protobuf message grpcServices.JobInfo
 */
export interface JobInfo {
    /**
     * @generated from protobuf field: int64 OriginalId = 1 [json_name = "OriginalId"];
     */
    OriginalId: number;
    /**
     * @generated from protobuf field: int64 Id = 2 [json_name = "Id"];
     */
    Id: number;
    /**
     * @generated from protobuf field: string Model = 3 [json_name = "Model"];
     */
    Model: string;
    /**
     * @generated from protobuf field: string Cron = 4 [json_name = "Cron"];
     */
    Cron: string;
    /**
     * @generated from protobuf field: bool Active = 5 [json_name = "Active"];
     */
    Active: boolean;
    /**
     * @generated from protobuf field: repeated string Next = 6 [json_name = "Next"];
     */
    Next: string[];
    /**
     * @generated from protobuf field: repeated string Last = 7 [json_name = "Last"];
     */
    Last: string[];
}
/**
 * @generated from protobuf message grpcServices.QueueServerInfo
 */
export interface QueueServerInfo {
    /**
     * @generated from protobuf field: repeated grpcServices.QueueInfo Queues = 1 [json_name = "Queues"];
     */
    Queues: QueueInfo[];
}
/**
 * @generated from protobuf message grpcServices.ListSortsValue
 */
export interface ListSortsValue {
    /**
     * @generated from protobuf field: repeated grpcServices.Sorts Value = 1 [json_name = "Value"];
     */
    Value: Sorts[];
}
/**
 * @generated from protobuf message grpcServices.ListPointerTaskInfoValue
 */
export interface ListPointerTaskInfoValue {
    /**
     * @generated from protobuf field: repeated grpcServices.TaskInfo Value = 1 [json_name = "Value"];
     */
    Value: TaskInfo[];
}
/**
 * @generated from protobuf message grpcServices.ListGetQueueMessRowValue
 */
export interface ListGetQueueMessRowValue {
    /**
     * @generated from protobuf field: repeated grpcServices.GetQueueMessRow Value = 1 [json_name = "Value"];
     */
    Value: GetQueueMessRow[];
}
/**
 * @generated from protobuf message grpcServices.GetQueueMessRow
 */
export interface GetQueueMessRow {
    /**
     * @generated from protobuf field: string Date = 1 [json_name = "Date"];
     */
    Date: string;
    /**
     * @generated from protobuf field: string Message = 2 [json_name = "Message"];
     */
    Message: string;
}
/**
 * @generated from protobuf message grpcServices.JobList
 */
export interface JobList {
    /**
     * @generated from protobuf field: repeated grpcServices.JobInfo List = 1 [json_name = "List"];
     */
    List: JobInfo[];
}
/**
 * @generated from protobuf message grpcServices.ListDirInfoStructValue
 */
export interface ListDirInfoStructValue {
    /**
     * @generated from protobuf field: repeated grpcServices.DirInfoStruct Value = 1 [json_name = "Value"];
     */
    Value: DirInfoStruct[];
}
/**
 * @generated from protobuf message grpcServices.RunJobInput
 */
export interface RunJobInput {
    /**
     * @generated from protobuf field: int64 TaskId = 1 [json_name = "TaskId"];
     */
    TaskId: number;
    /**
     * @generated from protobuf field: string Model = 2 [json_name = "Model"];
     */
    Model: string;
    /**
     * @generated from protobuf field: bool Prepare = 3 [json_name = "Prepare"];
     */
    Prepare: boolean;
}
/**
 * @generated from protobuf message grpcServices.Sorts
 */
export interface Sorts {
    /**
     * @generated from protobuf field: string Name = 1 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: bool Desc = 2 [json_name = "Desc"];
     */
    Desc: boolean;
}
/**
 * @generated from protobuf message grpcServices.QueryData
 */
export interface QueryData {
    /**
     * @generated from protobuf field: int64 Page = 1 [json_name = "Page"];
     */
    Page: number;
    /**
     * @generated from protobuf field: int64 OnPage = 2 [json_name = "OnPage"];
     */
    OnPage: number;
    /**
     * @generated from protobuf field: repeated grpcServices.Sorts Sort = 3 [json_name = "Sort"];
     */
    Sort: Sorts[];
    /**
     * @generated from protobuf field: repeated grpcServices.Filters Filters = 4 [json_name = "Filters"];
     */
    Filters: Filters[];
}
/**
 * @generated from protobuf message grpcServices.ListByteValue
 */
export interface ListByteValue {
    /**
     * @generated from protobuf field: string Value = 1 [json_name = "Value"];
     */
    Value: string;
}
/**
 * @generated from protobuf message grpcServices.MapStringQueueDescriptionValue
 */
export interface MapStringQueueDescriptionValue {
    /**
     * @generated from protobuf field: map<string, grpcServices.QueueDescription> Value = 1 [json_name = "Value"];
     */
    Value: {
        [key: string]: QueueDescription;
    };
}
/**
 * @generated from protobuf message grpcServices.CancelTaskInput
 */
export interface CancelTaskInput {
    /**
     * @generated from protobuf field: string QueueName = 1 [json_name = "QueueName"];
     */
    QueueName: string;
    /**
     * @generated from protobuf field: string TaskID = 2 [json_name = "TaskID"];
     */
    TaskID: string;
}
/**
 * @generated from protobuf message grpcServices.CronNextInput
 */
export interface CronNextInput {
    /**
     * @generated from protobuf field: string Cron = 1 [json_name = "Cron"];
     */
    Cron: string;
    /**
     * @generated from protobuf field: int64 N = 2 [json_name = "N"];
     */
    N: number;
}
/**
 * @generated from protobuf message grpcServices.ListStringValue
 */
export interface ListStringValue {
    /**
     * @generated from protobuf field: repeated string Value = 1 [json_name = "Value"];
     */
    Value: string[];
}
/**
 * @generated from protobuf message grpcServices.JobInput
 */
export interface JobInput {
    /**
     * @generated from protobuf field: int64 TaskId = 1 [json_name = "TaskId"];
     */
    TaskId: number;
    /**
     * @generated from protobuf field: string Model = 2 [json_name = "Model"];
     */
    Model: string;
    /**
     * @generated from protobuf field: string Cron = 3 [json_name = "Cron"];
     */
    Cron: string;
    /**
     * @generated from protobuf field: bool Active = 4 [json_name = "Active"];
     */
    Active: boolean;
    /**
     * @generated from protobuf field: bool Instant = 5 [json_name = "Instant"];
     */
    Instant: boolean;
}
/**
 * @generated from protobuf message grpcServices.GetTasksListInput
 */
export interface GetTasksListInput {
    /**
     * @generated from protobuf field: string Queue = 1 [json_name = "Queue"];
     */
    Queue: string;
    /**
     * @generated from protobuf field: repeated string Type = 2 [json_name = "Type"];
     */
    Type: string[];
    /**
     * @generated from protobuf field: int64 Offset = 3 [json_name = "Offset"];
     */
    Offset: number;
}
/**
 * @generated from protobuf message grpcServices.QueueDescription
 */
export interface QueueDescription {
    /**
     * @generated from protobuf field: string FriendlyName = 1 [json_name = "FriendlyName"];
     */
    FriendlyName: string;
    /**
     * @generated from protobuf field: string Description = 2 [json_name = "Description"];
     */
    Description: string;
    /**
     * @generated from protobuf field: string SystemName = 3 [json_name = "SystemName"];
     */
    SystemName: string;
}
/**
 * @generated from protobuf message grpcServices.TaskDef
 */
export interface TaskDef {
    /**
     * @generated from protobuf field: string QueueName = 1 [json_name = "QueueName"];
     */
    QueueName: string;
    /**
     * @generated from protobuf field: string MsgConfig = 2 [json_name = "MsgConfig"];
     */
    MsgConfig: string;
}
/**
 * @generated from protobuf message grpcServices.ListSchedulerTableValue
 */
export interface ListSchedulerTableValue {
    /**
     * @generated from protobuf field: repeated grpcServices.SchedulerTable Value = 1 [json_name = "Value"];
     */
    Value: SchedulerTable[];
}
/**
 * @generated from protobuf message grpcServices.GetQueueMessInput
 */
export interface GetQueueMessInput {
    /**
     * @generated from protobuf field: string QueueName = 1 [json_name = "QueueName"];
     */
    QueueName: string;
    /**
     * @generated from protobuf field: string TaskId = 2 [json_name = "TaskId"];
     */
    TaskId: string;
}
/**
 * @generated from protobuf message grpcServices.ListJobInfoValue
 */
export interface ListJobInfoValue {
    /**
     * @generated from protobuf field: repeated grpcServices.JobInfo Value = 1 [json_name = "Value"];
     */
    Value: JobInfo[];
}
/**
 * @generated from protobuf message grpcServices.GetJobInput
 */
export interface GetJobInput {
    /**
     * @generated from protobuf field: int64 TaskId = 1 [json_name = "TaskId"];
     */
    TaskId: number;
    /**
     * @generated from protobuf field: string Model = 2 [json_name = "Model"];
     */
    Model: string;
}
/**
 * @generated from protobuf enum grpcServices.TaskState
 */
export enum TaskState {
    /**
     *
     * 	TaskStateActive = 1;
     *
     * 	TaskStateAggregating = 2;
     *
     * 	TaskStateArchived = 3;
     *
     * 	TaskStateCompleted = 4;
     *
     * 	TaskStatePending = 5;
     *
     * 	TaskStateRetry = 6;
     *
     * 	TaskStateScheduled = 7;
     *
     *
     * @generated from protobuf enum value: TaskState_ValueFiller = 0;
     */
    TaskState_ValueFiller = 0
}
/**
 * @generated from protobuf enum grpcServices.Duration
 */
export enum Duration {
    /**
     *
     * 	Hour = 1;
     *
     * 	Microsecond = 2;
     *
     * 	Millisecond = 3;
     *
     * 	Minute = 4;
     *
     * 	Nanosecond = 5;
     *
     * 	Second = 6;
     *
     * 	maxDuration = 7;
     *
     * 	minDuration = 8;
     *
     *
     * @generated from protobuf enum value: Duration_ValueFiller = 0;
     */
    Duration_ValueFiller = 0
}
// @generated message type with reflection information, may provide speed optimized methods
class DirInfo$Type extends MessageType<DirInfo> {
    constructor() {
        super("grpcServices.DirInfo", [
            { no: 1, name: "FullSize", kind: "scalar", localName: "FullSize", jsonName: "FullSize", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Dirs", kind: "message", localName: "Dirs", jsonName: "Dirs", repeat: 1 /*RepeatType.PACKED*/, T: () => DirInfoStruct }
        ]);
    }
    create(value?: PartialMessage<DirInfo>): DirInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.FullSize = "";
        message.Dirs = [];
        if (value !== undefined)
            reflectionMergePartial<DirInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DirInfo): DirInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string FullSize = 1 [json_name = "FullSize"];*/ 1:
                    message.FullSize = reader.string();
                    break;
                case /* repeated grpcServices.DirInfoStruct Dirs = 2 [json_name = "Dirs"];*/ 2:
                    message.Dirs.push(DirInfoStruct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DirInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string FullSize = 1 [json_name = "FullSize"]; */
        if (message.FullSize !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.FullSize);
        /* repeated grpcServices.DirInfoStruct Dirs = 2 [json_name = "Dirs"]; */
        for (let i = 0; i < message.Dirs.length; i++)
            DirInfoStruct.internalBinaryWrite(message.Dirs[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.DirInfo
 */
export const DirInfo = new DirInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filters$Type extends MessageType<Filters> {
    constructor() {
        super("grpcServices.Filters", [
            { no: 1, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Condition", kind: "scalar", localName: "Condition", jsonName: "Condition", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Filters>): Filters {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Name = "";
        message.Value = "";
        message.Condition = "";
        if (value !== undefined)
            reflectionMergePartial<Filters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filters): Filters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Name = 1 [json_name = "Name"];*/ 1:
                    message.Name = reader.string();
                    break;
                case /* string Value = 2 [json_name = "Value"];*/ 2:
                    message.Value = reader.string();
                    break;
                case /* string Condition = 3 [json_name = "Condition"];*/ 3:
                    message.Condition = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Name = 1 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Name);
        /* string Value = 2 [json_name = "Value"]; */
        if (message.Value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Value);
        /* string Condition = 3 [json_name = "Condition"]; */
        if (message.Condition !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Condition);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.Filters
 */
export const Filters = new Filters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPointerQueueInfoValue$Type extends MessageType<ListPointerQueueInfoValue> {
    constructor() {
        super("grpcServices.ListPointerQueueInfoValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => QueueInfo }
        ]);
    }
    create(value?: PartialMessage<ListPointerQueueInfoValue>): ListPointerQueueInfoValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListPointerQueueInfoValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPointerQueueInfoValue): ListPointerQueueInfoValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.QueueInfo Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(QueueInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPointerQueueInfoValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.QueueInfo Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            QueueInfo.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListPointerQueueInfoValue
 */
export const ListPointerQueueInfoValue = new ListPointerQueueInfoValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueueInfo$Type extends MessageType<QueueInfo> {
    constructor() {
        super("grpcServices.QueueInfo", [
            { no: 1, name: "Queue", kind: "scalar", localName: "Queue", jsonName: "Queue", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "MemoryUsage", kind: "scalar", localName: "MemoryUsage", jsonName: "MemoryUsage", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Latency", kind: "scalar", localName: "Latency", jsonName: "Latency", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "Size", kind: "scalar", localName: "Size", jsonName: "Size", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "Groups", kind: "scalar", localName: "Groups", jsonName: "Groups", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "Pending", kind: "scalar", localName: "Pending", jsonName: "Pending", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "Active", kind: "scalar", localName: "Active", jsonName: "Active", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "Scheduled", kind: "scalar", localName: "Scheduled", jsonName: "Scheduled", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "Retry", kind: "scalar", localName: "Retry", jsonName: "Retry", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "Archived", kind: "scalar", localName: "Archived", jsonName: "Archived", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "Completed", kind: "scalar", localName: "Completed", jsonName: "Completed", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "Aggregating", kind: "scalar", localName: "Aggregating", jsonName: "Aggregating", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "Processed", kind: "scalar", localName: "Processed", jsonName: "Processed", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "Failed", kind: "scalar", localName: "Failed", jsonName: "Failed", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "ProcessedTotal", kind: "scalar", localName: "ProcessedTotal", jsonName: "ProcessedTotal", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "FailedTotal", kind: "scalar", localName: "FailedTotal", jsonName: "FailedTotal", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "Paused", kind: "scalar", localName: "Paused", jsonName: "Paused", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "Timestamp", kind: "scalar", localName: "Timestamp", jsonName: "Timestamp", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<QueueInfo>): QueueInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Queue = "";
        message.MemoryUsage = 0;
        message.Latency = 0;
        message.Size = 0;
        message.Groups = 0;
        message.Pending = 0;
        message.Active = 0;
        message.Scheduled = 0;
        message.Retry = 0;
        message.Archived = 0;
        message.Completed = 0;
        message.Aggregating = 0;
        message.Processed = 0;
        message.Failed = 0;
        message.ProcessedTotal = 0;
        message.FailedTotal = 0;
        message.Paused = false;
        message.Timestamp = "";
        if (value !== undefined)
            reflectionMergePartial<QueueInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueueInfo): QueueInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Queue = 1 [json_name = "Queue"];*/ 1:
                    message.Queue = reader.string();
                    break;
                case /* int64 MemoryUsage = 2 [json_name = "MemoryUsage"];*/ 2:
                    message.MemoryUsage = reader.int64().toNumber();
                    break;
                case /* int64 Latency = 3 [json_name = "Latency"];*/ 3:
                    message.Latency = reader.int64().toNumber();
                    break;
                case /* int64 Size = 4 [json_name = "Size"];*/ 4:
                    message.Size = reader.int64().toNumber();
                    break;
                case /* int64 Groups = 5 [json_name = "Groups"];*/ 5:
                    message.Groups = reader.int64().toNumber();
                    break;
                case /* int64 Pending = 6 [json_name = "Pending"];*/ 6:
                    message.Pending = reader.int64().toNumber();
                    break;
                case /* int64 Active = 7 [json_name = "Active"];*/ 7:
                    message.Active = reader.int64().toNumber();
                    break;
                case /* int64 Scheduled = 8 [json_name = "Scheduled"];*/ 8:
                    message.Scheduled = reader.int64().toNumber();
                    break;
                case /* int64 Retry = 9 [json_name = "Retry"];*/ 9:
                    message.Retry = reader.int64().toNumber();
                    break;
                case /* int64 Archived = 10 [json_name = "Archived"];*/ 10:
                    message.Archived = reader.int64().toNumber();
                    break;
                case /* int64 Completed = 11 [json_name = "Completed"];*/ 11:
                    message.Completed = reader.int64().toNumber();
                    break;
                case /* int64 Aggregating = 12 [json_name = "Aggregating"];*/ 12:
                    message.Aggregating = reader.int64().toNumber();
                    break;
                case /* int64 Processed = 13 [json_name = "Processed"];*/ 13:
                    message.Processed = reader.int64().toNumber();
                    break;
                case /* int64 Failed = 14 [json_name = "Failed"];*/ 14:
                    message.Failed = reader.int64().toNumber();
                    break;
                case /* int64 ProcessedTotal = 15 [json_name = "ProcessedTotal"];*/ 15:
                    message.ProcessedTotal = reader.int64().toNumber();
                    break;
                case /* int64 FailedTotal = 16 [json_name = "FailedTotal"];*/ 16:
                    message.FailedTotal = reader.int64().toNumber();
                    break;
                case /* bool Paused = 17 [json_name = "Paused"];*/ 17:
                    message.Paused = reader.bool();
                    break;
                case /* string Timestamp = 18 [json_name = "Timestamp"];*/ 18:
                    message.Timestamp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueueInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Queue = 1 [json_name = "Queue"]; */
        if (message.Queue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Queue);
        /* int64 MemoryUsage = 2 [json_name = "MemoryUsage"]; */
        if (message.MemoryUsage !== 0)
            writer.tag(2, WireType.Varint).int64(message.MemoryUsage);
        /* int64 Latency = 3 [json_name = "Latency"]; */
        if (message.Latency !== 0)
            writer.tag(3, WireType.Varint).int64(message.Latency);
        /* int64 Size = 4 [json_name = "Size"]; */
        if (message.Size !== 0)
            writer.tag(4, WireType.Varint).int64(message.Size);
        /* int64 Groups = 5 [json_name = "Groups"]; */
        if (message.Groups !== 0)
            writer.tag(5, WireType.Varint).int64(message.Groups);
        /* int64 Pending = 6 [json_name = "Pending"]; */
        if (message.Pending !== 0)
            writer.tag(6, WireType.Varint).int64(message.Pending);
        /* int64 Active = 7 [json_name = "Active"]; */
        if (message.Active !== 0)
            writer.tag(7, WireType.Varint).int64(message.Active);
        /* int64 Scheduled = 8 [json_name = "Scheduled"]; */
        if (message.Scheduled !== 0)
            writer.tag(8, WireType.Varint).int64(message.Scheduled);
        /* int64 Retry = 9 [json_name = "Retry"]; */
        if (message.Retry !== 0)
            writer.tag(9, WireType.Varint).int64(message.Retry);
        /* int64 Archived = 10 [json_name = "Archived"]; */
        if (message.Archived !== 0)
            writer.tag(10, WireType.Varint).int64(message.Archived);
        /* int64 Completed = 11 [json_name = "Completed"]; */
        if (message.Completed !== 0)
            writer.tag(11, WireType.Varint).int64(message.Completed);
        /* int64 Aggregating = 12 [json_name = "Aggregating"]; */
        if (message.Aggregating !== 0)
            writer.tag(12, WireType.Varint).int64(message.Aggregating);
        /* int64 Processed = 13 [json_name = "Processed"]; */
        if (message.Processed !== 0)
            writer.tag(13, WireType.Varint).int64(message.Processed);
        /* int64 Failed = 14 [json_name = "Failed"]; */
        if (message.Failed !== 0)
            writer.tag(14, WireType.Varint).int64(message.Failed);
        /* int64 ProcessedTotal = 15 [json_name = "ProcessedTotal"]; */
        if (message.ProcessedTotal !== 0)
            writer.tag(15, WireType.Varint).int64(message.ProcessedTotal);
        /* int64 FailedTotal = 16 [json_name = "FailedTotal"]; */
        if (message.FailedTotal !== 0)
            writer.tag(16, WireType.Varint).int64(message.FailedTotal);
        /* bool Paused = 17 [json_name = "Paused"]; */
        if (message.Paused !== false)
            writer.tag(17, WireType.Varint).bool(message.Paused);
        /* string Timestamp = 18 [json_name = "Timestamp"]; */
        if (message.Timestamp !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.Timestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.QueueInfo
 */
export const QueueInfo = new QueueInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTaskDefValue$Type extends MessageType<ListTaskDefValue> {
    constructor() {
        super("grpcServices.ListTaskDefValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => TaskDef }
        ]);
    }
    create(value?: PartialMessage<ListTaskDefValue>): ListTaskDefValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListTaskDefValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTaskDefValue): ListTaskDefValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.TaskDef Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(TaskDef.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTaskDefValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.TaskDef Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            TaskDef.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListTaskDefValue
 */
export const ListTaskDefValue = new ListTaskDefValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChainLinkDef$Type extends MessageType<ChainLinkDef> {
    constructor() {
        super("grpcServices.ChainLinkDef", [
            { no: 1, name: "Tasks", kind: "message", localName: "Tasks", jsonName: "Tasks", repeat: 1 /*RepeatType.PACKED*/, T: () => TaskDef }
        ]);
    }
    create(value?: PartialMessage<ChainLinkDef>): ChainLinkDef {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Tasks = [];
        if (value !== undefined)
            reflectionMergePartial<ChainLinkDef>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChainLinkDef): ChainLinkDef {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.TaskDef Tasks = 1 [json_name = "Tasks"];*/ 1:
                    message.Tasks.push(TaskDef.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChainLinkDef, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.TaskDef Tasks = 1 [json_name = "Tasks"]; */
        for (let i = 0; i < message.Tasks.length; i++)
            TaskDef.internalBinaryWrite(message.Tasks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ChainLinkDef
 */
export const ChainLinkDef = new ChainLinkDef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DirInfoStruct$Type extends MessageType<DirInfoStruct> {
    constructor() {
        super("grpcServices.DirInfoStruct", [
            { no: 1, name: "Queue", kind: "scalar", localName: "Queue", jsonName: "Queue", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Size", kind: "scalar", localName: "Size", jsonName: "Size", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Date", kind: "scalar", localName: "Date", jsonName: "Date", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DirInfoStruct>): DirInfoStruct {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Queue = "";
        message.Name = "";
        message.Size = "";
        message.Date = "";
        if (value !== undefined)
            reflectionMergePartial<DirInfoStruct>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DirInfoStruct): DirInfoStruct {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Queue = 1 [json_name = "Queue"];*/ 1:
                    message.Queue = reader.string();
                    break;
                case /* string Name = 2 [json_name = "Name"];*/ 2:
                    message.Name = reader.string();
                    break;
                case /* string Size = 3 [json_name = "Size"];*/ 3:
                    message.Size = reader.string();
                    break;
                case /* string Date = 4 [json_name = "Date"];*/ 4:
                    message.Date = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DirInfoStruct, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Queue = 1 [json_name = "Queue"]; */
        if (message.Queue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Queue);
        /* string Name = 2 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Name);
        /* string Size = 3 [json_name = "Size"]; */
        if (message.Size !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Size);
        /* string Date = 4 [json_name = "Date"]; */
        if (message.Date !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Date);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.DirInfoStruct
 */
export const DirInfoStruct = new DirInfoStruct$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchedulerTable$Type extends MessageType<SchedulerTable> {
    constructor() {
        super("grpcServices.SchedulerTable", [
            { no: 1, name: "Id", kind: "scalar", localName: "Id", jsonName: "Id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Cron", kind: "scalar", localName: "Cron", jsonName: "Cron", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Active", kind: "scalar", localName: "Active", jsonName: "Active", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "Next", kind: "scalar", localName: "Next", jsonName: "Next", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "Last", kind: "scalar", localName: "Last", jsonName: "Last", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "Desc", kind: "scalar", localName: "Desc", jsonName: "Desc", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SchedulerTable>): SchedulerTable {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Id = 0;
        message.Model = "";
        message.Cron = "";
        message.Active = false;
        message.Next = [];
        message.Last = [];
        message.Desc = "";
        if (value !== undefined)
            reflectionMergePartial<SchedulerTable>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SchedulerTable): SchedulerTable {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Id = 1 [json_name = "Id"];*/ 1:
                    message.Id = reader.int64().toNumber();
                    break;
                case /* string Model = 2 [json_name = "Model"];*/ 2:
                    message.Model = reader.string();
                    break;
                case /* string Cron = 3 [json_name = "Cron"];*/ 3:
                    message.Cron = reader.string();
                    break;
                case /* bool Active = 4 [json_name = "Active"];*/ 4:
                    message.Active = reader.bool();
                    break;
                case /* repeated string Next = 5 [json_name = "Next"];*/ 5:
                    message.Next.push(reader.string());
                    break;
                case /* repeated string Last = 6 [json_name = "Last"];*/ 6:
                    message.Last.push(reader.string());
                    break;
                case /* string Desc = 7 [json_name = "Desc"];*/ 7:
                    message.Desc = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SchedulerTable, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Id = 1 [json_name = "Id"]; */
        if (message.Id !== 0)
            writer.tag(1, WireType.Varint).int64(message.Id);
        /* string Model = 2 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Model);
        /* string Cron = 3 [json_name = "Cron"]; */
        if (message.Cron !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Cron);
        /* bool Active = 4 [json_name = "Active"]; */
        if (message.Active !== false)
            writer.tag(4, WireType.Varint).bool(message.Active);
        /* repeated string Next = 5 [json_name = "Next"]; */
        for (let i = 0; i < message.Next.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.Next[i]);
        /* repeated string Last = 6 [json_name = "Last"]; */
        for (let i = 0; i < message.Last.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.Last[i]);
        /* string Desc = 7 [json_name = "Desc"]; */
        if (message.Desc !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.Desc);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.SchedulerTable
 */
export const SchedulerTable = new SchedulerTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskInfo$Type extends MessageType<TaskInfo> {
    constructor() {
        super("grpcServices.TaskInfo", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Queue", kind: "scalar", localName: "Queue", jsonName: "Queue", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Payload", kind: "scalar", localName: "Payload", jsonName: "Payload", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "State", kind: "scalar", localName: "State", jsonName: "State", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "MaxRetry", kind: "scalar", localName: "MaxRetry", jsonName: "MaxRetry", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "Retried", kind: "scalar", localName: "Retried", jsonName: "Retried", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "LastErr", kind: "scalar", localName: "LastErr", jsonName: "LastErr", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "LastFailedAt", kind: "scalar", localName: "LastFailedAt", jsonName: "LastFailedAt", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "Timeout", kind: "scalar", localName: "Timeout", jsonName: "Timeout", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "Deadline", kind: "scalar", localName: "Deadline", jsonName: "Deadline", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "Group", kind: "scalar", localName: "Group", jsonName: "Group", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "NextProcessAt", kind: "scalar", localName: "NextProcessAt", jsonName: "NextProcessAt", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "IsOrphaned", kind: "scalar", localName: "IsOrphaned", jsonName: "IsOrphaned", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "Retention", kind: "scalar", localName: "Retention", jsonName: "Retention", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "CompletedAt", kind: "scalar", localName: "CompletedAt", jsonName: "CompletedAt", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "Result", kind: "scalar", localName: "Result", jsonName: "Result", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TaskInfo>): TaskInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        message.Queue = "";
        message.Type = "";
        message.Payload = "";
        message.State = 0;
        message.MaxRetry = 0;
        message.Retried = 0;
        message.LastErr = "";
        message.LastFailedAt = "";
        message.Timeout = 0;
        message.Deadline = "";
        message.Group = "";
        message.NextProcessAt = "";
        message.IsOrphaned = false;
        message.Retention = 0;
        message.CompletedAt = "";
        message.Result = "";
        if (value !== undefined)
            reflectionMergePartial<TaskInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskInfo): TaskInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.string();
                    break;
                case /* string Queue = 2 [json_name = "Queue"];*/ 2:
                    message.Queue = reader.string();
                    break;
                case /* string Type = 3 [json_name = "Type"];*/ 3:
                    message.Type = reader.string();
                    break;
                case /* string Payload = 4 [json_name = "Payload"];*/ 4:
                    message.Payload = reader.string();
                    break;
                case /* int64 State = 5 [json_name = "State"];*/ 5:
                    message.State = reader.int64().toNumber();
                    break;
                case /* int64 MaxRetry = 6 [json_name = "MaxRetry"];*/ 6:
                    message.MaxRetry = reader.int64().toNumber();
                    break;
                case /* int64 Retried = 7 [json_name = "Retried"];*/ 7:
                    message.Retried = reader.int64().toNumber();
                    break;
                case /* string LastErr = 8 [json_name = "LastErr"];*/ 8:
                    message.LastErr = reader.string();
                    break;
                case /* string LastFailedAt = 9 [json_name = "LastFailedAt"];*/ 9:
                    message.LastFailedAt = reader.string();
                    break;
                case /* int64 Timeout = 10 [json_name = "Timeout"];*/ 10:
                    message.Timeout = reader.int64().toNumber();
                    break;
                case /* string Deadline = 11 [json_name = "Deadline"];*/ 11:
                    message.Deadline = reader.string();
                    break;
                case /* string Group = 12 [json_name = "Group"];*/ 12:
                    message.Group = reader.string();
                    break;
                case /* string NextProcessAt = 13 [json_name = "NextProcessAt"];*/ 13:
                    message.NextProcessAt = reader.string();
                    break;
                case /* bool IsOrphaned = 14 [json_name = "IsOrphaned"];*/ 14:
                    message.IsOrphaned = reader.bool();
                    break;
                case /* int64 Retention = 15 [json_name = "Retention"];*/ 15:
                    message.Retention = reader.int64().toNumber();
                    break;
                case /* string CompletedAt = 16 [json_name = "CompletedAt"];*/ 16:
                    message.CompletedAt = reader.string();
                    break;
                case /* string Result = 17 [json_name = "Result"];*/ 17:
                    message.Result = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaskInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ID);
        /* string Queue = 2 [json_name = "Queue"]; */
        if (message.Queue !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Queue);
        /* string Type = 3 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Type);
        /* string Payload = 4 [json_name = "Payload"]; */
        if (message.Payload !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Payload);
        /* int64 State = 5 [json_name = "State"]; */
        if (message.State !== 0)
            writer.tag(5, WireType.Varint).int64(message.State);
        /* int64 MaxRetry = 6 [json_name = "MaxRetry"]; */
        if (message.MaxRetry !== 0)
            writer.tag(6, WireType.Varint).int64(message.MaxRetry);
        /* int64 Retried = 7 [json_name = "Retried"]; */
        if (message.Retried !== 0)
            writer.tag(7, WireType.Varint).int64(message.Retried);
        /* string LastErr = 8 [json_name = "LastErr"]; */
        if (message.LastErr !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.LastErr);
        /* string LastFailedAt = 9 [json_name = "LastFailedAt"]; */
        if (message.LastFailedAt !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.LastFailedAt);
        /* int64 Timeout = 10 [json_name = "Timeout"]; */
        if (message.Timeout !== 0)
            writer.tag(10, WireType.Varint).int64(message.Timeout);
        /* string Deadline = 11 [json_name = "Deadline"]; */
        if (message.Deadline !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.Deadline);
        /* string Group = 12 [json_name = "Group"]; */
        if (message.Group !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.Group);
        /* string NextProcessAt = 13 [json_name = "NextProcessAt"]; */
        if (message.NextProcessAt !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.NextProcessAt);
        /* bool IsOrphaned = 14 [json_name = "IsOrphaned"]; */
        if (message.IsOrphaned !== false)
            writer.tag(14, WireType.Varint).bool(message.IsOrphaned);
        /* int64 Retention = 15 [json_name = "Retention"]; */
        if (message.Retention !== 0)
            writer.tag(15, WireType.Varint).int64(message.Retention);
        /* string CompletedAt = 16 [json_name = "CompletedAt"]; */
        if (message.CompletedAt !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.CompletedAt);
        /* string Result = 17 [json_name = "Result"]; */
        if (message.Result !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.Result);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.TaskInfo
 */
export const TaskInfo = new TaskInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListChainLinkDefValue$Type extends MessageType<ListChainLinkDefValue> {
    constructor() {
        super("grpcServices.ListChainLinkDefValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => ChainLinkDef }
        ]);
    }
    create(value?: PartialMessage<ListChainLinkDefValue>): ListChainLinkDefValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListChainLinkDefValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListChainLinkDefValue): ListChainLinkDefValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.ChainLinkDef Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(ChainLinkDef.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListChainLinkDefValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.ChainLinkDef Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            ChainLinkDef.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListChainLinkDefValue
 */
export const ListChainLinkDefValue = new ListChainLinkDefValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChainDef$Type extends MessageType<ChainDef> {
    constructor() {
        super("grpcServices.ChainDef", [
            { no: 1, name: "ChainLinks", kind: "message", localName: "ChainLinks", jsonName: "ChainLinks", repeat: 1 /*RepeatType.PACKED*/, T: () => ChainLinkDef }
        ]);
    }
    create(value?: PartialMessage<ChainDef>): ChainDef {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ChainLinks = [];
        if (value !== undefined)
            reflectionMergePartial<ChainDef>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChainDef): ChainDef {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.ChainLinkDef ChainLinks = 1 [json_name = "ChainLinks"];*/ 1:
                    message.ChainLinks.push(ChainLinkDef.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChainDef, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.ChainLinkDef ChainLinks = 1 [json_name = "ChainLinks"]; */
        for (let i = 0; i < message.ChainLinks.length; i++)
            ChainLinkDef.internalBinaryWrite(message.ChainLinks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ChainDef
 */
export const ChainDef = new ChainDef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFiltersValue$Type extends MessageType<ListFiltersValue> {
    constructor() {
        super("grpcServices.ListFiltersValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Filters }
        ]);
    }
    create(value?: PartialMessage<ListFiltersValue>): ListFiltersValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListFiltersValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFiltersValue): ListFiltersValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.Filters Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Filters.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFiltersValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.Filters Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Filters.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListFiltersValue
 */
export const ListFiltersValue = new ListFiltersValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchedulerTableResult$Type extends MessageType<SchedulerTableResult> {
    constructor() {
        super("grpcServices.SchedulerTableResult", [
            { no: 1, name: "Row", kind: "message", localName: "Row", jsonName: "Row", repeat: 1 /*RepeatType.PACKED*/, T: () => SchedulerTable },
            { no: 2, name: "Count", kind: "scalar", localName: "Count", jsonName: "Count", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SchedulerTableResult>): SchedulerTableResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Row = [];
        message.Count = 0;
        if (value !== undefined)
            reflectionMergePartial<SchedulerTableResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SchedulerTableResult): SchedulerTableResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.SchedulerTable Row = 1 [json_name = "Row"];*/ 1:
                    message.Row.push(SchedulerTable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 Count = 2 [json_name = "Count"];*/ 2:
                    message.Count = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SchedulerTableResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.SchedulerTable Row = 1 [json_name = "Row"]; */
        for (let i = 0; i < message.Row.length; i++)
            SchedulerTable.internalBinaryWrite(message.Row[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 Count = 2 [json_name = "Count"]; */
        if (message.Count !== 0)
            writer.tag(2, WireType.Varint).int64(message.Count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.SchedulerTableResult
 */
export const SchedulerTableResult = new SchedulerTableResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskStreamMessage$Type extends MessageType<TaskStreamMessage> {
    constructor() {
        super("grpcServices.TaskStreamMessage", [
            { no: 1, name: "Tasks", kind: "message", localName: "Tasks", jsonName: "Tasks", repeat: 1 /*RepeatType.PACKED*/, T: () => TaskInfo }
        ]);
    }
    create(value?: PartialMessage<TaskStreamMessage>): TaskStreamMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Tasks = [];
        if (value !== undefined)
            reflectionMergePartial<TaskStreamMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskStreamMessage): TaskStreamMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.TaskInfo Tasks = 1 [json_name = "Tasks"];*/ 1:
                    message.Tasks.push(TaskInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaskStreamMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.TaskInfo Tasks = 1 [json_name = "Tasks"]; */
        for (let i = 0; i < message.Tasks.length; i++)
            TaskInfo.internalBinaryWrite(message.Tasks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.TaskStreamMessage
 */
export const TaskStreamMessage = new TaskStreamMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobInfo$Type extends MessageType<JobInfo> {
    constructor() {
        super("grpcServices.JobInfo", [
            { no: 1, name: "OriginalId", kind: "scalar", localName: "OriginalId", jsonName: "OriginalId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Id", kind: "scalar", localName: "Id", jsonName: "Id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Cron", kind: "scalar", localName: "Cron", jsonName: "Cron", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Active", kind: "scalar", localName: "Active", jsonName: "Active", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "Next", kind: "scalar", localName: "Next", jsonName: "Next", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "Last", kind: "scalar", localName: "Last", jsonName: "Last", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<JobInfo>): JobInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.OriginalId = 0;
        message.Id = 0;
        message.Model = "";
        message.Cron = "";
        message.Active = false;
        message.Next = [];
        message.Last = [];
        if (value !== undefined)
            reflectionMergePartial<JobInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JobInfo): JobInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 OriginalId = 1 [json_name = "OriginalId"];*/ 1:
                    message.OriginalId = reader.int64().toNumber();
                    break;
                case /* int64 Id = 2 [json_name = "Id"];*/ 2:
                    message.Id = reader.int64().toNumber();
                    break;
                case /* string Model = 3 [json_name = "Model"];*/ 3:
                    message.Model = reader.string();
                    break;
                case /* string Cron = 4 [json_name = "Cron"];*/ 4:
                    message.Cron = reader.string();
                    break;
                case /* bool Active = 5 [json_name = "Active"];*/ 5:
                    message.Active = reader.bool();
                    break;
                case /* repeated string Next = 6 [json_name = "Next"];*/ 6:
                    message.Next.push(reader.string());
                    break;
                case /* repeated string Last = 7 [json_name = "Last"];*/ 7:
                    message.Last.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JobInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 OriginalId = 1 [json_name = "OriginalId"]; */
        if (message.OriginalId !== 0)
            writer.tag(1, WireType.Varint).int64(message.OriginalId);
        /* int64 Id = 2 [json_name = "Id"]; */
        if (message.Id !== 0)
            writer.tag(2, WireType.Varint).int64(message.Id);
        /* string Model = 3 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Model);
        /* string Cron = 4 [json_name = "Cron"]; */
        if (message.Cron !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Cron);
        /* bool Active = 5 [json_name = "Active"]; */
        if (message.Active !== false)
            writer.tag(5, WireType.Varint).bool(message.Active);
        /* repeated string Next = 6 [json_name = "Next"]; */
        for (let i = 0; i < message.Next.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.Next[i]);
        /* repeated string Last = 7 [json_name = "Last"]; */
        for (let i = 0; i < message.Last.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.Last[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.JobInfo
 */
export const JobInfo = new JobInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueueServerInfo$Type extends MessageType<QueueServerInfo> {
    constructor() {
        super("grpcServices.QueueServerInfo", [
            { no: 1, name: "Queues", kind: "message", localName: "Queues", jsonName: "Queues", repeat: 1 /*RepeatType.PACKED*/, T: () => QueueInfo }
        ]);
    }
    create(value?: PartialMessage<QueueServerInfo>): QueueServerInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Queues = [];
        if (value !== undefined)
            reflectionMergePartial<QueueServerInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueueServerInfo): QueueServerInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.QueueInfo Queues = 1 [json_name = "Queues"];*/ 1:
                    message.Queues.push(QueueInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueueServerInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.QueueInfo Queues = 1 [json_name = "Queues"]; */
        for (let i = 0; i < message.Queues.length; i++)
            QueueInfo.internalBinaryWrite(message.Queues[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.QueueServerInfo
 */
export const QueueServerInfo = new QueueServerInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSortsValue$Type extends MessageType<ListSortsValue> {
    constructor() {
        super("grpcServices.ListSortsValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Sorts }
        ]);
    }
    create(value?: PartialMessage<ListSortsValue>): ListSortsValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListSortsValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSortsValue): ListSortsValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.Sorts Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Sorts.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSortsValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.Sorts Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Sorts.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListSortsValue
 */
export const ListSortsValue = new ListSortsValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPointerTaskInfoValue$Type extends MessageType<ListPointerTaskInfoValue> {
    constructor() {
        super("grpcServices.ListPointerTaskInfoValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => TaskInfo }
        ]);
    }
    create(value?: PartialMessage<ListPointerTaskInfoValue>): ListPointerTaskInfoValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListPointerTaskInfoValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPointerTaskInfoValue): ListPointerTaskInfoValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.TaskInfo Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(TaskInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPointerTaskInfoValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.TaskInfo Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            TaskInfo.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListPointerTaskInfoValue
 */
export const ListPointerTaskInfoValue = new ListPointerTaskInfoValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetQueueMessRowValue$Type extends MessageType<ListGetQueueMessRowValue> {
    constructor() {
        super("grpcServices.ListGetQueueMessRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetQueueMessRow }
        ]);
    }
    create(value?: PartialMessage<ListGetQueueMessRowValue>): ListGetQueueMessRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetQueueMessRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetQueueMessRowValue): ListGetQueueMessRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.GetQueueMessRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetQueueMessRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetQueueMessRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.GetQueueMessRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetQueueMessRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListGetQueueMessRowValue
 */
export const ListGetQueueMessRowValue = new ListGetQueueMessRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQueueMessRow$Type extends MessageType<GetQueueMessRow> {
    constructor() {
        super("grpcServices.GetQueueMessRow", [
            { no: 1, name: "Date", kind: "scalar", localName: "Date", jsonName: "Date", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Message", kind: "scalar", localName: "Message", jsonName: "Message", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetQueueMessRow>): GetQueueMessRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Date = "";
        message.Message = "";
        if (value !== undefined)
            reflectionMergePartial<GetQueueMessRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetQueueMessRow): GetQueueMessRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Date = 1 [json_name = "Date"];*/ 1:
                    message.Date = reader.string();
                    break;
                case /* string Message = 2 [json_name = "Message"];*/ 2:
                    message.Message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetQueueMessRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Date = 1 [json_name = "Date"]; */
        if (message.Date !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Date);
        /* string Message = 2 [json_name = "Message"]; */
        if (message.Message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.GetQueueMessRow
 */
export const GetQueueMessRow = new GetQueueMessRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobList$Type extends MessageType<JobList> {
    constructor() {
        super("grpcServices.JobList", [
            { no: 1, name: "List", kind: "message", localName: "List", jsonName: "List", repeat: 1 /*RepeatType.PACKED*/, T: () => JobInfo }
        ]);
    }
    create(value?: PartialMessage<JobList>): JobList {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.List = [];
        if (value !== undefined)
            reflectionMergePartial<JobList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JobList): JobList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.JobInfo List = 1 [json_name = "List"];*/ 1:
                    message.List.push(JobInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JobList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.JobInfo List = 1 [json_name = "List"]; */
        for (let i = 0; i < message.List.length; i++)
            JobInfo.internalBinaryWrite(message.List[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.JobList
 */
export const JobList = new JobList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDirInfoStructValue$Type extends MessageType<ListDirInfoStructValue> {
    constructor() {
        super("grpcServices.ListDirInfoStructValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => DirInfoStruct }
        ]);
    }
    create(value?: PartialMessage<ListDirInfoStructValue>): ListDirInfoStructValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListDirInfoStructValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDirInfoStructValue): ListDirInfoStructValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.DirInfoStruct Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(DirInfoStruct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDirInfoStructValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.DirInfoStruct Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            DirInfoStruct.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListDirInfoStructValue
 */
export const ListDirInfoStructValue = new ListDirInfoStructValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RunJobInput$Type extends MessageType<RunJobInput> {
    constructor() {
        super("grpcServices.RunJobInput", [
            { no: 1, name: "TaskId", kind: "scalar", localName: "TaskId", jsonName: "TaskId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Prepare", kind: "scalar", localName: "Prepare", jsonName: "Prepare", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RunJobInput>): RunJobInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.TaskId = 0;
        message.Model = "";
        message.Prepare = false;
        if (value !== undefined)
            reflectionMergePartial<RunJobInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RunJobInput): RunJobInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 TaskId = 1 [json_name = "TaskId"];*/ 1:
                    message.TaskId = reader.int64().toNumber();
                    break;
                case /* string Model = 2 [json_name = "Model"];*/ 2:
                    message.Model = reader.string();
                    break;
                case /* bool Prepare = 3 [json_name = "Prepare"];*/ 3:
                    message.Prepare = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RunJobInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 TaskId = 1 [json_name = "TaskId"]; */
        if (message.TaskId !== 0)
            writer.tag(1, WireType.Varint).int64(message.TaskId);
        /* string Model = 2 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Model);
        /* bool Prepare = 3 [json_name = "Prepare"]; */
        if (message.Prepare !== false)
            writer.tag(3, WireType.Varint).bool(message.Prepare);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.RunJobInput
 */
export const RunJobInput = new RunJobInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sorts$Type extends MessageType<Sorts> {
    constructor() {
        super("grpcServices.Sorts", [
            { no: 1, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Desc", kind: "scalar", localName: "Desc", jsonName: "Desc", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Sorts>): Sorts {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Name = "";
        message.Desc = false;
        if (value !== undefined)
            reflectionMergePartial<Sorts>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Sorts): Sorts {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Name = 1 [json_name = "Name"];*/ 1:
                    message.Name = reader.string();
                    break;
                case /* bool Desc = 2 [json_name = "Desc"];*/ 2:
                    message.Desc = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Sorts, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Name = 1 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Name);
        /* bool Desc = 2 [json_name = "Desc"]; */
        if (message.Desc !== false)
            writer.tag(2, WireType.Varint).bool(message.Desc);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.Sorts
 */
export const Sorts = new Sorts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryData$Type extends MessageType<QueryData> {
    constructor() {
        super("grpcServices.QueryData", [
            { no: 1, name: "Page", kind: "scalar", localName: "Page", jsonName: "Page", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "OnPage", kind: "scalar", localName: "OnPage", jsonName: "OnPage", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Sort", kind: "message", localName: "Sort", jsonName: "Sort", repeat: 1 /*RepeatType.PACKED*/, T: () => Sorts },
            { no: 4, name: "Filters", kind: "message", localName: "Filters", jsonName: "Filters", repeat: 1 /*RepeatType.PACKED*/, T: () => Filters }
        ]);
    }
    create(value?: PartialMessage<QueryData>): QueryData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Page = 0;
        message.OnPage = 0;
        message.Sort = [];
        message.Filters = [];
        if (value !== undefined)
            reflectionMergePartial<QueryData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryData): QueryData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Page = 1 [json_name = "Page"];*/ 1:
                    message.Page = reader.int64().toNumber();
                    break;
                case /* int64 OnPage = 2 [json_name = "OnPage"];*/ 2:
                    message.OnPage = reader.int64().toNumber();
                    break;
                case /* repeated grpcServices.Sorts Sort = 3 [json_name = "Sort"];*/ 3:
                    message.Sort.push(Sorts.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcServices.Filters Filters = 4 [json_name = "Filters"];*/ 4:
                    message.Filters.push(Filters.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Page = 1 [json_name = "Page"]; */
        if (message.Page !== 0)
            writer.tag(1, WireType.Varint).int64(message.Page);
        /* int64 OnPage = 2 [json_name = "OnPage"]; */
        if (message.OnPage !== 0)
            writer.tag(2, WireType.Varint).int64(message.OnPage);
        /* repeated grpcServices.Sorts Sort = 3 [json_name = "Sort"]; */
        for (let i = 0; i < message.Sort.length; i++)
            Sorts.internalBinaryWrite(message.Sort[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcServices.Filters Filters = 4 [json_name = "Filters"]; */
        for (let i = 0; i < message.Filters.length; i++)
            Filters.internalBinaryWrite(message.Filters[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.QueryData
 */
export const QueryData = new QueryData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListByteValue$Type extends MessageType<ListByteValue> {
    constructor() {
        super("grpcServices.ListByteValue", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListByteValue>): ListByteValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = "";
        if (value !== undefined)
            reflectionMergePartial<ListByteValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListByteValue): ListByteValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Value = 1 [json_name = "Value"];*/ 1:
                    message.Value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListByteValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Value = 1 [json_name = "Value"]; */
        if (message.Value !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListByteValue
 */
export const ListByteValue = new ListByteValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MapStringQueueDescriptionValue$Type extends MessageType<MapStringQueueDescriptionValue> {
    constructor() {
        super("grpcServices.MapStringQueueDescriptionValue", [
            { no: 1, name: "Value", kind: "map", localName: "Value", jsonName: "Value", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => QueueDescription } }
        ]);
    }
    create(value?: PartialMessage<MapStringQueueDescriptionValue>): MapStringQueueDescriptionValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = {};
        if (value !== undefined)
            reflectionMergePartial<MapStringQueueDescriptionValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MapStringQueueDescriptionValue): MapStringQueueDescriptionValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, grpcServices.QueueDescription> Value = 1 [json_name = "Value"];*/ 1:
                    this.binaryReadMap1(message.Value, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: MapStringQueueDescriptionValue["Value"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof MapStringQueueDescriptionValue["Value"] | undefined, val: MapStringQueueDescriptionValue["Value"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = QueueDescription.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field grpcServices.MapStringQueueDescriptionValue.Value");
            }
        }
        map[key ?? ""] = val ?? QueueDescription.create();
    }
    internalBinaryWrite(message: MapStringQueueDescriptionValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, grpcServices.QueueDescription> Value = 1 [json_name = "Value"]; */
        for (let k of globalThis.Object.keys(message.Value)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            QueueDescription.internalBinaryWrite(message.Value[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.MapStringQueueDescriptionValue
 */
export const MapStringQueueDescriptionValue = new MapStringQueueDescriptionValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelTaskInput$Type extends MessageType<CancelTaskInput> {
    constructor() {
        super("grpcServices.CancelTaskInput", [
            { no: 1, name: "QueueName", kind: "scalar", localName: "QueueName", jsonName: "QueueName", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "TaskID", kind: "scalar", localName: "TaskID", jsonName: "TaskID", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelTaskInput>): CancelTaskInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.QueueName = "";
        message.TaskID = "";
        if (value !== undefined)
            reflectionMergePartial<CancelTaskInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelTaskInput): CancelTaskInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string QueueName = 1 [json_name = "QueueName"];*/ 1:
                    message.QueueName = reader.string();
                    break;
                case /* string TaskID = 2 [json_name = "TaskID"];*/ 2:
                    message.TaskID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelTaskInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string QueueName = 1 [json_name = "QueueName"]; */
        if (message.QueueName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.QueueName);
        /* string TaskID = 2 [json_name = "TaskID"]; */
        if (message.TaskID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.TaskID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.CancelTaskInput
 */
export const CancelTaskInput = new CancelTaskInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CronNextInput$Type extends MessageType<CronNextInput> {
    constructor() {
        super("grpcServices.CronNextInput", [
            { no: 1, name: "Cron", kind: "scalar", localName: "Cron", jsonName: "Cron", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "N", kind: "scalar", localName: "N", jsonName: "N", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CronNextInput>): CronNextInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Cron = "";
        message.N = 0;
        if (value !== undefined)
            reflectionMergePartial<CronNextInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CronNextInput): CronNextInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Cron = 1 [json_name = "Cron"];*/ 1:
                    message.Cron = reader.string();
                    break;
                case /* int64 N = 2 [json_name = "N"];*/ 2:
                    message.N = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CronNextInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Cron = 1 [json_name = "Cron"]; */
        if (message.Cron !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Cron);
        /* int64 N = 2 [json_name = "N"]; */
        if (message.N !== 0)
            writer.tag(2, WireType.Varint).int64(message.N);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.CronNextInput
 */
export const CronNextInput = new CronNextInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStringValue$Type extends MessageType<ListStringValue> {
    constructor() {
        super("grpcServices.ListStringValue", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListStringValue>): ListStringValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListStringValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListStringValue): ListStringValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListStringValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.Value[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListStringValue
 */
export const ListStringValue = new ListStringValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobInput$Type extends MessageType<JobInput> {
    constructor() {
        super("grpcServices.JobInput", [
            { no: 1, name: "TaskId", kind: "scalar", localName: "TaskId", jsonName: "TaskId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Cron", kind: "scalar", localName: "Cron", jsonName: "Cron", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Active", kind: "scalar", localName: "Active", jsonName: "Active", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "Instant", kind: "scalar", localName: "Instant", jsonName: "Instant", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<JobInput>): JobInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.TaskId = 0;
        message.Model = "";
        message.Cron = "";
        message.Active = false;
        message.Instant = false;
        if (value !== undefined)
            reflectionMergePartial<JobInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JobInput): JobInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 TaskId = 1 [json_name = "TaskId"];*/ 1:
                    message.TaskId = reader.int64().toNumber();
                    break;
                case /* string Model = 2 [json_name = "Model"];*/ 2:
                    message.Model = reader.string();
                    break;
                case /* string Cron = 3 [json_name = "Cron"];*/ 3:
                    message.Cron = reader.string();
                    break;
                case /* bool Active = 4 [json_name = "Active"];*/ 4:
                    message.Active = reader.bool();
                    break;
                case /* bool Instant = 5 [json_name = "Instant"];*/ 5:
                    message.Instant = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JobInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 TaskId = 1 [json_name = "TaskId"]; */
        if (message.TaskId !== 0)
            writer.tag(1, WireType.Varint).int64(message.TaskId);
        /* string Model = 2 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Model);
        /* string Cron = 3 [json_name = "Cron"]; */
        if (message.Cron !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Cron);
        /* bool Active = 4 [json_name = "Active"]; */
        if (message.Active !== false)
            writer.tag(4, WireType.Varint).bool(message.Active);
        /* bool Instant = 5 [json_name = "Instant"]; */
        if (message.Instant !== false)
            writer.tag(5, WireType.Varint).bool(message.Instant);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.JobInput
 */
export const JobInput = new JobInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTasksListInput$Type extends MessageType<GetTasksListInput> {
    constructor() {
        super("grpcServices.GetTasksListInput", [
            { no: 1, name: "Queue", kind: "scalar", localName: "Queue", jsonName: "Queue", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Offset", kind: "scalar", localName: "Offset", jsonName: "Offset", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetTasksListInput>): GetTasksListInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Queue = "";
        message.Type = [];
        message.Offset = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTasksListInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTasksListInput): GetTasksListInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Queue = 1 [json_name = "Queue"];*/ 1:
                    message.Queue = reader.string();
                    break;
                case /* repeated string Type = 2 [json_name = "Type"];*/ 2:
                    message.Type.push(reader.string());
                    break;
                case /* int64 Offset = 3 [json_name = "Offset"];*/ 3:
                    message.Offset = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTasksListInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Queue = 1 [json_name = "Queue"]; */
        if (message.Queue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Queue);
        /* repeated string Type = 2 [json_name = "Type"]; */
        for (let i = 0; i < message.Type.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.Type[i]);
        /* int64 Offset = 3 [json_name = "Offset"]; */
        if (message.Offset !== 0)
            writer.tag(3, WireType.Varint).int64(message.Offset);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.GetTasksListInput
 */
export const GetTasksListInput = new GetTasksListInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueueDescription$Type extends MessageType<QueueDescription> {
    constructor() {
        super("grpcServices.QueueDescription", [
            { no: 1, name: "FriendlyName", kind: "scalar", localName: "FriendlyName", jsonName: "FriendlyName", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Description", kind: "scalar", localName: "Description", jsonName: "Description", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "SystemName", kind: "scalar", localName: "SystemName", jsonName: "SystemName", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<QueueDescription>): QueueDescription {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.FriendlyName = "";
        message.Description = "";
        message.SystemName = "";
        if (value !== undefined)
            reflectionMergePartial<QueueDescription>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueueDescription): QueueDescription {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string FriendlyName = 1 [json_name = "FriendlyName"];*/ 1:
                    message.FriendlyName = reader.string();
                    break;
                case /* string Description = 2 [json_name = "Description"];*/ 2:
                    message.Description = reader.string();
                    break;
                case /* string SystemName = 3 [json_name = "SystemName"];*/ 3:
                    message.SystemName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueueDescription, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string FriendlyName = 1 [json_name = "FriendlyName"]; */
        if (message.FriendlyName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.FriendlyName);
        /* string Description = 2 [json_name = "Description"]; */
        if (message.Description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Description);
        /* string SystemName = 3 [json_name = "SystemName"]; */
        if (message.SystemName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.SystemName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.QueueDescription
 */
export const QueueDescription = new QueueDescription$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskDef$Type extends MessageType<TaskDef> {
    constructor() {
        super("grpcServices.TaskDef", [
            { no: 1, name: "QueueName", kind: "scalar", localName: "QueueName", jsonName: "QueueName", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "MsgConfig", kind: "scalar", localName: "MsgConfig", jsonName: "MsgConfig", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TaskDef>): TaskDef {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.QueueName = "";
        message.MsgConfig = "";
        if (value !== undefined)
            reflectionMergePartial<TaskDef>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskDef): TaskDef {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string QueueName = 1 [json_name = "QueueName"];*/ 1:
                    message.QueueName = reader.string();
                    break;
                case /* string MsgConfig = 2 [json_name = "MsgConfig"];*/ 2:
                    message.MsgConfig = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaskDef, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string QueueName = 1 [json_name = "QueueName"]; */
        if (message.QueueName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.QueueName);
        /* string MsgConfig = 2 [json_name = "MsgConfig"]; */
        if (message.MsgConfig !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.MsgConfig);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.TaskDef
 */
export const TaskDef = new TaskDef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchedulerTableValue$Type extends MessageType<ListSchedulerTableValue> {
    constructor() {
        super("grpcServices.ListSchedulerTableValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => SchedulerTable }
        ]);
    }
    create(value?: PartialMessage<ListSchedulerTableValue>): ListSchedulerTableValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListSchedulerTableValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSchedulerTableValue): ListSchedulerTableValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.SchedulerTable Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(SchedulerTable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSchedulerTableValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.SchedulerTable Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            SchedulerTable.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListSchedulerTableValue
 */
export const ListSchedulerTableValue = new ListSchedulerTableValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQueueMessInput$Type extends MessageType<GetQueueMessInput> {
    constructor() {
        super("grpcServices.GetQueueMessInput", [
            { no: 1, name: "QueueName", kind: "scalar", localName: "QueueName", jsonName: "QueueName", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "TaskId", kind: "scalar", localName: "TaskId", jsonName: "TaskId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetQueueMessInput>): GetQueueMessInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.QueueName = "";
        message.TaskId = "";
        if (value !== undefined)
            reflectionMergePartial<GetQueueMessInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetQueueMessInput): GetQueueMessInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string QueueName = 1 [json_name = "QueueName"];*/ 1:
                    message.QueueName = reader.string();
                    break;
                case /* string TaskId = 2 [json_name = "TaskId"];*/ 2:
                    message.TaskId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetQueueMessInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string QueueName = 1 [json_name = "QueueName"]; */
        if (message.QueueName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.QueueName);
        /* string TaskId = 2 [json_name = "TaskId"]; */
        if (message.TaskId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.TaskId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.GetQueueMessInput
 */
export const GetQueueMessInput = new GetQueueMessInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListJobInfoValue$Type extends MessageType<ListJobInfoValue> {
    constructor() {
        super("grpcServices.ListJobInfoValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => JobInfo }
        ]);
    }
    create(value?: PartialMessage<ListJobInfoValue>): ListJobInfoValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListJobInfoValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListJobInfoValue): ListJobInfoValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcServices.JobInfo Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(JobInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListJobInfoValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcServices.JobInfo Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            JobInfo.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.ListJobInfoValue
 */
export const ListJobInfoValue = new ListJobInfoValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetJobInput$Type extends MessageType<GetJobInput> {
    constructor() {
        super("grpcServices.GetJobInput", [
            { no: 1, name: "TaskId", kind: "scalar", localName: "TaskId", jsonName: "TaskId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetJobInput>): GetJobInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.TaskId = 0;
        message.Model = "";
        if (value !== undefined)
            reflectionMergePartial<GetJobInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetJobInput): GetJobInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 TaskId = 1 [json_name = "TaskId"];*/ 1:
                    message.TaskId = reader.int64().toNumber();
                    break;
                case /* string Model = 2 [json_name = "Model"];*/ 2:
                    message.Model = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetJobInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 TaskId = 1 [json_name = "TaskId"]; */
        if (message.TaskId !== 0)
            writer.tag(1, WireType.Varint).int64(message.TaskId);
        /* string Model = 2 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Model);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcServices.GetJobInput
 */
export const GetJobInput = new GetJobInput$Type();
/**
 * @generated ServiceType for protobuf service grpcServices.AssetsService
 */
export const AssetsService = new ServiceType("grpcServices.AssetsService", [
    { name: "CleanQueueDirInfo", options: {}, I: Empty, O: Empty },
    { name: "TEst", options: {}, I: StringValue, O: StringValue },
    { name: "GetQueueDirInfo", options: {}, I: Empty, O: DirInfo }
]);
/**
 * @generated ServiceType for protobuf service grpcServices.QueueService
 */
export const QueueService = new ServiceType("grpcServices.QueueService", [
    { name: "PausePlay", options: {}, I: StringValue, O: BoolValue },
    { name: "GetTasksListStream", serverStreaming: true, options: {}, I: GetTasksListInput, O: TaskStreamMessage },
    { name: "GetQueuesInfo", options: {}, I: Empty, O: MapStringQueueDescriptionValue },
    { name: "GetQueueServerInfo", options: {}, I: Empty, O: ListPointerQueueInfoValue },
    { name: "GetQueueLogs", options: {}, I: GetQueueMessInput, O: ListGetQueueMessRowValue },
    { name: "GetQueueErrors", options: {}, I: GetQueueMessInput, O: ListGetQueueMessRowValue },
    { name: "GetTasksList", options: {}, I: GetTasksListInput, O: ListPointerTaskInfoValue },
    { name: "CancelTask", options: {}, I: CancelTaskInput, O: BoolValue },
    { name: "DeleteQueue", options: {}, I: StringValue, O: BoolValue },
    { name: "GetQueueServerInfoStream", serverStreaming: true, options: {}, I: Int32Value, O: QueueServerInfo },
    { name: "ClearQueue", options: {}, I: StringValue, O: Int64Value }
]);
/**
 * @generated ServiceType for protobuf service grpcServices.QueueServiceTaskChain
 */
export const QueueServiceTaskChain = new ServiceType("grpcServices.QueueServiceTaskChain", [
    { name: "ChainReport", options: {}, I: ChainDef, O: BoolValue }
]);
/**
 * @generated ServiceType for protobuf service grpcServices.ScheduleService
 */
export const ScheduleService = new ServiceType("grpcServices.ScheduleService", [
    { name: "GetCronNextN", options: {}, I: CronNextInput, O: ListStringValue },
    { name: "GetScedulerTable", options: {}, I: QueryData, O: SchedulerTableResult },
    { name: "UpdateJob", options: {}, I: JobInput, O: BoolValue },
    { name: "AddNewJob", options: {}, I: JobInput, O: BoolValue },
    { name: "Reset", options: {}, I: Empty, O: BoolValue },
    { name: "GetJobList", options: {}, I: Empty, O: JobList },
    { name: "RemoveJob", options: {}, I: GetJobInput, O: BoolValue },
    { name: "RunJobNow", options: {}, I: RunJobInput, O: BoolValue },
    { name: "GetJob", options: {}, I: GetJobInput, O: JobInfo }
]);
