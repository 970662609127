import { GrpcManager, RecconectableStream, Types } from "grpc-connect";
import { ReactNode, useEffect, useState } from "react";

type TaskInfo = Types.QueueManager.QueueService.TaskInfo;

// ! addToQueue needs to return just a taskId

export const InstantTaskStatusBtn = ({
    addToQueue,
    label,
    className,
    result,
    onError,
}: {
    addToQueue: () => Promise<{ response: { value: string } }>;
    label: ReactNode;
    className?: string;
    result: (isSuccess: boolean | undefined, taskInfo: TaskInfo) => void;
    onError?: (e: string) => void;
}) => {
    const client = GrpcManager.QueueManager.getQueueService();

    const [taskId, setTaskId] = useState<string>("");
    const [isClicked, setIsCliked] = useState<boolean>(false);

    useEffect(() => {
        const abort = new AbortController();
        const stream = new RecconectableStream(() => {
            return client.getTasksListStream(
                {
                    Queue: "sender-queue",
                    Type: ["Completed", "Retry"],
                    Offset: 0,
                },
                { abort: abort.signal },
            );
        });

        stream.onMessage((message) => {
            message.Tasks.forEach((el) => {
                // @ts-ignore generic val
                if (taskId.length > 0 && el.ID === taskId) {
                    result(el.State === 4 ? false : el.State === 6 ? true : undefined, el);
                }
            });
        });
        return () => {
            abort.abort();
        };
    }, [taskId]);

    const addTaskHandler = async () => {
        if (isClicked) return;
        try {
            // todo change this
            const isSuccess = await addToQueue();
            if (isSuccess.response.value.length > 0) {
                setTaskId(isSuccess.response.value);
                setIsCliked(true);
            } else {
                throw new Error("test connection failed");
            }
        } catch (e) {
            console.error(e);
            if (onError) onError(e instanceof Error ? e.message : String(e));
        }
    };

    return (
        <button className={className} onClick={addTaskHandler}>
            {label}
        </button>
    );
};
