// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcTodo2_service.proto" (package "grpcTodo2_service", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Todo2Service } from "./grpcTodo2_service";
import type { EditTodoOutput } from "./grpcTodo2_service";
import type { EditTodoInput } from "./grpcTodo2_service";
import type { GetHistoryOutput } from "./grpcTodo2_service";
import type { GetHistoryInput } from "./grpcTodo2_service";
import type { GetTodosOutput } from "./grpcTodo2_service";
import type { GetTodosInput } from "./grpcTodo2_service";
import type { EditListInput } from "./grpcTodo2_service";
import type { ChangeListOutput } from "./grpcTodo2_service";
import type { ChangeListInput } from "./grpcTodo2_service";
import type { GetTodoOutput } from "./grpcTodo2_service";
import type { GetTodoInput } from "./grpcTodo2_service";
import type { Int64Value } from "./google/protobuf/wrappers";
import type { AddTodoInput } from "./grpcTodo2_service";
import type { DeleteTodoInput } from "./grpcTodo2_service";
import type { Empty } from "./google/protobuf/empty";
import type { AddListInput } from "./grpcTodo2_service";
import type { TodoConfig } from "./grpcTodo2_service";
import type { BoolValue } from "./google/protobuf/wrappers";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { AddNewTagOutput } from "./grpcTodo2_service";
import type { AddNewTagInput } from "./grpcTodo2_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcTodo2_service.Todo2Service
 */
export interface ITodo2ServiceClient {
    /**
     * @generated from protobuf rpc: AddNewTag(grpcTodo2_service.AddNewTagInput) returns (grpcTodo2_service.AddNewTagOutput);
     */
    addNewTag(input: AddNewTagInput, options?: RpcOptions): UnaryCall<AddNewTagInput, AddNewTagOutput>;
    /**
     * @generated from protobuf rpc: GetConfigData(google.protobuf.BoolValue) returns (grpcTodo2_service.TodoConfig);
     */
    getConfigData(input: BoolValue, options?: RpcOptions): UnaryCall<BoolValue, TodoConfig>;
    /**
     * @generated from protobuf rpc: AddList(grpcTodo2_service.AddListInput) returns (google.protobuf.BoolValue);
     */
    addList(input: AddListInput, options?: RpcOptions): UnaryCall<AddListInput, BoolValue>;
    /**
     * @generated from protobuf rpc: Test(google.protobuf.Empty) returns (google.protobuf.BoolValue);
     */
    test(input: Empty, options?: RpcOptions): UnaryCall<Empty, BoolValue>;
    /**
     * @generated from protobuf rpc: DeleteTodo(grpcTodo2_service.DeleteTodoInput) returns (google.protobuf.BoolValue);
     */
    deleteTodo(input: DeleteTodoInput, options?: RpcOptions): UnaryCall<DeleteTodoInput, BoolValue>;
    /**
     * @generated from protobuf rpc: AddTodo(grpcTodo2_service.AddTodoInput) returns (google.protobuf.Int64Value);
     */
    addTodo(input: AddTodoInput, options?: RpcOptions): UnaryCall<AddTodoInput, Int64Value>;
    /**
     * @generated from protobuf rpc: GetTodo(grpcTodo2_service.GetTodoInput) returns (grpcTodo2_service.GetTodoOutput);
     */
    getTodo(input: GetTodoInput, options?: RpcOptions): UnaryCall<GetTodoInput, GetTodoOutput>;
    /**
     * @generated from protobuf rpc: ChangeList(grpcTodo2_service.ChangeListInput) returns (grpcTodo2_service.ChangeListOutput);
     */
    changeList(input: ChangeListInput, options?: RpcOptions): UnaryCall<ChangeListInput, ChangeListOutput>;
    /**
     * @generated from protobuf rpc: EditList(grpcTodo2_service.EditListInput) returns (google.protobuf.BoolValue);
     */
    editList(input: EditListInput, options?: RpcOptions): UnaryCall<EditListInput, BoolValue>;
    /**
     * @generated from protobuf rpc: GetTodos(grpcTodo2_service.GetTodosInput) returns (grpcTodo2_service.GetTodosOutput);
     */
    getTodos(input: GetTodosInput, options?: RpcOptions): UnaryCall<GetTodosInput, GetTodosOutput>;
    /**
     * @generated from protobuf rpc: GetHistory(grpcTodo2_service.GetHistoryInput) returns (grpcTodo2_service.GetHistoryOutput);
     */
    getHistory(input: GetHistoryInput, options?: RpcOptions): UnaryCall<GetHistoryInput, GetHistoryOutput>;
    /**
     * @generated from protobuf rpc: EditTodo(grpcTodo2_service.EditTodoInput) returns (grpcTodo2_service.EditTodoOutput);
     */
    editTodo(input: EditTodoInput, options?: RpcOptions): UnaryCall<EditTodoInput, EditTodoOutput>;
    /**
     * @generated from protobuf rpc: DeleteList(google.protobuf.Int64Value) returns (google.protobuf.BoolValue);
     */
    deleteList(input: Int64Value, options?: RpcOptions): UnaryCall<Int64Value, BoolValue>;
}
/**
 * @generated from protobuf service grpcTodo2_service.Todo2Service
 */
export class Todo2ServiceClient implements ITodo2ServiceClient, ServiceInfo {
    typeName = Todo2Service.typeName;
    methods = Todo2Service.methods;
    options = Todo2Service.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: AddNewTag(grpcTodo2_service.AddNewTagInput) returns (grpcTodo2_service.AddNewTagOutput);
     */
    addNewTag(input: AddNewTagInput, options?: RpcOptions): UnaryCall<AddNewTagInput, AddNewTagOutput> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddNewTagInput, AddNewTagOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetConfigData(google.protobuf.BoolValue) returns (grpcTodo2_service.TodoConfig);
     */
    getConfigData(input: BoolValue, options?: RpcOptions): UnaryCall<BoolValue, TodoConfig> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<BoolValue, TodoConfig>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddList(grpcTodo2_service.AddListInput) returns (google.protobuf.BoolValue);
     */
    addList(input: AddListInput, options?: RpcOptions): UnaryCall<AddListInput, BoolValue> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddListInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Test(google.protobuf.Empty) returns (google.protobuf.BoolValue);
     */
    test(input: Empty, options?: RpcOptions): UnaryCall<Empty, BoolValue> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTodo(grpcTodo2_service.DeleteTodoInput) returns (google.protobuf.BoolValue);
     */
    deleteTodo(input: DeleteTodoInput, options?: RpcOptions): UnaryCall<DeleteTodoInput, BoolValue> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteTodoInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddTodo(grpcTodo2_service.AddTodoInput) returns (google.protobuf.Int64Value);
     */
    addTodo(input: AddTodoInput, options?: RpcOptions): UnaryCall<AddTodoInput, Int64Value> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddTodoInput, Int64Value>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTodo(grpcTodo2_service.GetTodoInput) returns (grpcTodo2_service.GetTodoOutput);
     */
    getTodo(input: GetTodoInput, options?: RpcOptions): UnaryCall<GetTodoInput, GetTodoOutput> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTodoInput, GetTodoOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ChangeList(grpcTodo2_service.ChangeListInput) returns (grpcTodo2_service.ChangeListOutput);
     */
    changeList(input: ChangeListInput, options?: RpcOptions): UnaryCall<ChangeListInput, ChangeListOutput> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ChangeListInput, ChangeListOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditList(grpcTodo2_service.EditListInput) returns (google.protobuf.BoolValue);
     */
    editList(input: EditListInput, options?: RpcOptions): UnaryCall<EditListInput, BoolValue> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<EditListInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTodos(grpcTodo2_service.GetTodosInput) returns (grpcTodo2_service.GetTodosOutput);
     */
    getTodos(input: GetTodosInput, options?: RpcOptions): UnaryCall<GetTodosInput, GetTodosOutput> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTodosInput, GetTodosOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetHistory(grpcTodo2_service.GetHistoryInput) returns (grpcTodo2_service.GetHistoryOutput);
     */
    getHistory(input: GetHistoryInput, options?: RpcOptions): UnaryCall<GetHistoryInput, GetHistoryOutput> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetHistoryInput, GetHistoryOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditTodo(grpcTodo2_service.EditTodoInput) returns (grpcTodo2_service.EditTodoOutput);
     */
    editTodo(input: EditTodoInput, options?: RpcOptions): UnaryCall<EditTodoInput, EditTodoOutput> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<EditTodoInput, EditTodoOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteList(google.protobuf.Int64Value) returns (google.protobuf.BoolValue);
     */
    deleteList(input: Int64Value, options?: RpcOptions): UnaryCall<Int64Value, BoolValue> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<Int64Value, BoolValue>("unary", this._transport, method, opt, input);
    }
}
