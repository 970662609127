import { Modal, Textarea, useSerenityForm } from "serenity-controls";
import { useCommentsStore } from "./Store";
import { Button } from "@as-pl/ui/components/ui/button";
import { ArrowDown, ArrowUp, PlusIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { FormatDate, TimeAgo } from "../tools";

export const CommentsList = ({ elementId, model }: { elementId: number; model: string }) => {
    const comments = useCommentsStore((r) => r.comments);

    const loadComments = useCommentsStore((r) => r.loadComments);
    const setOrder = useCommentsStore((r) => r.setOrder);
    const order = useCommentsStore((r) => r.order);
    const [isAddVisible, setAddVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await loadComments(elementId, model);
            setLoading(false);
        })();
    }, [elementId, model]);

    return (
        <>
            <div className="w-full">
                <div className="flex items-center justify-between">
                    <h2>
                        Komentarze
                        <span
                            onClick={() => {
                                setOrder(order == "asc" ? "desc" : "asc");
                                loadComments(elementId, model);
                            }}
                            className="ml-2 cursor-pointer"
                        >
                            {order == "asc" ? <ArrowDown size={14} className="text-gray-600" /> : <ArrowUp size={14} className="text-gray-600" />}
                        </span>
                    </h2>

                    <Button variant={"ghost"} className="p-1" onClick={() => setAddVisible(!isAddVisible)}>
                        <PlusIcon size={12} /> Dodaj
                    </Button>
                </div>
                {loading && <div>Ładowanie...</div>}
                {!loading && comments.length === 0 && <div className="text-gray-400">Brak komentarzy</div>}

                {!loading &&
                    comments.map((comment) => (
                        <div key={comment.ID} className="mb-3 last:mb-0">
                            <div className="whitespace-pre-wrap rounded-md bg-neutral-200 p-2">{comment.Text}</div>
                            <div className="flex items-center justify-between">
                                <div className="text-xs text-gray-400">
                                    {FormatDate(comment.Created)} ( {TimeAgo(comment.Created)} )
                                </div>
                                <div className="text-gray-700">{comment.Login}</div>
                            </div>
                        </div>
                    ))}
            </div>
            {isAddVisible && (
                <Modal show={true} onHide={() => setAddVisible(false)} title="Dodaj komentarz">
                    <AddComment
                        elementId={elementId}
                        model={model}
                        onAdded={() => {
                            loadComments(elementId, model);
                            setAddVisible(false);
                        }}
                    />
                </Modal>
            )}
        </>
    );
};

const AddComment = ({ elementId, model, onAdded }: { elementId: number; model: string; onAdded: () => void }) => {
    const addComment = useCommentsStore((r) => r.addComment);

    const { field, ...form } = useSerenityForm<{ comment: string }>();
    const submit = () => {
        const values = form.getValues();
        addComment(elementId, model, values.comment);
        onAdded();
        form.reset();
    };

    return (
        <div>
            <Textarea
                {...field("comment")}
                autoFocus={true}
                style={{
                    width: "400px",
                    height: "200px",
                    resize: "none",
                }}
            />
            <div className="m-3 flex justify-end">
                <Button variant={"outline"} onClick={() => submit()}>
                    Dodaj
                </Button>
            </div>
        </div>
    );
};
