// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcComments_service.proto" (package "grpcComments_service", syntax proto3)
// tslint:disable
import { Int64Value } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcComments_service.CommentAddInput
 */
export interface CommentAddInput {
    /**
     * @generated from protobuf field: int64 Id = 1 [json_name = "Id"];
     */
    Id: number;
    /**
     * @generated from protobuf field: string Comment = 2 [json_name = "Comment"];
     */
    Comment: string;
    /**
     * @generated from protobuf field: int64 ParentId = 3 [json_name = "ParentId"];
     */
    ParentId: number;
    /**
     * @generated from protobuf field: string Model = 4 [json_name = "Model"];
     */
    Model: string;
}
/**
 * @generated from protobuf message grpcComments_service.GetCommentsRequest
 */
export interface GetCommentsRequest {
    /**
     * @generated from protobuf field: int64 Id = 1 [json_name = "Id"];
     */
    Id: number;
    /**
     * @generated from protobuf field: string Model = 2 [json_name = "Model"];
     */
    Model: string;
    /**
     * @generated from protobuf field: string Order = 3 [json_name = "Order"];
     */
    Order: string;
}
/**
 * @generated from protobuf message grpcComments_service.ListGetObjectCommentsRowValue
 */
export interface ListGetObjectCommentsRowValue {
    /**
     * @generated from protobuf field: repeated grpcComments_service.GetObjectCommentsRow Value = 1 [json_name = "Value"];
     */
    Value: GetObjectCommentsRow[];
}
/**
 * @generated from protobuf message grpcComments_service.GetObjectCommentsRow
 */
export interface GetObjectCommentsRow {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: string Created = 2 [json_name = "Created"];
     */
    Created: string;
    /**
     * @generated from protobuf field: string Text = 3 [json_name = "Text"];
     */
    Text: string;
    /**
     * @generated from protobuf field: int64 ParentCommentID = 4 [json_name = "ParentCommentID"];
     */
    ParentCommentID: number;
    /**
     * @generated from protobuf field: string Login = 5 [json_name = "Login"];
     */
    Login: string;
    /**
     * @generated from protobuf field: int64 UserID = 6 [json_name = "UserID"];
     */
    UserID: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class CommentAddInput$Type extends MessageType<CommentAddInput> {
    constructor() {
        super("grpcComments_service.CommentAddInput", [
            { no: 1, name: "Id", kind: "scalar", localName: "Id", jsonName: "Id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Comment", kind: "scalar", localName: "Comment", jsonName: "Comment", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ParentId", kind: "scalar", localName: "ParentId", jsonName: "ParentId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CommentAddInput>): CommentAddInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Id = 0;
        message.Comment = "";
        message.ParentId = 0;
        message.Model = "";
        if (value !== undefined)
            reflectionMergePartial<CommentAddInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CommentAddInput): CommentAddInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Id = 1 [json_name = "Id"];*/ 1:
                    message.Id = reader.int64().toNumber();
                    break;
                case /* string Comment = 2 [json_name = "Comment"];*/ 2:
                    message.Comment = reader.string();
                    break;
                case /* int64 ParentId = 3 [json_name = "ParentId"];*/ 3:
                    message.ParentId = reader.int64().toNumber();
                    break;
                case /* string Model = 4 [json_name = "Model"];*/ 4:
                    message.Model = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CommentAddInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Id = 1 [json_name = "Id"]; */
        if (message.Id !== 0)
            writer.tag(1, WireType.Varint).int64(message.Id);
        /* string Comment = 2 [json_name = "Comment"]; */
        if (message.Comment !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Comment);
        /* int64 ParentId = 3 [json_name = "ParentId"]; */
        if (message.ParentId !== 0)
            writer.tag(3, WireType.Varint).int64(message.ParentId);
        /* string Model = 4 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Model);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComments_service.CommentAddInput
 */
export const CommentAddInput = new CommentAddInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCommentsRequest$Type extends MessageType<GetCommentsRequest> {
    constructor() {
        super("grpcComments_service.GetCommentsRequest", [
            { no: 1, name: "Id", kind: "scalar", localName: "Id", jsonName: "Id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Order", kind: "scalar", localName: "Order", jsonName: "Order", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCommentsRequest>): GetCommentsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Id = 0;
        message.Model = "";
        message.Order = "";
        if (value !== undefined)
            reflectionMergePartial<GetCommentsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCommentsRequest): GetCommentsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Id = 1 [json_name = "Id"];*/ 1:
                    message.Id = reader.int64().toNumber();
                    break;
                case /* string Model = 2 [json_name = "Model"];*/ 2:
                    message.Model = reader.string();
                    break;
                case /* string Order = 3 [json_name = "Order"];*/ 3:
                    message.Order = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCommentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Id = 1 [json_name = "Id"]; */
        if (message.Id !== 0)
            writer.tag(1, WireType.Varint).int64(message.Id);
        /* string Model = 2 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Model);
        /* string Order = 3 [json_name = "Order"]; */
        if (message.Order !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Order);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComments_service.GetCommentsRequest
 */
export const GetCommentsRequest = new GetCommentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetObjectCommentsRowValue$Type extends MessageType<ListGetObjectCommentsRowValue> {
    constructor() {
        super("grpcComments_service.ListGetObjectCommentsRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetObjectCommentsRow }
        ]);
    }
    create(value?: PartialMessage<ListGetObjectCommentsRowValue>): ListGetObjectCommentsRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetObjectCommentsRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetObjectCommentsRowValue): ListGetObjectCommentsRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcComments_service.GetObjectCommentsRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetObjectCommentsRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetObjectCommentsRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcComments_service.GetObjectCommentsRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetObjectCommentsRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComments_service.ListGetObjectCommentsRowValue
 */
export const ListGetObjectCommentsRowValue = new ListGetObjectCommentsRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetObjectCommentsRow$Type extends MessageType<GetObjectCommentsRow> {
    constructor() {
        super("grpcComments_service.GetObjectCommentsRow", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Created", kind: "scalar", localName: "Created", jsonName: "Created", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Text", kind: "scalar", localName: "Text", jsonName: "Text", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "ParentCommentID", kind: "scalar", localName: "ParentCommentID", jsonName: "ParentCommentID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "Login", kind: "scalar", localName: "Login", jsonName: "Login", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "UserID", kind: "scalar", localName: "UserID", jsonName: "UserID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetObjectCommentsRow>): GetObjectCommentsRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.Created = "";
        message.Text = "";
        message.ParentCommentID = 0;
        message.Login = "";
        message.UserID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetObjectCommentsRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetObjectCommentsRow): GetObjectCommentsRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* string Created = 2 [json_name = "Created"];*/ 2:
                    message.Created = reader.string();
                    break;
                case /* string Text = 3 [json_name = "Text"];*/ 3:
                    message.Text = reader.string();
                    break;
                case /* int64 ParentCommentID = 4 [json_name = "ParentCommentID"];*/ 4:
                    message.ParentCommentID = reader.int64().toNumber();
                    break;
                case /* string Login = 5 [json_name = "Login"];*/ 5:
                    message.Login = reader.string();
                    break;
                case /* int64 UserID = 6 [json_name = "UserID"];*/ 6:
                    message.UserID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetObjectCommentsRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* string Created = 2 [json_name = "Created"]; */
        if (message.Created !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Created);
        /* string Text = 3 [json_name = "Text"]; */
        if (message.Text !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Text);
        /* int64 ParentCommentID = 4 [json_name = "ParentCommentID"]; */
        if (message.ParentCommentID !== 0)
            writer.tag(4, WireType.Varint).int64(message.ParentCommentID);
        /* string Login = 5 [json_name = "Login"]; */
        if (message.Login !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.Login);
        /* int64 UserID = 6 [json_name = "UserID"]; */
        if (message.UserID !== 0)
            writer.tag(6, WireType.Varint).int64(message.UserID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComments_service.GetObjectCommentsRow
 */
export const GetObjectCommentsRow = new GetObjectCommentsRow$Type();
/**
 * @generated ServiceType for protobuf service grpcComments_service.CommentsService
 */
export const CommentsService = new ServiceType("grpcComments_service.CommentsService", [
    { name: "GetComments", options: {}, I: GetCommentsRequest, O: ListGetObjectCommentsRowValue },
    { name: "CreateComment", options: {}, I: CommentAddInput, O: Int64Value }
]);
