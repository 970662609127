// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcSql_reports.proto" (package "grpcSql_reports", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { SqlReportsService } from "./grpcSql_reports";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { MonitorMessage } from "./grpcSql_reports";
import type { Int32Value } from "./google/protobuf/wrappers";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcSql_reports.SqlReportsService
 */
export interface ISqlReportsServiceClient {
    /**
     * @generated from protobuf rpc: RunWithIteration(google.protobuf.Int32Value) returns (stream grpcSql_reports.MonitorMessage);
     */
    runWithIteration(input: Int32Value, options?: RpcOptions): ServerStreamingCall<Int32Value, MonitorMessage>;
}
/**
 * @generated from protobuf service grpcSql_reports.SqlReportsService
 */
export class SqlReportsServiceClient implements ISqlReportsServiceClient, ServiceInfo {
    typeName = SqlReportsService.typeName;
    methods = SqlReportsService.methods;
    options = SqlReportsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: RunWithIteration(google.protobuf.Int32Value) returns (stream grpcSql_reports.MonitorMessage);
     */
    runWithIteration(input: Int32Value, options?: RpcOptions): ServerStreamingCall<Int32Value, MonitorMessage> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Int32Value, MonitorMessage>("serverStreaming", this._transport, method, opt, input);
    }
}
