// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcUsers_service.proto" (package "grpcUsers_service", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { UsersService } from "./grpcUsers_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetUsersOutput } from "./grpcUsers_service";
import type { GetUsersInput } from "./grpcUsers_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcUsers_service.UsersService
 */
export interface IUsersServiceClient {
    /**
     * @generated from protobuf rpc: GetUsers(grpcUsers_service.GetUsersInput) returns (grpcUsers_service.GetUsersOutput);
     */
    getUsers(input: GetUsersInput, options?: RpcOptions): UnaryCall<GetUsersInput, GetUsersOutput>;
}
/**
 * @generated from protobuf service grpcUsers_service.UsersService
 */
export class UsersServiceClient implements IUsersServiceClient, ServiceInfo {
    typeName = UsersService.typeName;
    methods = UsersService.methods;
    options = UsersService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetUsers(grpcUsers_service.GetUsersInput) returns (grpcUsers_service.GetUsersOutput);
     */
    getUsers(input: GetUsersInput, options?: RpcOptions): UnaryCall<GetUsersInput, GetUsersOutput> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUsersInput, GetUsersOutput>("unary", this._transport, method, opt, input);
    }
}
