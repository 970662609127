// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcComments_service.proto" (package "grpcComments_service", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { CommentsService } from "./grpcComments_service";
import type { Int64Value } from "./google/protobuf/wrappers";
import type { CommentAddInput } from "./grpcComments_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListGetObjectCommentsRowValue } from "./grpcComments_service";
import type { GetCommentsRequest } from "./grpcComments_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcComments_service.CommentsService
 */
export interface ICommentsServiceClient {
    /**
     * @generated from protobuf rpc: GetComments(grpcComments_service.GetCommentsRequest) returns (grpcComments_service.ListGetObjectCommentsRowValue);
     */
    getComments(input: GetCommentsRequest, options?: RpcOptions): UnaryCall<GetCommentsRequest, ListGetObjectCommentsRowValue>;
    /**
     * @generated from protobuf rpc: CreateComment(grpcComments_service.CommentAddInput) returns (google.protobuf.Int64Value);
     */
    createComment(input: CommentAddInput, options?: RpcOptions): UnaryCall<CommentAddInput, Int64Value>;
}
/**
 * @generated from protobuf service grpcComments_service.CommentsService
 */
export class CommentsServiceClient implements ICommentsServiceClient, ServiceInfo {
    typeName = CommentsService.typeName;
    methods = CommentsService.methods;
    options = CommentsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetComments(grpcComments_service.GetCommentsRequest) returns (grpcComments_service.ListGetObjectCommentsRowValue);
     */
    getComments(input: GetCommentsRequest, options?: RpcOptions): UnaryCall<GetCommentsRequest, ListGetObjectCommentsRowValue> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCommentsRequest, ListGetObjectCommentsRowValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateComment(grpcComments_service.CommentAddInput) returns (google.protobuf.Int64Value);
     */
    createComment(input: CommentAddInput, options?: RpcOptions): UnaryCall<CommentAddInput, Int64Value> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CommentAddInput, Int64Value>("unary", this._transport, method, opt, input);
    }
}
