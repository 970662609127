// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcSql_reports.proto" (package "grpcSql_reports", syntax proto3)
// tslint:disable
import { Int32Value } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcSql_reports.MonitorMessage
 */
export interface MonitorMessage {
    /**
     * @generated from protobuf field: string Type = 1 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: string Job = 2 [json_name = "Job"];
     */
    Job: string;
    /**
     * @generated from protobuf field: string Message = 3 [json_name = "Message"];
     */
    Message: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class MonitorMessage$Type extends MessageType<MonitorMessage> {
    constructor() {
        super("grpcSql_reports.MonitorMessage", [
            { no: 1, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Job", kind: "scalar", localName: "Job", jsonName: "Job", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Message", kind: "scalar", localName: "Message", jsonName: "Message", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MonitorMessage>): MonitorMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Type = "";
        message.Job = "";
        message.Message = "";
        if (value !== undefined)
            reflectionMergePartial<MonitorMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MonitorMessage): MonitorMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Type = 1 [json_name = "Type"];*/ 1:
                    message.Type = reader.string();
                    break;
                case /* string Job = 2 [json_name = "Job"];*/ 2:
                    message.Job = reader.string();
                    break;
                case /* string Message = 3 [json_name = "Message"];*/ 3:
                    message.Message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MonitorMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Type = 1 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Type);
        /* string Job = 2 [json_name = "Job"]; */
        if (message.Job !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Job);
        /* string Message = 3 [json_name = "Message"]; */
        if (message.Message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSql_reports.MonitorMessage
 */
export const MonitorMessage = new MonitorMessage$Type();
/**
 * @generated ServiceType for protobuf service grpcSql_reports.SqlReportsService
 */
export const SqlReportsService = new ServiceType("grpcSql_reports.SqlReportsService", [
    { name: "RunWithIteration", serverStreaming: true, options: {}, I: Int32Value, O: MonitorMessage }
]);
