// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcUsers_service.proto" (package "grpcUsers_service", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcUsers_service.GetUsersInput
 */
export interface GetUsersInput {
    /**
     * @generated from protobuf field: repeated int64 Selected = 1 [json_name = "Selected"];
     */
    Selected: number[];
    /**
     * @generated from protobuf field: string InputValue = 2 [json_name = "InputValue"];
     */
    InputValue: string;
}
/**
 * @generated from protobuf message grpcUsers_service.ListGetUsersRowValue
 */
export interface ListGetUsersRowValue {
    /**
     * @generated from protobuf field: repeated grpcUsers_service.GetUsersRow Value = 1 [json_name = "Value"];
     */
    Value: GetUsersRow[];
}
/**
 * @generated from protobuf message grpcUsers_service.GetUsersRow
 */
export interface GetUsersRow {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: string Login = 2 [json_name = "Login"];
     */
    Login: string;
    /**
     * @generated from protobuf field: string Email = 3 [json_name = "Email"];
     */
    Email: string;
    /**
     * @generated from protobuf field: string Company = 4 [json_name = "Company"];
     */
    Company: string;
    /**
     * @generated from protobuf field: string City = 5 [json_name = "City"];
     */
    City: string;
}
/**
 * @generated from protobuf message grpcUsers_service.GetUsersOutput
 */
export interface GetUsersOutput {
    /**
     * @generated from protobuf field: repeated grpcUsers_service.GetUsersRow Users = 1 [json_name = "Users"];
     */
    Users: GetUsersRow[];
}
/**
 * @generated from protobuf message grpcUsers_service.ListInt32Value
 */
export interface ListInt32Value {
    /**
     * @generated from protobuf field: repeated int64 Value = 1 [json_name = "Value"];
     */
    Value: number[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetUsersInput$Type extends MessageType<GetUsersInput> {
    constructor() {
        super("grpcUsers_service.GetUsersInput", [
            { no: 1, name: "Selected", kind: "scalar", localName: "Selected", jsonName: "Selected", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "InputValue", kind: "scalar", localName: "InputValue", jsonName: "InputValue", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUsersInput>): GetUsersInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Selected = [];
        message.InputValue = "";
        if (value !== undefined)
            reflectionMergePartial<GetUsersInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUsersInput): GetUsersInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 Selected = 1 [json_name = "Selected"];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.Selected.push(reader.int64().toNumber());
                    else
                        message.Selected.push(reader.int64().toNumber());
                    break;
                case /* string InputValue = 2 [json_name = "InputValue"];*/ 2:
                    message.InputValue = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUsersInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 Selected = 1 [json_name = "Selected"]; */
        if (message.Selected.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.Selected.length; i++)
                writer.int64(message.Selected[i]);
            writer.join();
        }
        /* string InputValue = 2 [json_name = "InputValue"]; */
        if (message.InputValue !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.InputValue);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcUsers_service.GetUsersInput
 */
export const GetUsersInput = new GetUsersInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetUsersRowValue$Type extends MessageType<ListGetUsersRowValue> {
    constructor() {
        super("grpcUsers_service.ListGetUsersRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetUsersRow }
        ]);
    }
    create(value?: PartialMessage<ListGetUsersRowValue>): ListGetUsersRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetUsersRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetUsersRowValue): ListGetUsersRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcUsers_service.GetUsersRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetUsersRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetUsersRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcUsers_service.GetUsersRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetUsersRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcUsers_service.ListGetUsersRowValue
 */
export const ListGetUsersRowValue = new ListGetUsersRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUsersRow$Type extends MessageType<GetUsersRow> {
    constructor() {
        super("grpcUsers_service.GetUsersRow", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Login", kind: "scalar", localName: "Login", jsonName: "Login", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Email", kind: "scalar", localName: "Email", jsonName: "Email", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Company", kind: "scalar", localName: "Company", jsonName: "Company", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "City", kind: "scalar", localName: "City", jsonName: "City", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUsersRow>): GetUsersRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.Login = "";
        message.Email = "";
        message.Company = "";
        message.City = "";
        if (value !== undefined)
            reflectionMergePartial<GetUsersRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUsersRow): GetUsersRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* string Login = 2 [json_name = "Login"];*/ 2:
                    message.Login = reader.string();
                    break;
                case /* string Email = 3 [json_name = "Email"];*/ 3:
                    message.Email = reader.string();
                    break;
                case /* string Company = 4 [json_name = "Company"];*/ 4:
                    message.Company = reader.string();
                    break;
                case /* string City = 5 [json_name = "City"];*/ 5:
                    message.City = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUsersRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* string Login = 2 [json_name = "Login"]; */
        if (message.Login !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Login);
        /* string Email = 3 [json_name = "Email"]; */
        if (message.Email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Email);
        /* string Company = 4 [json_name = "Company"]; */
        if (message.Company !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Company);
        /* string City = 5 [json_name = "City"]; */
        if (message.City !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.City);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcUsers_service.GetUsersRow
 */
export const GetUsersRow = new GetUsersRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUsersOutput$Type extends MessageType<GetUsersOutput> {
    constructor() {
        super("grpcUsers_service.GetUsersOutput", [
            { no: 1, name: "Users", kind: "message", localName: "Users", jsonName: "Users", repeat: 1 /*RepeatType.PACKED*/, T: () => GetUsersRow }
        ]);
    }
    create(value?: PartialMessage<GetUsersOutput>): GetUsersOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Users = [];
        if (value !== undefined)
            reflectionMergePartial<GetUsersOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUsersOutput): GetUsersOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcUsers_service.GetUsersRow Users = 1 [json_name = "Users"];*/ 1:
                    message.Users.push(GetUsersRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUsersOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcUsers_service.GetUsersRow Users = 1 [json_name = "Users"]; */
        for (let i = 0; i < message.Users.length; i++)
            GetUsersRow.internalBinaryWrite(message.Users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcUsers_service.GetUsersOutput
 */
export const GetUsersOutput = new GetUsersOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInt32Value$Type extends MessageType<ListInt32Value> {
    constructor() {
        super("grpcUsers_service.ListInt32Value", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListInt32Value>): ListInt32Value {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListInt32Value>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListInt32Value): ListInt32Value {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 Value = 1 [json_name = "Value"];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.Value.push(reader.int64().toNumber());
                    else
                        message.Value.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListInt32Value, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 Value = 1 [json_name = "Value"]; */
        if (message.Value.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.Value.length; i++)
                writer.int64(message.Value[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcUsers_service.ListInt32Value
 */
export const ListInt32Value = new ListInt32Value$Type();
/**
 * @generated ServiceType for protobuf service grpcUsers_service.UsersService
 */
export const UsersService = new ServiceType("grpcUsers_service.UsersService", [
    { name: "GetUsers", options: {}, I: GetUsersInput, O: GetUsersOutput }
]);
