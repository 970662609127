// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcSearch_by_car_service.proto" (package "grpcSearch_by_car_service", syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { StringValue } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcSearch_by_car_service.GetCarsOutput
 */
export interface GetCarsOutput {
    /**
     * @generated from protobuf field: repeated grpcSearch_by_car_service.Application Cars = 1 [json_name = "Cars"];
     */
    Cars: Application[];
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.Period
 */
export interface Period {
    /**
     * @generated from protobuf field: int64 From = 1 [json_name = "From"];
     */
    From: number;
    /**
     * @generated from protobuf field: int64 To = 2 [json_name = "To"];
     */
    To: number;
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.GetModelsInput
 */
export interface GetModelsInput {
    /**
     * @generated from protobuf field: grpcSearch_by_car_service.Period Period = 1 [json_name = "Period"];
     */
    Period?: Period;
    /**
     * @generated from protobuf field: string Brand = 2 [json_name = "Brand"];
     */
    Brand: string;
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.ListGetModelsRowValue
 */
export interface ListGetModelsRowValue {
    /**
     * @generated from protobuf field: repeated grpcSearch_by_car_service.GetModelsRow Value = 1 [json_name = "Value"];
     */
    Value: GetModelsRow[];
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.GetCarsForProductInput
 */
export interface GetCarsForProductInput {
    /**
     * @generated from protobuf field: string ProductName = 1 [json_name = "ProductName"];
     */
    ProductName: string;
    /**
     * @generated from protobuf field: string Brand = 2 [json_name = "Brand"];
     */
    Brand: string;
    /**
     * @generated from protobuf field: int64 Page = 3 [json_name = "Page"];
     */
    Page: number;
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.GetModelsRow
 */
export interface GetModelsRow {
    /**
     * @generated from protobuf field: string Series = 1 [json_name = "Series"];
     */
    Series: string;
    /**
     * @generated from protobuf field: string DateFrom = 2 [json_name = "DateFrom"];
     */
    DateFrom: string;
    /**
     * @generated from protobuf field: string DateTo = 3 [json_name = "DateTo"];
     */
    DateTo: string;
    /**
     * @generated from protobuf field: string CarType = 4 [json_name = "CarType"];
     */
    CarType: string;
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.SearchOutput
 */
export interface SearchOutput {
    /**
     * @generated from protobuf field: repeated grpcSearch_by_car_service.Application Apps = 1 [json_name = "Apps"];
     */
    Apps: Application[];
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.SearchInput
 */
export interface SearchInput {
    /**
     * @generated from protobuf field: string Mark = 1 [json_name = "Mark"];
     */
    Mark: string;
    /**
     * @generated from protobuf field: string Series = 2 [json_name = "Series"];
     */
    Series: string;
    /**
     * @generated from protobuf field: string Model = 3 [json_name = "Model"];
     */
    Model: string;
    /**
     * @generated from protobuf field: string EngineCode = 4 [json_name = "EngineCode"];
     */
    EngineCode: string;
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.GetCarsForProductOutput
 */
export interface GetCarsForProductOutput {
    /**
     * @generated from protobuf field: repeated grpcSearch_by_car_service.Application Cars = 1 [json_name = "Cars"];
     */
    Cars: Application[];
    /**
     * @generated from protobuf field: int64 Pages = 2 [json_name = "Pages"];
     */
    Pages: number;
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.ListPeriodValue
 */
export interface ListPeriodValue {
    /**
     * @generated from protobuf field: repeated grpcSearch_by_car_service.Period Value = 1 [json_name = "Value"];
     */
    Value: Period[];
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.OutputTimePeriods
 */
export interface OutputTimePeriods {
    /**
     * @generated from protobuf field: repeated grpcSearch_by_car_service.Period Values = 1 [json_name = "Values"];
     */
    Values: Period[];
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.ListStringValue
 */
export interface ListStringValue {
    /**
     * @generated from protobuf field: repeated string Value = 1 [json_name = "Value"];
     */
    Value: string[];
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.ListApplicationValue
 */
export interface ListApplicationValue {
    /**
     * @generated from protobuf field: repeated grpcSearch_by_car_service.Application Value = 1 [json_name = "Value"];
     */
    Value: Application[];
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.Application
 */
export interface Application {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: int64 TecdocId = 2 [json_name = "TecdocId"];
     */
    TecdocId: number;
    /**
     * @generated from protobuf field: string Mark = 3 [json_name = "Mark"];
     */
    Mark: string;
    /**
     * @generated from protobuf field: string Series = 4 [json_name = "Series"];
     */
    Series: string;
    /**
     * @generated from protobuf field: string Model = 5 [json_name = "Model"];
     */
    Model: string;
    /**
     * @generated from protobuf field: string EngineCode = 6 [json_name = "EngineCode"];
     */
    EngineCode: string;
    /**
     * @generated from protobuf field: string Description = 7 [json_name = "Description"];
     */
    Description: string;
    /**
     * @generated from protobuf field: int64 Capacity = 8 [json_name = "Capacity"];
     */
    Capacity: number;
    /**
     * @generated from protobuf field: string HP = 9 [json_name = "HP"];
     */
    HP: string;
    /**
     * @generated from protobuf field: string KW = 10 [json_name = "KW"];
     */
    KW: string;
    /**
     * @generated from protobuf field: int64 CapacityLit = 11 [json_name = "CapacityLit"];
     */
    CapacityLit: number;
    /**
     * @generated from protobuf field: int64 Cylinders = 12 [json_name = "Cylinders"];
     */
    Cylinders: number;
    /**
     * @generated from protobuf field: int64 Valves = 13 [json_name = "Valves"];
     */
    Valves: number;
    /**
     * @generated from protobuf field: string EngineType = 14 [json_name = "EngineType"];
     */
    EngineType: string;
    /**
     * @generated from protobuf field: string FuelType = 15 [json_name = "FuelType"];
     */
    FuelType: string;
    /**
     * @generated from protobuf field: string Drive = 16 [json_name = "Drive"];
     */
    Drive: string;
    /**
     * @generated from protobuf field: string GearBox = 17 [json_name = "GearBox"];
     */
    GearBox: string;
    /**
     * @generated from protobuf field: string BodyType = 18 [json_name = "BodyType"];
     */
    BodyType: string;
    /**
     * @generated from protobuf field: string DateFrom = 19 [json_name = "DateFrom"];
     */
    DateFrom: string;
    /**
     * @generated from protobuf field: string DateTo = 20 [json_name = "DateTo"];
     */
    DateTo: string;
    /**
     * @generated from protobuf field: repeated string Products = 21 [json_name = "Products"];
     */
    Products: string[];
    /**
     * @generated from protobuf field: string CarType = 22 [json_name = "CarType"];
     */
    CarType: string;
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.GetCarsInput
 */
export interface GetCarsInput {
    /**
     * @generated from protobuf field: string Mark = 1 [json_name = "Mark"];
     */
    Mark: string;
    /**
     * @generated from protobuf field: string Series = 2 [json_name = "Series"];
     */
    Series: string;
}
/**
 * @generated from protobuf message grpcSearch_by_car_service.GetModelsOutput
 */
export interface GetModelsOutput {
    /**
     * @generated from protobuf field: repeated grpcSearch_by_car_service.GetModelsRow Models = 1 [json_name = "Models"];
     */
    Models: GetModelsRow[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetCarsOutput$Type extends MessageType<GetCarsOutput> {
    constructor() {
        super("grpcSearch_by_car_service.GetCarsOutput", [
            { no: 1, name: "Cars", kind: "message", localName: "Cars", jsonName: "Cars", repeat: 1 /*RepeatType.PACKED*/, T: () => Application }
        ]);
    }
    create(value?: PartialMessage<GetCarsOutput>): GetCarsOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Cars = [];
        if (value !== undefined)
            reflectionMergePartial<GetCarsOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCarsOutput): GetCarsOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcSearch_by_car_service.Application Cars = 1 [json_name = "Cars"];*/ 1:
                    message.Cars.push(Application.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCarsOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcSearch_by_car_service.Application Cars = 1 [json_name = "Cars"]; */
        for (let i = 0; i < message.Cars.length; i++)
            Application.internalBinaryWrite(message.Cars[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.GetCarsOutput
 */
export const GetCarsOutput = new GetCarsOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Period$Type extends MessageType<Period> {
    constructor() {
        super("grpcSearch_by_car_service.Period", [
            { no: 1, name: "From", kind: "scalar", localName: "From", jsonName: "From", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "To", kind: "scalar", localName: "To", jsonName: "To", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Period>): Period {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.From = 0;
        message.To = 0;
        if (value !== undefined)
            reflectionMergePartial<Period>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Period): Period {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 From = 1 [json_name = "From"];*/ 1:
                    message.From = reader.int64().toNumber();
                    break;
                case /* int64 To = 2 [json_name = "To"];*/ 2:
                    message.To = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Period, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 From = 1 [json_name = "From"]; */
        if (message.From !== 0)
            writer.tag(1, WireType.Varint).int64(message.From);
        /* int64 To = 2 [json_name = "To"]; */
        if (message.To !== 0)
            writer.tag(2, WireType.Varint).int64(message.To);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.Period
 */
export const Period = new Period$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetModelsInput$Type extends MessageType<GetModelsInput> {
    constructor() {
        super("grpcSearch_by_car_service.GetModelsInput", [
            { no: 1, name: "Period", kind: "message", localName: "Period", jsonName: "Period", T: () => Period },
            { no: 2, name: "Brand", kind: "scalar", localName: "Brand", jsonName: "Brand", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetModelsInput>): GetModelsInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Brand = "";
        if (value !== undefined)
            reflectionMergePartial<GetModelsInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetModelsInput): GetModelsInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcSearch_by_car_service.Period Period = 1 [json_name = "Period"];*/ 1:
                    message.Period = Period.internalBinaryRead(reader, reader.uint32(), options, message.Period);
                    break;
                case /* string Brand = 2 [json_name = "Brand"];*/ 2:
                    message.Brand = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetModelsInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcSearch_by_car_service.Period Period = 1 [json_name = "Period"]; */
        if (message.Period)
            Period.internalBinaryWrite(message.Period, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string Brand = 2 [json_name = "Brand"]; */
        if (message.Brand !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Brand);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.GetModelsInput
 */
export const GetModelsInput = new GetModelsInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetModelsRowValue$Type extends MessageType<ListGetModelsRowValue> {
    constructor() {
        super("grpcSearch_by_car_service.ListGetModelsRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetModelsRow }
        ]);
    }
    create(value?: PartialMessage<ListGetModelsRowValue>): ListGetModelsRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetModelsRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetModelsRowValue): ListGetModelsRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcSearch_by_car_service.GetModelsRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetModelsRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetModelsRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcSearch_by_car_service.GetModelsRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetModelsRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.ListGetModelsRowValue
 */
export const ListGetModelsRowValue = new ListGetModelsRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCarsForProductInput$Type extends MessageType<GetCarsForProductInput> {
    constructor() {
        super("grpcSearch_by_car_service.GetCarsForProductInput", [
            { no: 1, name: "ProductName", kind: "scalar", localName: "ProductName", jsonName: "ProductName", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Brand", kind: "scalar", localName: "Brand", jsonName: "Brand", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Page", kind: "scalar", localName: "Page", jsonName: "Page", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetCarsForProductInput>): GetCarsForProductInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ProductName = "";
        message.Brand = "";
        message.Page = 0;
        if (value !== undefined)
            reflectionMergePartial<GetCarsForProductInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCarsForProductInput): GetCarsForProductInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ProductName = 1 [json_name = "ProductName"];*/ 1:
                    message.ProductName = reader.string();
                    break;
                case /* string Brand = 2 [json_name = "Brand"];*/ 2:
                    message.Brand = reader.string();
                    break;
                case /* int64 Page = 3 [json_name = "Page"];*/ 3:
                    message.Page = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCarsForProductInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ProductName = 1 [json_name = "ProductName"]; */
        if (message.ProductName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ProductName);
        /* string Brand = 2 [json_name = "Brand"]; */
        if (message.Brand !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Brand);
        /* int64 Page = 3 [json_name = "Page"]; */
        if (message.Page !== 0)
            writer.tag(3, WireType.Varint).int64(message.Page);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.GetCarsForProductInput
 */
export const GetCarsForProductInput = new GetCarsForProductInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetModelsRow$Type extends MessageType<GetModelsRow> {
    constructor() {
        super("grpcSearch_by_car_service.GetModelsRow", [
            { no: 1, name: "Series", kind: "scalar", localName: "Series", jsonName: "Series", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "DateFrom", kind: "scalar", localName: "DateFrom", jsonName: "DateFrom", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "DateTo", kind: "scalar", localName: "DateTo", jsonName: "DateTo", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "CarType", kind: "scalar", localName: "CarType", jsonName: "CarType", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetModelsRow>): GetModelsRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Series = "";
        message.DateFrom = "";
        message.DateTo = "";
        message.CarType = "";
        if (value !== undefined)
            reflectionMergePartial<GetModelsRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetModelsRow): GetModelsRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Series = 1 [json_name = "Series"];*/ 1:
                    message.Series = reader.string();
                    break;
                case /* string DateFrom = 2 [json_name = "DateFrom"];*/ 2:
                    message.DateFrom = reader.string();
                    break;
                case /* string DateTo = 3 [json_name = "DateTo"];*/ 3:
                    message.DateTo = reader.string();
                    break;
                case /* string CarType = 4 [json_name = "CarType"];*/ 4:
                    message.CarType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetModelsRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Series = 1 [json_name = "Series"]; */
        if (message.Series !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Series);
        /* string DateFrom = 2 [json_name = "DateFrom"]; */
        if (message.DateFrom !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.DateFrom);
        /* string DateTo = 3 [json_name = "DateTo"]; */
        if (message.DateTo !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.DateTo);
        /* string CarType = 4 [json_name = "CarType"]; */
        if (message.CarType !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.CarType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.GetModelsRow
 */
export const GetModelsRow = new GetModelsRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOutput$Type extends MessageType<SearchOutput> {
    constructor() {
        super("grpcSearch_by_car_service.SearchOutput", [
            { no: 1, name: "Apps", kind: "message", localName: "Apps", jsonName: "Apps", repeat: 1 /*RepeatType.PACKED*/, T: () => Application }
        ]);
    }
    create(value?: PartialMessage<SearchOutput>): SearchOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Apps = [];
        if (value !== undefined)
            reflectionMergePartial<SearchOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOutput): SearchOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcSearch_by_car_service.Application Apps = 1 [json_name = "Apps"];*/ 1:
                    message.Apps.push(Application.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcSearch_by_car_service.Application Apps = 1 [json_name = "Apps"]; */
        for (let i = 0; i < message.Apps.length; i++)
            Application.internalBinaryWrite(message.Apps[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.SearchOutput
 */
export const SearchOutput = new SearchOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInput$Type extends MessageType<SearchInput> {
    constructor() {
        super("grpcSearch_by_car_service.SearchInput", [
            { no: 1, name: "Mark", kind: "scalar", localName: "Mark", jsonName: "Mark", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Series", kind: "scalar", localName: "Series", jsonName: "Series", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "EngineCode", kind: "scalar", localName: "EngineCode", jsonName: "EngineCode", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchInput>): SearchInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Mark = "";
        message.Series = "";
        message.Model = "";
        message.EngineCode = "";
        if (value !== undefined)
            reflectionMergePartial<SearchInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInput): SearchInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Mark = 1 [json_name = "Mark"];*/ 1:
                    message.Mark = reader.string();
                    break;
                case /* string Series = 2 [json_name = "Series"];*/ 2:
                    message.Series = reader.string();
                    break;
                case /* string Model = 3 [json_name = "Model"];*/ 3:
                    message.Model = reader.string();
                    break;
                case /* string EngineCode = 4 [json_name = "EngineCode"];*/ 4:
                    message.EngineCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Mark = 1 [json_name = "Mark"]; */
        if (message.Mark !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Mark);
        /* string Series = 2 [json_name = "Series"]; */
        if (message.Series !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Series);
        /* string Model = 3 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Model);
        /* string EngineCode = 4 [json_name = "EngineCode"]; */
        if (message.EngineCode !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.EngineCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.SearchInput
 */
export const SearchInput = new SearchInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCarsForProductOutput$Type extends MessageType<GetCarsForProductOutput> {
    constructor() {
        super("grpcSearch_by_car_service.GetCarsForProductOutput", [
            { no: 1, name: "Cars", kind: "message", localName: "Cars", jsonName: "Cars", repeat: 1 /*RepeatType.PACKED*/, T: () => Application },
            { no: 2, name: "Pages", kind: "scalar", localName: "Pages", jsonName: "Pages", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetCarsForProductOutput>): GetCarsForProductOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Cars = [];
        message.Pages = 0;
        if (value !== undefined)
            reflectionMergePartial<GetCarsForProductOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCarsForProductOutput): GetCarsForProductOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcSearch_by_car_service.Application Cars = 1 [json_name = "Cars"];*/ 1:
                    message.Cars.push(Application.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 Pages = 2 [json_name = "Pages"];*/ 2:
                    message.Pages = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCarsForProductOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcSearch_by_car_service.Application Cars = 1 [json_name = "Cars"]; */
        for (let i = 0; i < message.Cars.length; i++)
            Application.internalBinaryWrite(message.Cars[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 Pages = 2 [json_name = "Pages"]; */
        if (message.Pages !== 0)
            writer.tag(2, WireType.Varint).int64(message.Pages);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.GetCarsForProductOutput
 */
export const GetCarsForProductOutput = new GetCarsForProductOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPeriodValue$Type extends MessageType<ListPeriodValue> {
    constructor() {
        super("grpcSearch_by_car_service.ListPeriodValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Period }
        ]);
    }
    create(value?: PartialMessage<ListPeriodValue>): ListPeriodValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListPeriodValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPeriodValue): ListPeriodValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcSearch_by_car_service.Period Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Period.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPeriodValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcSearch_by_car_service.Period Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Period.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.ListPeriodValue
 */
export const ListPeriodValue = new ListPeriodValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OutputTimePeriods$Type extends MessageType<OutputTimePeriods> {
    constructor() {
        super("grpcSearch_by_car_service.OutputTimePeriods", [
            { no: 1, name: "Values", kind: "message", localName: "Values", jsonName: "Values", repeat: 1 /*RepeatType.PACKED*/, T: () => Period }
        ]);
    }
    create(value?: PartialMessage<OutputTimePeriods>): OutputTimePeriods {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Values = [];
        if (value !== undefined)
            reflectionMergePartial<OutputTimePeriods>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OutputTimePeriods): OutputTimePeriods {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcSearch_by_car_service.Period Values = 1 [json_name = "Values"];*/ 1:
                    message.Values.push(Period.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OutputTimePeriods, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcSearch_by_car_service.Period Values = 1 [json_name = "Values"]; */
        for (let i = 0; i < message.Values.length; i++)
            Period.internalBinaryWrite(message.Values[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.OutputTimePeriods
 */
export const OutputTimePeriods = new OutputTimePeriods$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStringValue$Type extends MessageType<ListStringValue> {
    constructor() {
        super("grpcSearch_by_car_service.ListStringValue", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListStringValue>): ListStringValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListStringValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListStringValue): ListStringValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListStringValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.Value[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.ListStringValue
 */
export const ListStringValue = new ListStringValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListApplicationValue$Type extends MessageType<ListApplicationValue> {
    constructor() {
        super("grpcSearch_by_car_service.ListApplicationValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Application }
        ]);
    }
    create(value?: PartialMessage<ListApplicationValue>): ListApplicationValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListApplicationValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListApplicationValue): ListApplicationValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcSearch_by_car_service.Application Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Application.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListApplicationValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcSearch_by_car_service.Application Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Application.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.ListApplicationValue
 */
export const ListApplicationValue = new ListApplicationValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Application$Type extends MessageType<Application> {
    constructor() {
        super("grpcSearch_by_car_service.Application", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "TecdocId", kind: "scalar", localName: "TecdocId", jsonName: "TecdocId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Mark", kind: "scalar", localName: "Mark", jsonName: "Mark", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Series", kind: "scalar", localName: "Series", jsonName: "Series", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "EngineCode", kind: "scalar", localName: "EngineCode", jsonName: "EngineCode", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "Description", kind: "scalar", localName: "Description", jsonName: "Description", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "Capacity", kind: "scalar", localName: "Capacity", jsonName: "Capacity", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "HP", kind: "scalar", localName: "HP", jsonName: "HP", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "KW", kind: "scalar", localName: "KW", jsonName: "KW", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "CapacityLit", kind: "scalar", localName: "CapacityLit", jsonName: "CapacityLit", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "Cylinders", kind: "scalar", localName: "Cylinders", jsonName: "Cylinders", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "Valves", kind: "scalar", localName: "Valves", jsonName: "Valves", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "EngineType", kind: "scalar", localName: "EngineType", jsonName: "EngineType", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "FuelType", kind: "scalar", localName: "FuelType", jsonName: "FuelType", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "Drive", kind: "scalar", localName: "Drive", jsonName: "Drive", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "GearBox", kind: "scalar", localName: "GearBox", jsonName: "GearBox", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "BodyType", kind: "scalar", localName: "BodyType", jsonName: "BodyType", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "DateFrom", kind: "scalar", localName: "DateFrom", jsonName: "DateFrom", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "DateTo", kind: "scalar", localName: "DateTo", jsonName: "DateTo", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "Products", kind: "scalar", localName: "Products", jsonName: "Products", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "CarType", kind: "scalar", localName: "CarType", jsonName: "CarType", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Application>): Application {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.TecdocId = 0;
        message.Mark = "";
        message.Series = "";
        message.Model = "";
        message.EngineCode = "";
        message.Description = "";
        message.Capacity = 0;
        message.HP = "";
        message.KW = "";
        message.CapacityLit = 0;
        message.Cylinders = 0;
        message.Valves = 0;
        message.EngineType = "";
        message.FuelType = "";
        message.Drive = "";
        message.GearBox = "";
        message.BodyType = "";
        message.DateFrom = "";
        message.DateTo = "";
        message.Products = [];
        message.CarType = "";
        if (value !== undefined)
            reflectionMergePartial<Application>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Application): Application {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* int64 TecdocId = 2 [json_name = "TecdocId"];*/ 2:
                    message.TecdocId = reader.int64().toNumber();
                    break;
                case /* string Mark = 3 [json_name = "Mark"];*/ 3:
                    message.Mark = reader.string();
                    break;
                case /* string Series = 4 [json_name = "Series"];*/ 4:
                    message.Series = reader.string();
                    break;
                case /* string Model = 5 [json_name = "Model"];*/ 5:
                    message.Model = reader.string();
                    break;
                case /* string EngineCode = 6 [json_name = "EngineCode"];*/ 6:
                    message.EngineCode = reader.string();
                    break;
                case /* string Description = 7 [json_name = "Description"];*/ 7:
                    message.Description = reader.string();
                    break;
                case /* int64 Capacity = 8 [json_name = "Capacity"];*/ 8:
                    message.Capacity = reader.int64().toNumber();
                    break;
                case /* string HP = 9 [json_name = "HP"];*/ 9:
                    message.HP = reader.string();
                    break;
                case /* string KW = 10 [json_name = "KW"];*/ 10:
                    message.KW = reader.string();
                    break;
                case /* int64 CapacityLit = 11 [json_name = "CapacityLit"];*/ 11:
                    message.CapacityLit = reader.int64().toNumber();
                    break;
                case /* int64 Cylinders = 12 [json_name = "Cylinders"];*/ 12:
                    message.Cylinders = reader.int64().toNumber();
                    break;
                case /* int64 Valves = 13 [json_name = "Valves"];*/ 13:
                    message.Valves = reader.int64().toNumber();
                    break;
                case /* string EngineType = 14 [json_name = "EngineType"];*/ 14:
                    message.EngineType = reader.string();
                    break;
                case /* string FuelType = 15 [json_name = "FuelType"];*/ 15:
                    message.FuelType = reader.string();
                    break;
                case /* string Drive = 16 [json_name = "Drive"];*/ 16:
                    message.Drive = reader.string();
                    break;
                case /* string GearBox = 17 [json_name = "GearBox"];*/ 17:
                    message.GearBox = reader.string();
                    break;
                case /* string BodyType = 18 [json_name = "BodyType"];*/ 18:
                    message.BodyType = reader.string();
                    break;
                case /* string DateFrom = 19 [json_name = "DateFrom"];*/ 19:
                    message.DateFrom = reader.string();
                    break;
                case /* string DateTo = 20 [json_name = "DateTo"];*/ 20:
                    message.DateTo = reader.string();
                    break;
                case /* repeated string Products = 21 [json_name = "Products"];*/ 21:
                    message.Products.push(reader.string());
                    break;
                case /* string CarType = 22 [json_name = "CarType"];*/ 22:
                    message.CarType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Application, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* int64 TecdocId = 2 [json_name = "TecdocId"]; */
        if (message.TecdocId !== 0)
            writer.tag(2, WireType.Varint).int64(message.TecdocId);
        /* string Mark = 3 [json_name = "Mark"]; */
        if (message.Mark !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Mark);
        /* string Series = 4 [json_name = "Series"]; */
        if (message.Series !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Series);
        /* string Model = 5 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.Model);
        /* string EngineCode = 6 [json_name = "EngineCode"]; */
        if (message.EngineCode !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.EngineCode);
        /* string Description = 7 [json_name = "Description"]; */
        if (message.Description !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.Description);
        /* int64 Capacity = 8 [json_name = "Capacity"]; */
        if (message.Capacity !== 0)
            writer.tag(8, WireType.Varint).int64(message.Capacity);
        /* string HP = 9 [json_name = "HP"]; */
        if (message.HP !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.HP);
        /* string KW = 10 [json_name = "KW"]; */
        if (message.KW !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.KW);
        /* int64 CapacityLit = 11 [json_name = "CapacityLit"]; */
        if (message.CapacityLit !== 0)
            writer.tag(11, WireType.Varint).int64(message.CapacityLit);
        /* int64 Cylinders = 12 [json_name = "Cylinders"]; */
        if (message.Cylinders !== 0)
            writer.tag(12, WireType.Varint).int64(message.Cylinders);
        /* int64 Valves = 13 [json_name = "Valves"]; */
        if (message.Valves !== 0)
            writer.tag(13, WireType.Varint).int64(message.Valves);
        /* string EngineType = 14 [json_name = "EngineType"]; */
        if (message.EngineType !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.EngineType);
        /* string FuelType = 15 [json_name = "FuelType"]; */
        if (message.FuelType !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.FuelType);
        /* string Drive = 16 [json_name = "Drive"]; */
        if (message.Drive !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.Drive);
        /* string GearBox = 17 [json_name = "GearBox"]; */
        if (message.GearBox !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.GearBox);
        /* string BodyType = 18 [json_name = "BodyType"]; */
        if (message.BodyType !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.BodyType);
        /* string DateFrom = 19 [json_name = "DateFrom"]; */
        if (message.DateFrom !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.DateFrom);
        /* string DateTo = 20 [json_name = "DateTo"]; */
        if (message.DateTo !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.DateTo);
        /* repeated string Products = 21 [json_name = "Products"]; */
        for (let i = 0; i < message.Products.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.Products[i]);
        /* string CarType = 22 [json_name = "CarType"]; */
        if (message.CarType !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.CarType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.Application
 */
export const Application = new Application$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCarsInput$Type extends MessageType<GetCarsInput> {
    constructor() {
        super("grpcSearch_by_car_service.GetCarsInput", [
            { no: 1, name: "Mark", kind: "scalar", localName: "Mark", jsonName: "Mark", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Series", kind: "scalar", localName: "Series", jsonName: "Series", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCarsInput>): GetCarsInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Mark = "";
        message.Series = "";
        if (value !== undefined)
            reflectionMergePartial<GetCarsInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCarsInput): GetCarsInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Mark = 1 [json_name = "Mark"];*/ 1:
                    message.Mark = reader.string();
                    break;
                case /* string Series = 2 [json_name = "Series"];*/ 2:
                    message.Series = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCarsInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Mark = 1 [json_name = "Mark"]; */
        if (message.Mark !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Mark);
        /* string Series = 2 [json_name = "Series"]; */
        if (message.Series !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Series);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.GetCarsInput
 */
export const GetCarsInput = new GetCarsInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetModelsOutput$Type extends MessageType<GetModelsOutput> {
    constructor() {
        super("grpcSearch_by_car_service.GetModelsOutput", [
            { no: 1, name: "Models", kind: "message", localName: "Models", jsonName: "Models", repeat: 1 /*RepeatType.PACKED*/, T: () => GetModelsRow }
        ]);
    }
    create(value?: PartialMessage<GetModelsOutput>): GetModelsOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Models = [];
        if (value !== undefined)
            reflectionMergePartial<GetModelsOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetModelsOutput): GetModelsOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcSearch_by_car_service.GetModelsRow Models = 1 [json_name = "Models"];*/ 1:
                    message.Models.push(GetModelsRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetModelsOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcSearch_by_car_service.GetModelsRow Models = 1 [json_name = "Models"]; */
        for (let i = 0; i < message.Models.length; i++)
            GetModelsRow.internalBinaryWrite(message.Models[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcSearch_by_car_service.GetModelsOutput
 */
export const GetModelsOutput = new GetModelsOutput$Type();
/**
 * @generated ServiceType for protobuf service grpcSearch_by_car_service.SearchByCarService
 */
export const SearchByCarService = new ServiceType("grpcSearch_by_car_service.SearchByCarService", [
    { name: "SearchByPhrase", options: {}, I: StringValue, O: SearchOutput },
    { name: "GetBrands", options: {}, I: Empty, O: ListStringValue },
    { name: "GetBrandsForProduct", options: {}, I: StringValue, O: ListStringValue },
    { name: "Search", options: {}, I: SearchInput, O: SearchOutput },
    { name: "GetCars", options: {}, I: GetCarsInput, O: GetCarsOutput },
    { name: "GetCarsForProduct", options: {}, I: GetCarsForProductInput, O: GetCarsForProductOutput },
    { name: "GetModels", options: {}, I: GetModelsInput, O: GetModelsOutput },
    { name: "GetTimePeriods", options: {}, I: StringValue, O: OutputTimePeriods }
]);
