import { getTransport } from '../configureService';
import { MessageType } from "@protobuf-ts/runtime";
import { ConfigKey } from "@as-pl/env/src/getEnv";
import {CommentsServiceClient as ErpBackendCommentsService} from './erp-backend/grpcComments_service.client';
import {ComplainsServiceClient as ErpBackendComplainsService} from './erp-backend/grpcComplains.client';
import {ExportsServiceClient as ErpBackendExportsService} from './erp-backend/grpcExports_service.client';
import {ProducersServiceClient as ErpBackendProducersService} from './erp-backend/grpcProducers.client';
import {ProductEditServiceClient as ErpBackendProductEditService} from './erp-backend/grpcProduct_tecdoc.client';
import {ProductImageExporterServiceClient as ErpBackendProductImageExporterService} from './erp-backend/grpcProductimageexporter.client';
import {ProfilesServiceClient as ErpBackendProfilesService} from './erp-backend/grpcProfiles_service.client';
import {SqlReportsServiceClient as ErpBackendSqlReportsService} from './erp-backend/grpcSql_reports.client';
import {TecdocServiceClient as ErpBackendTecdocService} from './erp-backend/grpcTecdoc_service.client';
import {Todo2ServiceClient as ErpBackendTodo2Service} from './erp-backend/grpcTodo2_service.client';
import {UsersServiceClient as ErpBackendUsersService} from './erp-backend/grpcUsers_service.client';
import {QueueServiceClient as QueueManagerQueueService} from './queue-manager/grpcServices.client';
import {QueueServiceTaskChainClient as QueueManagerQueueServiceTaskChain} from './queue-manager/grpcServices.client';
import {AssetsServiceClient as QueueManagerAssetsService} from './queue-manager/grpcServices.client';
import {ScheduleServiceClient as QueueManagerScheduleService} from './queue-manager/grpcServices.client';
import {TrackClient as TaskMonitorTrack} from './task-monitor/grpcMonitor.client';
import {SearchByCarServiceClient as WebpageBackendSearchByCarService} from './webpage-backend/grpcSearch_by_car_service.client';


type ExtractGeneric<T> = T extends MessageType<infer U> ? U : never;


import  * as ErpBackendCommentsServiceTypesImport from './erp-backend/grpcComments_service';
type CommentAddInputErpBackendCommentsServiceType = ExtractGeneric<typeof ErpBackendCommentsServiceTypesImport.CommentAddInput>; 
type GetCommentsRequestErpBackendCommentsServiceType = ExtractGeneric<typeof ErpBackendCommentsServiceTypesImport.GetCommentsRequest>; 
type ListGetObjectCommentsRowValueErpBackendCommentsServiceType = ExtractGeneric<typeof ErpBackendCommentsServiceTypesImport.ListGetObjectCommentsRowValue>; 
type GetObjectCommentsRowErpBackendCommentsServiceType = ExtractGeneric<typeof ErpBackendCommentsServiceTypesImport.GetObjectCommentsRow>; 
import  * as ErpBackendComplainsServiceTypesImport from './erp-backend/grpcComplains';
type ListColumnValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.ListColumnValue>; 
type ColumnErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.Column>; 
type ListFilterValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.ListFilterValue>; 
type OrderErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.Order>; 
type MapStringStringValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.MapStringStringValue>; 
type DataQueryErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.DataQuery>; 
type MonitorMessageErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.MonitorMessage>; 
type FilterErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.Filter>; 
type ListOrderValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.ListOrderValue>; 
import  * as ErpBackendExportsServiceTypesImport from './erp-backend/grpcExports_service';
type TestConnErpBackendExportsServiceType = ExtractGeneric<typeof ErpBackendExportsServiceTypesImport.TestConn>; 
import  * as ErpBackendProducersServiceTypesImport from './erp-backend/grpcProducers';
type ListFilterConditionClValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListFilterConditionClValue>; 
type NullInt32ErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.NullInt32>; 
type AddCNInputErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.AddCNInput>; 
type GetProducersRowErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.GetProducersRow>; 
type ListSortModelValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListSortModelValue>; 
type ListFilterModelValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListFilterModelValue>; 
type ProducerErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.Producer>; 
type FilterConditionClErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.FilterConditionCl>; 
type FilterConditionErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.FilterCondition>; 
type FilterModelErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.FilterModel>; 
type AgGridQueryDataErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.AgGridQueryData>; 
type SortModelErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.SortModel>; 
type ListStringValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListStringValue>; 
type ListProducerValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListProducerValue>; 
type ProducersGridResultErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ProducersGridResult>; 
import  * as ErpBackendProductEditServiceTypesImport from './erp-backend/grpcProduct_tecdoc';
type SearchManyAppsOutputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchManyAppsOutput>; 
type GetAppListInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetAppListInput>; 
type IN_QueryData_Filters_ValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.IN_QueryData_Filters_Value>; 
type QueryDataErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.QueryData>; 
type AgGridQueryDataErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.AgGridQueryData>; 
type ListApplicationRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListApplicationRowValue>; 
type GetApplicationHistoryOutputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetApplicationHistoryOutput>; 
type ConnectApplicationInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ConnectApplicationInput>; 
type FilterConditionClErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.FilterConditionCl>; 
type FilterModelErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.FilterModel>; 
type ListStringValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListStringValue>; 
type ListSearchDataValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListSearchDataValue>; 
type SearchManyAppsInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchManyAppsInput>; 
type GetUnmergedAppsInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetUnmergedAppsInput>; 
type ApplicationResultErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationResult>; 
type SearchDataErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchData>; 
type GetEnginesInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetEnginesInput>; 
type ListIN_QueryData_Filters_ValueValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIN_QueryData_Filters_ValueValue>; 
type IN_QueryData_OrderErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.IN_QueryData_Order>; 
type GetApplicationHistoryInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetApplicationHistoryInput>; 
type ListGetApplicationHistoryOutputValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListGetApplicationHistoryOutputValue>; 
type ListSortModelValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListSortModelValue>; 
type SortModelErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SortModel>; 
type ListFilterConditionClValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListFilterConditionClValue>; 
type SearchAppInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchAppInput>; 
type ListGetEnginesRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListGetEnginesRowValue>; 
type ListGetLinkedCrossListRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListGetLinkedCrossListRowValue>; 
type ListInsertManyAppsInputValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListInsertManyAppsInputValue>; 
type ApplicationRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationRow>; 
type ListSearchManyAppsOutputValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListSearchManyAppsOutputValue>; 
type GetEnginesOutputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetEnginesOutput>; 
type ListIN_QueryData_OrderValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIN_QueryData_OrderValue>; 
type ListIN_QueryData_FiltersValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIN_QueryData_FiltersValue>; 
type IN_QueryData_FiltersErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.IN_QueryData_Filters>; 
type GetLinkedCrossListRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetLinkedCrossListRow>; 
type FilterConditionErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.FilterCondition>; 
type ListFilterModelValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListFilterModelValue>; 
type GetEnginesRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetEnginesRow>; 
type GetLinkedCrosslistInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetLinkedCrosslistInput>; 
type ListInt32ValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListInt32Value>; 
type InsertManyAppsInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.InsertManyAppsInput>; 
type MergeApplicationsInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.MergeApplicationsInput>; 
import  * as ErpBackendProductImageExporterServiceTypesImport from './erp-backend/grpcProductimageexporter';
type InputErpBackendProductImageExporterServiceType = ExtractGeneric<typeof ErpBackendProductImageExporterServiceTypesImport.Input>; 
import  * as ErpBackendProfilesServiceTypesImport from './erp-backend/grpcProfiles_service';
type ListStringValueErpBackendProfilesServiceType = ExtractGeneric<typeof ErpBackendProfilesServiceTypesImport.ListStringValue>; 
import  * as ErpBackendSqlReportsServiceTypesImport from './erp-backend/grpcSql_reports';
type MonitorMessageErpBackendSqlReportsServiceType = ExtractGeneric<typeof ErpBackendSqlReportsServiceTypesImport.MonitorMessage>; 
import  * as ErpBackendTecdocServiceTypesImport from './erp-backend/grpcTecdoc_service';
type BaseErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.Base>; 
type TecDocApplicationImporterMessageErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.TecDocApplicationImporterMessage>; 
type TecDocImporterMessageErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.TecDocImporterMessage>; 
type MonitorMessageErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.MonitorMessage>; 
type AuthDataErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.AuthData>; 
type MetaDataErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.MetaData>; 
import  * as ErpBackendTodo2ServiceTypesImport from './erp-backend/grpcTodo2_service';
type TodoTagErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.TodoTag>; 
type ListTodoTagValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListTodoTagValue>; 
type ListTodoValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListTodoValue>; 
type AddNewTagOutputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.AddNewTagOutput>; 
type UserErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.User>; 
type ListFileValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListFileValue>; 
type ListGetHistoryRowValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListGetHistoryRowValue>; 
type GetTodosInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.GetTodosInput>; 
type ChangeListOutputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ChangeListOutput>; 
type EditListInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.EditListInput>; 
type FilterConditionClErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.FilterConditionCl>; 
type FilterConditionErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.FilterCondition>; 
type EditTodoOutputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.EditTodoOutput>; 
type AgGridQueryDataErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.AgGridQueryData>; 
type AddListInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.AddListInput>; 
type GetTodoOutputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.GetTodoOutput>; 
type ChangeListInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ChangeListInput>; 
type TodoListErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.TodoList>; 
type SortModelErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.SortModel>; 
type ListFilterConditionClValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListFilterConditionClValue>; 
type ListFilterModelValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListFilterModelValue>; 
type GetHistoryOutputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.GetHistoryOutput>; 
type ListInt32ValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListInt32Value>; 
type DeleteTodoInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.DeleteTodoInput>; 
type NullStringErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.NullString>; 
type FilterModelErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.FilterModel>; 
type GetHistoryInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.GetHistoryInput>; 
type OptionErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.Option>; 
type StatusErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.Status>; 
type ListUserValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListUserValue>; 
type GetTodoInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.GetTodoInput>; 
type TodoErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.Todo>; 
type ListSortModelValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListSortModelValue>; 
type ListTodoListValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListTodoListValue>; 
type AddNewTagInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.AddNewTagInput>; 
type TodoConfigErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.TodoConfig>; 
type GetHistoryRowErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.GetHistoryRow>; 
type GetTodosOutputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.GetTodosOutput>; 
type ListStringValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListStringValue>; 
type ListOptionValueErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ListOptionValue>; 
type AddTodoInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.AddTodoInput>; 
type FileErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.File>; 
type ExternalFilterErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.ExternalFilter>; 
type EditTodoInputErpBackendTodo2ServiceType = ExtractGeneric<typeof ErpBackendTodo2ServiceTypesImport.EditTodoInput>; 
import  * as ErpBackendUsersServiceTypesImport from './erp-backend/grpcUsers_service';
type GetUsersInputErpBackendUsersServiceType = ExtractGeneric<typeof ErpBackendUsersServiceTypesImport.GetUsersInput>; 
type ListGetUsersRowValueErpBackendUsersServiceType = ExtractGeneric<typeof ErpBackendUsersServiceTypesImport.ListGetUsersRowValue>; 
type GetUsersRowErpBackendUsersServiceType = ExtractGeneric<typeof ErpBackendUsersServiceTypesImport.GetUsersRow>; 
type GetUsersOutputErpBackendUsersServiceType = ExtractGeneric<typeof ErpBackendUsersServiceTypesImport.GetUsersOutput>; 
type ListInt32ValueErpBackendUsersServiceType = ExtractGeneric<typeof ErpBackendUsersServiceTypesImport.ListInt32Value>; 

import  * as QueueManagerQueueServiceTypesImport from './queue-manager/grpcServices';
type DirInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.DirInfo>; 
type FiltersQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.Filters>; 
type ListPointerQueueInfoValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListPointerQueueInfoValue>; 
type QueueInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueueInfo>; 
type ListTaskDefValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListTaskDefValue>; 
type ChainLinkDefQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ChainLinkDef>; 
type DirInfoStructQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.DirInfoStruct>; 
type SchedulerTableQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.SchedulerTable>; 
type TaskInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskInfo>; 
type ListChainLinkDefValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListChainLinkDefValue>; 
type ChainDefQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ChainDef>; 
type ListFiltersValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListFiltersValue>; 
type SchedulerTableResultQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.SchedulerTableResult>; 
type TaskStreamMessageQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskStreamMessage>; 
type JobInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.JobInfo>; 
type QueueServerInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueueServerInfo>; 
type ListSortsValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListSortsValue>; 
type ListPointerTaskInfoValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListPointerTaskInfoValue>; 
type ListGetQueueMessRowValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListGetQueueMessRowValue>; 
type GetQueueMessRowQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetQueueMessRow>; 
type JobListQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.JobList>; 
type ListDirInfoStructValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListDirInfoStructValue>; 
type RunJobInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.RunJobInput>; 
type SortsQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.Sorts>; 
type QueryDataQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueryData>; 
type ListByteValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListByteValue>; 
type TaskStateQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskState>; 
type MapStringQueueDescriptionValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.MapStringQueueDescriptionValue>; 
type CancelTaskInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.CancelTaskInput>; 
type CronNextInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.CronNextInput>; 
type ListStringValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListStringValue>; 
type JobInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.JobInput>; 
type GetTasksListInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetTasksListInput>; 
type QueueDescriptionQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueueDescription>; 
type TaskDefQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskDef>; 
type ListSchedulerTableValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListSchedulerTableValue>; 
type DurationQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.Duration>; 
type GetQueueMessInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetQueueMessInput>; 
type ListJobInfoValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListJobInfoValue>; 
type GetJobInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetJobInput>; 
import  * as QueueManagerQueueServiceTaskChainTypesImport from './queue-manager/grpcServices';
type DirInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.DirInfo>; 
type FiltersQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.Filters>; 
type ListPointerQueueInfoValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListPointerQueueInfoValue>; 
type QueueInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueueInfo>; 
type ListTaskDefValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListTaskDefValue>; 
type ChainLinkDefQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ChainLinkDef>; 
type DirInfoStructQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.DirInfoStruct>; 
type SchedulerTableQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.SchedulerTable>; 
type TaskInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskInfo>; 
type ListChainLinkDefValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListChainLinkDefValue>; 
type ChainDefQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ChainDef>; 
type ListFiltersValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListFiltersValue>; 
type SchedulerTableResultQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.SchedulerTableResult>; 
type TaskStreamMessageQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskStreamMessage>; 
type JobInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.JobInfo>; 
type QueueServerInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueueServerInfo>; 
type ListSortsValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListSortsValue>; 
type ListPointerTaskInfoValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListPointerTaskInfoValue>; 
type ListGetQueueMessRowValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListGetQueueMessRowValue>; 
type GetQueueMessRowQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetQueueMessRow>; 
type JobListQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.JobList>; 
type ListDirInfoStructValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListDirInfoStructValue>; 
type RunJobInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.RunJobInput>; 
type SortsQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.Sorts>; 
type QueryDataQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueryData>; 
type ListByteValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListByteValue>; 
type TaskStateQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskState>; 
type MapStringQueueDescriptionValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.MapStringQueueDescriptionValue>; 
type CancelTaskInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.CancelTaskInput>; 
type CronNextInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.CronNextInput>; 
type ListStringValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListStringValue>; 
type JobInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.JobInput>; 
type GetTasksListInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetTasksListInput>; 
type QueueDescriptionQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueueDescription>; 
type TaskDefQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskDef>; 
type ListSchedulerTableValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListSchedulerTableValue>; 
type DurationQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.Duration>; 
type GetQueueMessInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetQueueMessInput>; 
type ListJobInfoValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListJobInfoValue>; 
type GetJobInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetJobInput>; 
import  * as QueueManagerAssetsServiceTypesImport from './queue-manager/grpcServices';
type DirInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.DirInfo>; 
type FiltersQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.Filters>; 
type ListPointerQueueInfoValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListPointerQueueInfoValue>; 
type QueueInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueueInfo>; 
type ListTaskDefValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListTaskDefValue>; 
type ChainLinkDefQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ChainLinkDef>; 
type DirInfoStructQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.DirInfoStruct>; 
type SchedulerTableQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.SchedulerTable>; 
type TaskInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskInfo>; 
type ListChainLinkDefValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListChainLinkDefValue>; 
type ChainDefQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ChainDef>; 
type ListFiltersValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListFiltersValue>; 
type SchedulerTableResultQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.SchedulerTableResult>; 
type TaskStreamMessageQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskStreamMessage>; 
type JobInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.JobInfo>; 
type QueueServerInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueueServerInfo>; 
type ListSortsValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListSortsValue>; 
type ListPointerTaskInfoValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListPointerTaskInfoValue>; 
type ListGetQueueMessRowValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListGetQueueMessRowValue>; 
type GetQueueMessRowQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetQueueMessRow>; 
type JobListQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.JobList>; 
type ListDirInfoStructValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListDirInfoStructValue>; 
type RunJobInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.RunJobInput>; 
type SortsQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.Sorts>; 
type QueryDataQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueryData>; 
type ListByteValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListByteValue>; 
type TaskStateQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskState>; 
type MapStringQueueDescriptionValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.MapStringQueueDescriptionValue>; 
type CancelTaskInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.CancelTaskInput>; 
type CronNextInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.CronNextInput>; 
type ListStringValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListStringValue>; 
type JobInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.JobInput>; 
type GetTasksListInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetTasksListInput>; 
type QueueDescriptionQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueueDescription>; 
type TaskDefQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskDef>; 
type ListSchedulerTableValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListSchedulerTableValue>; 
type DurationQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.Duration>; 
type GetQueueMessInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetQueueMessInput>; 
type ListJobInfoValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListJobInfoValue>; 
type GetJobInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetJobInput>; 
import  * as QueueManagerScheduleServiceTypesImport from './queue-manager/grpcServices';
type DirInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.DirInfo>; 
type FiltersQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.Filters>; 
type ListPointerQueueInfoValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListPointerQueueInfoValue>; 
type QueueInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.QueueInfo>; 
type ListTaskDefValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListTaskDefValue>; 
type ChainLinkDefQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ChainLinkDef>; 
type DirInfoStructQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.DirInfoStruct>; 
type SchedulerTableQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.SchedulerTable>; 
type TaskInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.TaskInfo>; 
type ListChainLinkDefValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListChainLinkDefValue>; 
type ChainDefQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ChainDef>; 
type ListFiltersValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListFiltersValue>; 
type SchedulerTableResultQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.SchedulerTableResult>; 
type TaskStreamMessageQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.TaskStreamMessage>; 
type JobInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.JobInfo>; 
type QueueServerInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.QueueServerInfo>; 
type ListSortsValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListSortsValue>; 
type ListPointerTaskInfoValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListPointerTaskInfoValue>; 
type ListGetQueueMessRowValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListGetQueueMessRowValue>; 
type GetQueueMessRowQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.GetQueueMessRow>; 
type JobListQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.JobList>; 
type ListDirInfoStructValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListDirInfoStructValue>; 
type RunJobInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.RunJobInput>; 
type SortsQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.Sorts>; 
type QueryDataQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.QueryData>; 
type ListByteValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListByteValue>; 
type TaskStateQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.TaskState>; 
type MapStringQueueDescriptionValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.MapStringQueueDescriptionValue>; 
type CancelTaskInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.CancelTaskInput>; 
type CronNextInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.CronNextInput>; 
type ListStringValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListStringValue>; 
type JobInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.JobInput>; 
type GetTasksListInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.GetTasksListInput>; 
type QueueDescriptionQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.QueueDescription>; 
type TaskDefQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.TaskDef>; 
type ListSchedulerTableValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListSchedulerTableValue>; 
type DurationQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.Duration>; 
type GetQueueMessInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.GetQueueMessInput>; 
type ListJobInfoValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListJobInfoValue>; 
type GetJobInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.GetJobInput>; 

import  * as TaskMonitorTrackTypesImport from './task-monitor/grpcMonitor';
type MonitorInputMessageTaskMonitorTrackType = ExtractGeneric<typeof TaskMonitorTrackTypesImport.MonitorInputMessage>; 
type TaskMonitorQueryTaskMonitorTrackType = ExtractGeneric<typeof TaskMonitorTrackTypesImport.TaskMonitorQuery>; 
type MonitorMessageTaskMonitorTrackType = ExtractGeneric<typeof TaskMonitorTrackTypesImport.MonitorMessage>; 

import  * as WebpageBackendSearchByCarServiceTypesImport from './webpage-backend/grpcSearch_by_car_service';
type GetCarsOutputWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.GetCarsOutput>; 
type PeriodWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.Period>; 
type GetModelsInputWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.GetModelsInput>; 
type ListGetModelsRowValueWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.ListGetModelsRowValue>; 
type GetCarsForProductInputWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.GetCarsForProductInput>; 
type GetModelsRowWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.GetModelsRow>; 
type SearchOutputWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.SearchOutput>; 
type SearchInputWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.SearchInput>; 
type GetCarsForProductOutputWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.GetCarsForProductOutput>; 
type ListPeriodValueWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.ListPeriodValue>; 
type OutputTimePeriodsWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.OutputTimePeriods>; 
type ListStringValueWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.ListStringValue>; 
type ListApplicationValueWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.ListApplicationValue>; 
type ApplicationWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.Application>; 
type GetCarsInputWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.GetCarsInput>; 
type GetModelsOutputWebpageBackendSearchByCarServiceType = ExtractGeneric<typeof WebpageBackendSearchByCarServiceTypesImport.GetModelsOutput>; 


const transports = {
  ErpBackend: getTransport( ConfigKey.NEXT_PUBLIC_BACKEND ),
  QueueManager: getTransport( ConfigKey.NEXT_PUBLIC_QUEUE_MANAGER ),
  TaskMonitor: getTransport( ConfigKey.NEXT_PUBLIC_QUEUE_MANAGER ),
  WebpageBackend: getTransport( ConfigKey.NEXT_PUBLIC_WEB_BACKEND ),
  }


export class GrpcManager {
static  ErpBackend= {
  getCommentsService(){
 return new ErpBackendCommentsService(transports.ErpBackend)  },
  getComplainsService(){
 return new ErpBackendComplainsService(transports.ErpBackend)  },
  getExportsService(){
 return new ErpBackendExportsService(transports.ErpBackend)  },
  getProducersService(){
 return new ErpBackendProducersService(transports.ErpBackend)  },
  getProductEditService(){
 return new ErpBackendProductEditService(transports.ErpBackend)  },
  getProductImageExporterService(){
 return new ErpBackendProductImageExporterService(transports.ErpBackend)  },
  getProfilesService(){
 return new ErpBackendProfilesService(transports.ErpBackend)  },
  getSqlReportsService(){
 return new ErpBackendSqlReportsService(transports.ErpBackend)  },
  getTecdocService(){
 return new ErpBackendTecdocService(transports.ErpBackend)  },
  getTodo2Service(){
 return new ErpBackendTodo2Service(transports.ErpBackend)  },
  getUsersService(){
 return new ErpBackendUsersService(transports.ErpBackend)  },
}
static  QueueManager= {
  getQueueService(){
 return new QueueManagerQueueService(transports.QueueManager)  },
  getQueueServiceTaskChain(){
 return new QueueManagerQueueServiceTaskChain(transports.QueueManager)  },
  getAssetsService(){
 return new QueueManagerAssetsService(transports.QueueManager)  },
  getScheduleService(){
 return new QueueManagerScheduleService(transports.QueueManager)  },
}
static  TaskMonitor= {
  getTrack(){
 return new TaskMonitorTrack(transports.TaskMonitor)  },
}
static  WebpageBackend= {
  getSearchByCarService(){
 return new WebpageBackendSearchByCarService(transports.WebpageBackend)  },
}
}


export namespace Types{
	export namespace ErpBackend{
		export namespace CommentsService{
			export type  CommentAddInput=CommentAddInputErpBackendCommentsServiceType;
 			export type  GetCommentsRequest=GetCommentsRequestErpBackendCommentsServiceType;
 			export type  ListGetObjectCommentsRowValue=ListGetObjectCommentsRowValueErpBackendCommentsServiceType;
 			export type  GetObjectCommentsRow=GetObjectCommentsRowErpBackendCommentsServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ComplainsService{
			export type  ListColumnValue=ListColumnValueErpBackendComplainsServiceType;
 			export type  Column=ColumnErpBackendComplainsServiceType;
 			export type  ListFilterValue=ListFilterValueErpBackendComplainsServiceType;
 			export type  Order=OrderErpBackendComplainsServiceType;
 			export type  MapStringStringValue=MapStringStringValueErpBackendComplainsServiceType;
 			export type  DataQuery=DataQueryErpBackendComplainsServiceType;
 			export type  MonitorMessage=MonitorMessageErpBackendComplainsServiceType;
 			export type  Filter=FilterErpBackendComplainsServiceType;
 			export type  ListOrderValue=ListOrderValueErpBackendComplainsServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ExportsService{
			export type  TestConn=TestConnErpBackendExportsServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProducersService{
			export type  ListFilterConditionClValue=ListFilterConditionClValueErpBackendProducersServiceType;
 			export type  NullInt32=NullInt32ErpBackendProducersServiceType;
 			export type  AddCNInput=AddCNInputErpBackendProducersServiceType;
 			export type  GetProducersRow=GetProducersRowErpBackendProducersServiceType;
 			export type  ListSortModelValue=ListSortModelValueErpBackendProducersServiceType;
 			export type  ListFilterModelValue=ListFilterModelValueErpBackendProducersServiceType;
 			export type  Producer=ProducerErpBackendProducersServiceType;
 			export type  FilterConditionCl=FilterConditionClErpBackendProducersServiceType;
 			export type  FilterCondition=FilterConditionErpBackendProducersServiceType;
 			export type  FilterModel=FilterModelErpBackendProducersServiceType;
 			export type  AgGridQueryData=AgGridQueryDataErpBackendProducersServiceType;
 			export type  SortModel=SortModelErpBackendProducersServiceType;
 			export type  ListStringValue=ListStringValueErpBackendProducersServiceType;
 			export type  ListProducerValue=ListProducerValueErpBackendProducersServiceType;
 			export type  ProducersGridResult=ProducersGridResultErpBackendProducersServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProductEditService{
			export type  SearchManyAppsOutput=SearchManyAppsOutputErpBackendProductEditServiceType;
 			export type  GetAppListInput=GetAppListInputErpBackendProductEditServiceType;
 			export type  IN_QueryData_Filters_Value=IN_QueryData_Filters_ValueErpBackendProductEditServiceType;
 			export type  QueryData=QueryDataErpBackendProductEditServiceType;
 			export type  AgGridQueryData=AgGridQueryDataErpBackendProductEditServiceType;
 			export type  ListApplicationRowValue=ListApplicationRowValueErpBackendProductEditServiceType;
 			export type  GetApplicationHistoryOutput=GetApplicationHistoryOutputErpBackendProductEditServiceType;
 			export type  ConnectApplicationInput=ConnectApplicationInputErpBackendProductEditServiceType;
 			export type  FilterConditionCl=FilterConditionClErpBackendProductEditServiceType;
 			export type  FilterModel=FilterModelErpBackendProductEditServiceType;
 			export type  ListStringValue=ListStringValueErpBackendProductEditServiceType;
 			export type  ListSearchDataValue=ListSearchDataValueErpBackendProductEditServiceType;
 			export type  SearchManyAppsInput=SearchManyAppsInputErpBackendProductEditServiceType;
 			export type  GetUnmergedAppsInput=GetUnmergedAppsInputErpBackendProductEditServiceType;
 			export type  ApplicationResult=ApplicationResultErpBackendProductEditServiceType;
 			export type  SearchData=SearchDataErpBackendProductEditServiceType;
 			export type  GetEnginesInput=GetEnginesInputErpBackendProductEditServiceType;
 			export type  ListIN_QueryData_Filters_ValueValue=ListIN_QueryData_Filters_ValueValueErpBackendProductEditServiceType;
 			export type  IN_QueryData_Order=IN_QueryData_OrderErpBackendProductEditServiceType;
 			export type  GetApplicationHistoryInput=GetApplicationHistoryInputErpBackendProductEditServiceType;
 			export type  ListGetApplicationHistoryOutputValue=ListGetApplicationHistoryOutputValueErpBackendProductEditServiceType;
 			export type  ListSortModelValue=ListSortModelValueErpBackendProductEditServiceType;
 			export type  SortModel=SortModelErpBackendProductEditServiceType;
 			export type  ListFilterConditionClValue=ListFilterConditionClValueErpBackendProductEditServiceType;
 			export type  SearchAppInput=SearchAppInputErpBackendProductEditServiceType;
 			export type  ListGetEnginesRowValue=ListGetEnginesRowValueErpBackendProductEditServiceType;
 			export type  ListGetLinkedCrossListRowValue=ListGetLinkedCrossListRowValueErpBackendProductEditServiceType;
 			export type  ListInsertManyAppsInputValue=ListInsertManyAppsInputValueErpBackendProductEditServiceType;
 			export type  ApplicationRow=ApplicationRowErpBackendProductEditServiceType;
 			export type  ListSearchManyAppsOutputValue=ListSearchManyAppsOutputValueErpBackendProductEditServiceType;
 			export type  GetEnginesOutput=GetEnginesOutputErpBackendProductEditServiceType;
 			export type  ListIN_QueryData_OrderValue=ListIN_QueryData_OrderValueErpBackendProductEditServiceType;
 			export type  ListIN_QueryData_FiltersValue=ListIN_QueryData_FiltersValueErpBackendProductEditServiceType;
 			export type  IN_QueryData_Filters=IN_QueryData_FiltersErpBackendProductEditServiceType;
 			export type  GetLinkedCrossListRow=GetLinkedCrossListRowErpBackendProductEditServiceType;
 			export type  FilterCondition=FilterConditionErpBackendProductEditServiceType;
 			export type  ListFilterModelValue=ListFilterModelValueErpBackendProductEditServiceType;
 			export type  GetEnginesRow=GetEnginesRowErpBackendProductEditServiceType;
 			export type  GetLinkedCrosslistInput=GetLinkedCrosslistInputErpBackendProductEditServiceType;
 			export type  ListInt32Value=ListInt32ValueErpBackendProductEditServiceType;
 			export type  InsertManyAppsInput=InsertManyAppsInputErpBackendProductEditServiceType;
 			export type  MergeApplicationsInput=MergeApplicationsInputErpBackendProductEditServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProductImageExporterService{
			export type  Input=InputErpBackendProductImageExporterServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProfilesService{
			export type  ListStringValue=ListStringValueErpBackendProfilesServiceType;
 }
}
	export namespace ErpBackend{
		export namespace SqlReportsService{
			export type  MonitorMessage=MonitorMessageErpBackendSqlReportsServiceType;
 }
}
	export namespace ErpBackend{
		export namespace TecdocService{
			export type  Base=BaseErpBackendTecdocServiceType;
 			export type  TecDocApplicationImporterMessage=TecDocApplicationImporterMessageErpBackendTecdocServiceType;
 			export type  TecDocImporterMessage=TecDocImporterMessageErpBackendTecdocServiceType;
 			export type  MonitorMessage=MonitorMessageErpBackendTecdocServiceType;
 			export type  AuthData=AuthDataErpBackendTecdocServiceType;
 			export type  MetaData=MetaDataErpBackendTecdocServiceType;
 }
}
	export namespace ErpBackend{
		export namespace Todo2Service{
			export type  TodoTag=TodoTagErpBackendTodo2ServiceType;
 			export type  ListTodoTagValue=ListTodoTagValueErpBackendTodo2ServiceType;
 			export type  ListTodoValue=ListTodoValueErpBackendTodo2ServiceType;
 			export type  AddNewTagOutput=AddNewTagOutputErpBackendTodo2ServiceType;
 			export type  User=UserErpBackendTodo2ServiceType;
 			export type  ListFileValue=ListFileValueErpBackendTodo2ServiceType;
 			export type  ListGetHistoryRowValue=ListGetHistoryRowValueErpBackendTodo2ServiceType;
 			export type  GetTodosInput=GetTodosInputErpBackendTodo2ServiceType;
 			export type  ChangeListOutput=ChangeListOutputErpBackendTodo2ServiceType;
 			export type  EditListInput=EditListInputErpBackendTodo2ServiceType;
 			export type  FilterConditionCl=FilterConditionClErpBackendTodo2ServiceType;
 			export type  FilterCondition=FilterConditionErpBackendTodo2ServiceType;
 			export type  EditTodoOutput=EditTodoOutputErpBackendTodo2ServiceType;
 			export type  AgGridQueryData=AgGridQueryDataErpBackendTodo2ServiceType;
 			export type  AddListInput=AddListInputErpBackendTodo2ServiceType;
 			export type  GetTodoOutput=GetTodoOutputErpBackendTodo2ServiceType;
 			export type  ChangeListInput=ChangeListInputErpBackendTodo2ServiceType;
 			export type  TodoList=TodoListErpBackendTodo2ServiceType;
 			export type  SortModel=SortModelErpBackendTodo2ServiceType;
 			export type  ListFilterConditionClValue=ListFilterConditionClValueErpBackendTodo2ServiceType;
 			export type  ListFilterModelValue=ListFilterModelValueErpBackendTodo2ServiceType;
 			export type  GetHistoryOutput=GetHistoryOutputErpBackendTodo2ServiceType;
 			export type  ListInt32Value=ListInt32ValueErpBackendTodo2ServiceType;
 			export type  DeleteTodoInput=DeleteTodoInputErpBackendTodo2ServiceType;
 			export type  NullString=NullStringErpBackendTodo2ServiceType;
 			export type  FilterModel=FilterModelErpBackendTodo2ServiceType;
 			export type  GetHistoryInput=GetHistoryInputErpBackendTodo2ServiceType;
 			export type  Option=OptionErpBackendTodo2ServiceType;
 			export type  Status=StatusErpBackendTodo2ServiceType;
 			export type  ListUserValue=ListUserValueErpBackendTodo2ServiceType;
 			export type  GetTodoInput=GetTodoInputErpBackendTodo2ServiceType;
 			export type  Todo=TodoErpBackendTodo2ServiceType;
 			export type  ListSortModelValue=ListSortModelValueErpBackendTodo2ServiceType;
 			export type  ListTodoListValue=ListTodoListValueErpBackendTodo2ServiceType;
 			export type  AddNewTagInput=AddNewTagInputErpBackendTodo2ServiceType;
 			export type  TodoConfig=TodoConfigErpBackendTodo2ServiceType;
 			export type  GetHistoryRow=GetHistoryRowErpBackendTodo2ServiceType;
 			export type  GetTodosOutput=GetTodosOutputErpBackendTodo2ServiceType;
 			export type  ListStringValue=ListStringValueErpBackendTodo2ServiceType;
 			export type  ListOptionValue=ListOptionValueErpBackendTodo2ServiceType;
 			export type  AddTodoInput=AddTodoInputErpBackendTodo2ServiceType;
 			export type  File=FileErpBackendTodo2ServiceType;
 			export type  ExternalFilter=ExternalFilterErpBackendTodo2ServiceType;
 			export type  EditTodoInput=EditTodoInputErpBackendTodo2ServiceType;
 }
}
	export namespace ErpBackend{
		export namespace UsersService{
			export type  GetUsersInput=GetUsersInputErpBackendUsersServiceType;
 			export type  ListGetUsersRowValue=ListGetUsersRowValueErpBackendUsersServiceType;
 			export type  GetUsersRow=GetUsersRowErpBackendUsersServiceType;
 			export type  GetUsersOutput=GetUsersOutputErpBackendUsersServiceType;
 			export type  ListInt32Value=ListInt32ValueErpBackendUsersServiceType;
 }
}
	export namespace QueueManager{
		export namespace QueueService{
			export type  DirInfo=DirInfoQueueManagerQueueServiceType;
 			export type  Filters=FiltersQueueManagerQueueServiceType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerQueueServiceType;
 			export type  QueueInfo=QueueInfoQueueManagerQueueServiceType;
 			export type  ListTaskDefValue=ListTaskDefValueQueueManagerQueueServiceType;
 			export type  ChainLinkDef=ChainLinkDefQueueManagerQueueServiceType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerQueueServiceType;
 			export type  SchedulerTable=SchedulerTableQueueManagerQueueServiceType;
 			export type  TaskInfo=TaskInfoQueueManagerQueueServiceType;
 			export type  ListChainLinkDefValue=ListChainLinkDefValueQueueManagerQueueServiceType;
 			export type  ChainDef=ChainDefQueueManagerQueueServiceType;
 			export type  ListFiltersValue=ListFiltersValueQueueManagerQueueServiceType;
 			export type  SchedulerTableResult=SchedulerTableResultQueueManagerQueueServiceType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerQueueServiceType;
 			export type  JobInfo=JobInfoQueueManagerQueueServiceType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerQueueServiceType;
 			export type  ListSortsValue=ListSortsValueQueueManagerQueueServiceType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerQueueServiceType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerQueueServiceType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerQueueServiceType;
 			export type  JobList=JobListQueueManagerQueueServiceType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerQueueServiceType;
 			export type  RunJobInput=RunJobInputQueueManagerQueueServiceType;
 			export type  Sorts=SortsQueueManagerQueueServiceType;
 			export type  QueryData=QueryDataQueueManagerQueueServiceType;
 			export type  ListByteValue=ListByteValueQueueManagerQueueServiceType;
 			export type  TaskState=TaskStateQueueManagerQueueServiceType;
 			export type  MapStringQueueDescriptionValue=MapStringQueueDescriptionValueQueueManagerQueueServiceType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerQueueServiceType;
 			export type  CronNextInput=CronNextInputQueueManagerQueueServiceType;
 			export type  ListStringValue=ListStringValueQueueManagerQueueServiceType;
 			export type  JobInput=JobInputQueueManagerQueueServiceType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerQueueServiceType;
 			export type  QueueDescription=QueueDescriptionQueueManagerQueueServiceType;
 			export type  TaskDef=TaskDefQueueManagerQueueServiceType;
 			export type  ListSchedulerTableValue=ListSchedulerTableValueQueueManagerQueueServiceType;
 			export type  Duration=DurationQueueManagerQueueServiceType;
 			export type  GetQueueMessInput=GetQueueMessInputQueueManagerQueueServiceType;
 			export type  ListJobInfoValue=ListJobInfoValueQueueManagerQueueServiceType;
 			export type  GetJobInput=GetJobInputQueueManagerQueueServiceType;
 }
		export namespace QueueServiceTaskChain{
			export type  DirInfo=DirInfoQueueManagerQueueServiceTaskChainType;
 			export type  Filters=FiltersQueueManagerQueueServiceTaskChainType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerQueueServiceTaskChainType;
 			export type  QueueInfo=QueueInfoQueueManagerQueueServiceTaskChainType;
 			export type  ListTaskDefValue=ListTaskDefValueQueueManagerQueueServiceTaskChainType;
 			export type  ChainLinkDef=ChainLinkDefQueueManagerQueueServiceTaskChainType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerQueueServiceTaskChainType;
 			export type  SchedulerTable=SchedulerTableQueueManagerQueueServiceTaskChainType;
 			export type  TaskInfo=TaskInfoQueueManagerQueueServiceTaskChainType;
 			export type  ListChainLinkDefValue=ListChainLinkDefValueQueueManagerQueueServiceTaskChainType;
 			export type  ChainDef=ChainDefQueueManagerQueueServiceTaskChainType;
 			export type  ListFiltersValue=ListFiltersValueQueueManagerQueueServiceTaskChainType;
 			export type  SchedulerTableResult=SchedulerTableResultQueueManagerQueueServiceTaskChainType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerQueueServiceTaskChainType;
 			export type  JobInfo=JobInfoQueueManagerQueueServiceTaskChainType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerQueueServiceTaskChainType;
 			export type  ListSortsValue=ListSortsValueQueueManagerQueueServiceTaskChainType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerQueueServiceTaskChainType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerQueueServiceTaskChainType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerQueueServiceTaskChainType;
 			export type  JobList=JobListQueueManagerQueueServiceTaskChainType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerQueueServiceTaskChainType;
 			export type  RunJobInput=RunJobInputQueueManagerQueueServiceTaskChainType;
 			export type  Sorts=SortsQueueManagerQueueServiceTaskChainType;
 			export type  QueryData=QueryDataQueueManagerQueueServiceTaskChainType;
 			export type  ListByteValue=ListByteValueQueueManagerQueueServiceTaskChainType;
 			export type  TaskState=TaskStateQueueManagerQueueServiceTaskChainType;
 			export type  MapStringQueueDescriptionValue=MapStringQueueDescriptionValueQueueManagerQueueServiceTaskChainType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerQueueServiceTaskChainType;
 			export type  CronNextInput=CronNextInputQueueManagerQueueServiceTaskChainType;
 			export type  ListStringValue=ListStringValueQueueManagerQueueServiceTaskChainType;
 			export type  JobInput=JobInputQueueManagerQueueServiceTaskChainType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerQueueServiceTaskChainType;
 			export type  QueueDescription=QueueDescriptionQueueManagerQueueServiceTaskChainType;
 			export type  TaskDef=TaskDefQueueManagerQueueServiceTaskChainType;
 			export type  ListSchedulerTableValue=ListSchedulerTableValueQueueManagerQueueServiceTaskChainType;
 			export type  Duration=DurationQueueManagerQueueServiceTaskChainType;
 			export type  GetQueueMessInput=GetQueueMessInputQueueManagerQueueServiceTaskChainType;
 			export type  ListJobInfoValue=ListJobInfoValueQueueManagerQueueServiceTaskChainType;
 			export type  GetJobInput=GetJobInputQueueManagerQueueServiceTaskChainType;
 }
		export namespace AssetsService{
			export type  DirInfo=DirInfoQueueManagerAssetsServiceType;
 			export type  Filters=FiltersQueueManagerAssetsServiceType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerAssetsServiceType;
 			export type  QueueInfo=QueueInfoQueueManagerAssetsServiceType;
 			export type  ListTaskDefValue=ListTaskDefValueQueueManagerAssetsServiceType;
 			export type  ChainLinkDef=ChainLinkDefQueueManagerAssetsServiceType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerAssetsServiceType;
 			export type  SchedulerTable=SchedulerTableQueueManagerAssetsServiceType;
 			export type  TaskInfo=TaskInfoQueueManagerAssetsServiceType;
 			export type  ListChainLinkDefValue=ListChainLinkDefValueQueueManagerAssetsServiceType;
 			export type  ChainDef=ChainDefQueueManagerAssetsServiceType;
 			export type  ListFiltersValue=ListFiltersValueQueueManagerAssetsServiceType;
 			export type  SchedulerTableResult=SchedulerTableResultQueueManagerAssetsServiceType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerAssetsServiceType;
 			export type  JobInfo=JobInfoQueueManagerAssetsServiceType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerAssetsServiceType;
 			export type  ListSortsValue=ListSortsValueQueueManagerAssetsServiceType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerAssetsServiceType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerAssetsServiceType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerAssetsServiceType;
 			export type  JobList=JobListQueueManagerAssetsServiceType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerAssetsServiceType;
 			export type  RunJobInput=RunJobInputQueueManagerAssetsServiceType;
 			export type  Sorts=SortsQueueManagerAssetsServiceType;
 			export type  QueryData=QueryDataQueueManagerAssetsServiceType;
 			export type  ListByteValue=ListByteValueQueueManagerAssetsServiceType;
 			export type  TaskState=TaskStateQueueManagerAssetsServiceType;
 			export type  MapStringQueueDescriptionValue=MapStringQueueDescriptionValueQueueManagerAssetsServiceType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerAssetsServiceType;
 			export type  CronNextInput=CronNextInputQueueManagerAssetsServiceType;
 			export type  ListStringValue=ListStringValueQueueManagerAssetsServiceType;
 			export type  JobInput=JobInputQueueManagerAssetsServiceType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerAssetsServiceType;
 			export type  QueueDescription=QueueDescriptionQueueManagerAssetsServiceType;
 			export type  TaskDef=TaskDefQueueManagerAssetsServiceType;
 			export type  ListSchedulerTableValue=ListSchedulerTableValueQueueManagerAssetsServiceType;
 			export type  Duration=DurationQueueManagerAssetsServiceType;
 			export type  GetQueueMessInput=GetQueueMessInputQueueManagerAssetsServiceType;
 			export type  ListJobInfoValue=ListJobInfoValueQueueManagerAssetsServiceType;
 			export type  GetJobInput=GetJobInputQueueManagerAssetsServiceType;
 }
		export namespace ScheduleService{
			export type  DirInfo=DirInfoQueueManagerScheduleServiceType;
 			export type  Filters=FiltersQueueManagerScheduleServiceType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerScheduleServiceType;
 			export type  QueueInfo=QueueInfoQueueManagerScheduleServiceType;
 			export type  ListTaskDefValue=ListTaskDefValueQueueManagerScheduleServiceType;
 			export type  ChainLinkDef=ChainLinkDefQueueManagerScheduleServiceType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerScheduleServiceType;
 			export type  SchedulerTable=SchedulerTableQueueManagerScheduleServiceType;
 			export type  TaskInfo=TaskInfoQueueManagerScheduleServiceType;
 			export type  ListChainLinkDefValue=ListChainLinkDefValueQueueManagerScheduleServiceType;
 			export type  ChainDef=ChainDefQueueManagerScheduleServiceType;
 			export type  ListFiltersValue=ListFiltersValueQueueManagerScheduleServiceType;
 			export type  SchedulerTableResult=SchedulerTableResultQueueManagerScheduleServiceType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerScheduleServiceType;
 			export type  JobInfo=JobInfoQueueManagerScheduleServiceType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerScheduleServiceType;
 			export type  ListSortsValue=ListSortsValueQueueManagerScheduleServiceType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerScheduleServiceType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerScheduleServiceType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerScheduleServiceType;
 			export type  JobList=JobListQueueManagerScheduleServiceType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerScheduleServiceType;
 			export type  RunJobInput=RunJobInputQueueManagerScheduleServiceType;
 			export type  Sorts=SortsQueueManagerScheduleServiceType;
 			export type  QueryData=QueryDataQueueManagerScheduleServiceType;
 			export type  ListByteValue=ListByteValueQueueManagerScheduleServiceType;
 			export type  TaskState=TaskStateQueueManagerScheduleServiceType;
 			export type  MapStringQueueDescriptionValue=MapStringQueueDescriptionValueQueueManagerScheduleServiceType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerScheduleServiceType;
 			export type  CronNextInput=CronNextInputQueueManagerScheduleServiceType;
 			export type  ListStringValue=ListStringValueQueueManagerScheduleServiceType;
 			export type  JobInput=JobInputQueueManagerScheduleServiceType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerScheduleServiceType;
 			export type  QueueDescription=QueueDescriptionQueueManagerScheduleServiceType;
 			export type  TaskDef=TaskDefQueueManagerScheduleServiceType;
 			export type  ListSchedulerTableValue=ListSchedulerTableValueQueueManagerScheduleServiceType;
 			export type  Duration=DurationQueueManagerScheduleServiceType;
 			export type  GetQueueMessInput=GetQueueMessInputQueueManagerScheduleServiceType;
 			export type  ListJobInfoValue=ListJobInfoValueQueueManagerScheduleServiceType;
 			export type  GetJobInput=GetJobInputQueueManagerScheduleServiceType;
 }
}
	export namespace TaskMonitor{
		export namespace Track{
			export type  MonitorInputMessage=MonitorInputMessageTaskMonitorTrackType;
 			export type  TaskMonitorQuery=TaskMonitorQueryTaskMonitorTrackType;
 			export type  MonitorMessage=MonitorMessageTaskMonitorTrackType;
 }
}
	export namespace WebpageBackend{
		export namespace SearchByCarService{
			export type  GetCarsOutput=GetCarsOutputWebpageBackendSearchByCarServiceType;
 			export type  Period=PeriodWebpageBackendSearchByCarServiceType;
 			export type  GetModelsInput=GetModelsInputWebpageBackendSearchByCarServiceType;
 			export type  ListGetModelsRowValue=ListGetModelsRowValueWebpageBackendSearchByCarServiceType;
 			export type  GetCarsForProductInput=GetCarsForProductInputWebpageBackendSearchByCarServiceType;
 			export type  GetModelsRow=GetModelsRowWebpageBackendSearchByCarServiceType;
 			export type  SearchOutput=SearchOutputWebpageBackendSearchByCarServiceType;
 			export type  SearchInput=SearchInputWebpageBackendSearchByCarServiceType;
 			export type  GetCarsForProductOutput=GetCarsForProductOutputWebpageBackendSearchByCarServiceType;
 			export type  ListPeriodValue=ListPeriodValueWebpageBackendSearchByCarServiceType;
 			export type  OutputTimePeriods=OutputTimePeriodsWebpageBackendSearchByCarServiceType;
 			export type  ListStringValue=ListStringValueWebpageBackendSearchByCarServiceType;
 			export type  ListApplicationValue=ListApplicationValueWebpageBackendSearchByCarServiceType;
 			export type  Application=ApplicationWebpageBackendSearchByCarServiceType;
 			export type  GetCarsInput=GetCarsInputWebpageBackendSearchByCarServiceType;
 			export type  GetModelsOutput=GetModelsOutputWebpageBackendSearchByCarServiceType;
 }
}
}
