// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcTodo2_service.proto" (package "grpcTodo2_service", syntax proto3)
// tslint:disable
import { Int64Value } from "./google/protobuf/wrappers";
import { Empty } from "./google/protobuf/empty";
import { BoolValue } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcTodo2_service.TodoTag
 */
export interface TodoTag {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: string Label = 2 [json_name = "Label"];
     */
    Label: string;
}
/**
 * @generated from protobuf message grpcTodo2_service.ListTodoTagValue
 */
export interface ListTodoTagValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.TodoTag Value = 1 [json_name = "Value"];
     */
    Value: TodoTag[];
}
/**
 * @generated from protobuf message grpcTodo2_service.ListTodoValue
 */
export interface ListTodoValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.Todo Value = 1 [json_name = "Value"];
     */
    Value: Todo[];
}
/**
 * @generated from protobuf message grpcTodo2_service.AddNewTagOutput
 */
export interface AddNewTagOutput {
    /**
     * @generated from protobuf field: grpcTodo2_service.TodoTag Tag = 1 [json_name = "Tag"];
     */
    Tag?: TodoTag;
}
/**
 * @generated from protobuf message grpcTodo2_service.User
 */
export interface User {
    /**
     * @generated from protobuf field: int64 UserId = 1 [json_name = "UserId"];
     */
    UserId: number;
    /**
     * @generated from protobuf field: string Login = 2 [json_name = "Login"];
     */
    Login: string;
    /**
     * @generated from protobuf field: bool IsMod = 3 [json_name = "IsMod"];
     */
    IsMod: boolean;
}
/**
 * @generated from protobuf message grpcTodo2_service.ListFileValue
 */
export interface ListFileValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.File Value = 1 [json_name = "Value"];
     */
    Value: File[];
}
/**
 * @generated from protobuf message grpcTodo2_service.ListGetHistoryRowValue
 */
export interface ListGetHistoryRowValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.GetHistoryRow Value = 1 [json_name = "Value"];
     */
    Value: GetHistoryRow[];
}
/**
 * @generated from protobuf message grpcTodo2_service.GetTodosInput
 */
export interface GetTodosInput {
    /**
     * @generated from protobuf field: int64 ListId = 1 [json_name = "ListId"];
     */
    ListId: number;
    /**
     * @generated from protobuf field: grpcTodo2_service.AgGridQueryData Filter = 2 [json_name = "Filter"];
     */
    Filter?: AgGridQueryData;
    /**
     * @generated from protobuf field: grpcTodo2_service.ExternalFilter ExtFilter = 3 [json_name = "ExtFilter"];
     */
    ExtFilter?: ExternalFilter;
}
/**
 * @generated from protobuf message grpcTodo2_service.ChangeListOutput
 */
export interface ChangeListOutput {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.GetHistoryRow History = 1 [json_name = "History"];
     */
    History: GetHistoryRow[];
    /**
     * @generated from protobuf field: bool IsSuccess = 2 [json_name = "IsSuccess"];
     */
    IsSuccess: boolean;
}
/**
 * @generated from protobuf message grpcTodo2_service.EditListInput
 */
export interface EditListInput {
    /**
     * @generated from protobuf field: grpcTodo2_service.TodoList List = 1 [json_name = "List"];
     */
    List?: TodoList;
}
/**
 * @generated from protobuf message grpcTodo2_service.FilterConditionCl
 */
export interface FilterConditionCl {
    /**
     * @generated from protobuf field: string Filter = 1 [json_name = "Filter"];
     */
    Filter: string;
    /**
     * @generated from protobuf field: string FilterType = 2 [json_name = "FilterType"];
     */
    FilterType: string;
    /**
     * @generated from protobuf field: string Type = 3 [json_name = "Type"];
     */
    Type: string;
}
/**
 * @generated from protobuf message grpcTodo2_service.FilterCondition
 */
export interface FilterCondition {
    /**
     * @generated from protobuf field: string Operator = 1 [json_name = "Operator"];
     */
    Operator: string;
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.FilterConditionCl Conditions = 2 [json_name = "Conditions"];
     */
    Conditions: FilterConditionCl[];
}
/**
 * @generated from protobuf message grpcTodo2_service.EditTodoOutput
 */
export interface EditTodoOutput {
    /**
     * @generated from protobuf field: bool IsSuccess = 1 [json_name = "IsSuccess"];
     */
    IsSuccess: boolean;
    /**
     * @generated from protobuf field: bool IsAssigned = 2 [json_name = "IsAssigned"];
     */
    IsAssigned: boolean;
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.GetHistoryRow History = 3 [json_name = "History"];
     */
    History: GetHistoryRow[];
}
/**
 * @generated from protobuf message grpcTodo2_service.AgGridQueryData
 */
export interface AgGridQueryData {
    /**
     * @generated from protobuf field: int64 StartRow = 1 [json_name = "StartRow"];
     */
    StartRow: number;
    /**
     * @generated from protobuf field: int64 EndRow = 2 [json_name = "EndRow"];
     */
    EndRow: number;
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.SortModel SortModel = 3 [json_name = "SortModel"];
     */
    SortModel: SortModel[];
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.FilterModel GoFilterModel = 4 [json_name = "GoFilterModel"];
     */
    GoFilterModel: FilterModel[];
}
/**
 * @generated from protobuf message grpcTodo2_service.AddListInput
 */
export interface AddListInput {
    /**
     * @generated from protobuf field: string Name = 1 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: repeated int64 Users = 2 [json_name = "Users"];
     */
    Users: number[];
    /**
     * @generated from protobuf field: repeated int64 Mods = 3 [json_name = "Mods"];
     */
    Mods: number[];
    /**
     * @generated from protobuf field: int64 Sort = 4 [json_name = "Sort"];
     */
    Sort: number;
}
/**
 * @generated from protobuf message grpcTodo2_service.GetTodoOutput
 */
export interface GetTodoOutput {
    /**
     * @generated from protobuf field: grpcTodo2_service.Todo Todo = 1 [json_name = "Todo"];
     */
    Todo?: Todo;
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.GetHistoryRow Hist = 2 [json_name = "Hist"];
     */
    Hist: GetHistoryRow[];
}
/**
 * @generated from protobuf message grpcTodo2_service.ChangeListInput
 */
export interface ChangeListInput {
    /**
     * @generated from protobuf field: string OldListName = 1 [json_name = "OldListName"];
     */
    OldListName: string;
    /**
     * @generated from protobuf field: string NewListName = 2 [json_name = "NewListName"];
     */
    NewListName: string;
    /**
     * @generated from protobuf field: int64 ListId = 3 [json_name = "ListId"];
     */
    ListId: number;
    /**
     * @generated from protobuf field: int64 TodoId = 4 [json_name = "TodoId"];
     */
    TodoId: number;
}
/**
 * @generated from protobuf message grpcTodo2_service.TodoList
 */
export interface TodoList {
    /**
     * @generated from protobuf field: string Name = 1 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: int64 Id = 2 [json_name = "Id"];
     */
    Id: number;
    /**
     * @generated from protobuf field: bool IsOwner = 3 [json_name = "IsOwner"];
     */
    IsOwner: boolean;
    /**
     * @generated from protobuf field: grpcTodo2_service.User Owner = 4 [json_name = "Owner"];
     */
    Owner?: User;
    /**
     * @generated from protobuf field: bool IsMod = 5 [json_name = "IsMod"];
     */
    IsMod: boolean;
    /**
     * @generated from protobuf field: int64 NewCount = 6 [json_name = "NewCount"];
     */
    NewCount: number;
    /**
     * @generated from protobuf field: int64 InProgressCount = 7 [json_name = "InProgressCount"];
     */
    InProgressCount: number;
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.User Group = 8 [json_name = "Group"];
     */
    Group: User[];
    /**
     * @generated from protobuf field: int64 Sort = 9 [json_name = "Sort"];
     */
    Sort: number;
}
/**
 * @generated from protobuf message grpcTodo2_service.SortModel
 */
export interface SortModel {
    /**
     * @generated from protobuf field: string Sort = 1 [json_name = "Sort"];
     */
    Sort: string;
    /**
     * @generated from protobuf field: string ColId = 2 [json_name = "ColId"];
     */
    ColId: string;
}
/**
 * @generated from protobuf message grpcTodo2_service.ListFilterConditionClValue
 */
export interface ListFilterConditionClValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.FilterConditionCl Value = 1 [json_name = "Value"];
     */
    Value: FilterConditionCl[];
}
/**
 * @generated from protobuf message grpcTodo2_service.ListFilterModelValue
 */
export interface ListFilterModelValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.FilterModel Value = 1 [json_name = "Value"];
     */
    Value: FilterModel[];
}
/**
 * @generated from protobuf message grpcTodo2_service.GetHistoryOutput
 */
export interface GetHistoryOutput {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.GetHistoryRow History = 1 [json_name = "History"];
     */
    History: GetHistoryRow[];
}
/**
 * @generated from protobuf message grpcTodo2_service.ListInt32Value
 */
export interface ListInt32Value {
    /**
     * @generated from protobuf field: repeated int64 Value = 1 [json_name = "Value"];
     */
    Value: number[];
}
/**
 * @generated from protobuf message grpcTodo2_service.DeleteTodoInput
 */
export interface DeleteTodoInput {
    /**
     * @generated from protobuf field: int64 Id = 1 [json_name = "Id"];
     */
    Id: number;
}
/**
 * @generated from protobuf message grpcTodo2_service.NullString
 */
export interface NullString {
    /**
     * @generated from protobuf field: string String = 1 [json_name = "String"];
     */
    String: string;
    /**
     * @generated from protobuf field: bool Valid = 2 [json_name = "Valid"];
     */
    Valid: boolean;
}
/**
 * @generated from protobuf message grpcTodo2_service.FilterModel
 */
export interface FilterModel {
    /**
     * @generated from protobuf field: string ColId = 1 [json_name = "ColId"];
     */
    ColId: string;
    /**
     * @generated from protobuf field: grpcTodo2_service.FilterCondition Filter = 2 [json_name = "Filter"];
     */
    Filter?: FilterCondition;
}
/**
 * @generated from protobuf message grpcTodo2_service.GetHistoryInput
 */
export interface GetHistoryInput {
    /**
     * @generated from protobuf field: int64 TodoId = 1 [json_name = "TodoId"];
     */
    TodoId: number;
}
/**
 * @generated from protobuf message grpcTodo2_service.Option
 */
export interface Option {
    /**
     * @generated from protobuf field: string Label = 1 [json_name = "Label"];
     */
    Label: string;
    /**
     * @generated from protobuf field: string Value = 2 [json_name = "Value"];
     */
    Value: string;
}
/**
 * @generated from protobuf message grpcTodo2_service.Status
 */
export interface Status {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.Option Other = 1 [json_name = "Other"];
     */
    Other: Option[];
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.Option Owner = 2 [json_name = "Owner"];
     */
    Owner: Option[];
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.Option Assigned = 3 [json_name = "Assigned"];
     */
    Assigned: Option[];
}
/**
 * @generated from protobuf message grpcTodo2_service.ListUserValue
 */
export interface ListUserValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.User Value = 1 [json_name = "Value"];
     */
    Value: User[];
}
/**
 * @generated from protobuf message grpcTodo2_service.GetTodoInput
 */
export interface GetTodoInput {
    /**
     * @generated from protobuf field: int64 TodoId = 1 [json_name = "TodoId"];
     */
    TodoId: number;
}
/**
 * @generated from protobuf message grpcTodo2_service.Todo
 */
export interface Todo {
    /**
     * @generated from protobuf field: int64 Id = 1 [json_name = "Id"];
     */
    Id: number;
    /**
     * @generated from protobuf field: string Topic = 2 [json_name = "Topic"];
     */
    Topic: string;
    /**
     * @generated from protobuf field: string Content = 3 [json_name = "Content"];
     */
    Content: string;
    /**
     * @generated from protobuf field: string Created = 4 [json_name = "Created"];
     */
    Created: string;
    /**
     * @generated from protobuf field: string Owner = 5 [json_name = "Owner"];
     */
    Owner: string;
    /**
     * @generated from protobuf field: grpcTodo2_service.Option Status = 6 [json_name = "Status"];
     */
    Status?: Option;
    /**
     * @generated from protobuf field: string Priority = 7 [json_name = "Priority"];
     */
    Priority: string;
    /**
     * @generated from protobuf field: string ListName = 8 [json_name = "ListName"];
     */
    ListName: string;
    /**
     * @generated from protobuf field: int64 ListId = 9 [json_name = "ListId"];
     */
    ListId: number;
    /**
     * @generated from protobuf field: string Begin = 10 [json_name = "Begin"];
     */
    Begin: string;
    /**
     * @generated from protobuf field: string End = 11 [json_name = "End"];
     */
    End: string;
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.User Assigned = 12 [json_name = "Assigned"];
     */
    Assigned: User[];
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.TodoTag Tags = 13 [json_name = "Tags"];
     */
    Tags: TodoTag[];
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.File Files = 14 [json_name = "Files"];
     */
    Files: File[];
    /**
     * @generated from protobuf field: bool IsOwner = 15 [json_name = "IsOwner"];
     */
    IsOwner: boolean;
    /**
     * @generated from protobuf field: bool IsAssigned = 16 [json_name = "IsAssigned"];
     */
    IsAssigned: boolean;
}
/**
 * @generated from protobuf message grpcTodo2_service.ListSortModelValue
 */
export interface ListSortModelValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.SortModel Value = 1 [json_name = "Value"];
     */
    Value: SortModel[];
}
/**
 * @generated from protobuf message grpcTodo2_service.ListTodoListValue
 */
export interface ListTodoListValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.TodoList Value = 1 [json_name = "Value"];
     */
    Value: TodoList[];
}
/**
 * @generated from protobuf message grpcTodo2_service.AddNewTagInput
 */
export interface AddNewTagInput {
    /**
     * @generated from protobuf field: string Tag = 1 [json_name = "Tag"];
     */
    Tag: string;
}
/**
 * @generated from protobuf message grpcTodo2_service.TodoConfig
 */
export interface TodoConfig {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.TodoTag Tags = 1 [json_name = "Tags"];
     */
    Tags: TodoTag[];
    /**
     * @generated from protobuf field: repeated string Priority = 2 [json_name = "Priority"];
     */
    Priority: string[];
    /**
     * @generated from protobuf field: grpcTodo2_service.Status Status = 3 [json_name = "Status"];
     */
    Status?: Status;
    /**
     * @generated from protobuf field: bool IsDev = 4 [json_name = "IsDev"];
     */
    IsDev: boolean;
}
/**
 * @generated from protobuf message grpcTodo2_service.GetHistoryRow
 */
export interface GetHistoryRow {
    /**
     * @generated from protobuf field: string Created = 1 [json_name = "Created"];
     */
    Created: string;
    /**
     * @generated from protobuf field: grpcTodo2_service.NullString Login = 2 [json_name = "Login"];
     */
    Login?: NullString;
    /**
     * @generated from protobuf field: string Content = 3 [json_name = "Content"];
     */
    Content: string;
}
/**
 * @generated from protobuf message grpcTodo2_service.GetTodosOutput
 */
export interface GetTodosOutput {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.Todo Todos = 1 [json_name = "Todos"];
     */
    Todos: Todo[];
    /**
     * @generated from protobuf field: int64 Count = 2 [json_name = "Count"];
     */
    Count: number;
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.TodoList TodoLists = 3 [json_name = "TodoLists"];
     */
    TodoLists: TodoList[];
}
/**
 * @generated from protobuf message grpcTodo2_service.ListStringValue
 */
export interface ListStringValue {
    /**
     * @generated from protobuf field: repeated string Value = 1 [json_name = "Value"];
     */
    Value: string[];
}
/**
 * @generated from protobuf message grpcTodo2_service.ListOptionValue
 */
export interface ListOptionValue {
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.Option Value = 1 [json_name = "Value"];
     */
    Value: Option[];
}
/**
 * @generated from protobuf message grpcTodo2_service.AddTodoInput
 */
export interface AddTodoInput {
    /**
     * @generated from protobuf field: string Topic = 1 [json_name = "Topic"];
     */
    Topic: string;
    /**
     * @generated from protobuf field: string Content = 2 [json_name = "Content"];
     */
    Content: string;
    /**
     * @generated from protobuf field: string Status = 3 [json_name = "Status"];
     */
    Status: string;
    /**
     * @generated from protobuf field: string Priority = 4 [json_name = "Priority"];
     */
    Priority: string;
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.User Assign = 5 [json_name = "Assign"];
     */
    Assign: User[];
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.TodoTag Tags = 6 [json_name = "Tags"];
     */
    Tags: TodoTag[];
    /**
     * @generated from protobuf field: int64 ListId = 7 [json_name = "ListId"];
     */
    ListId: number;
}
/**
 * @generated from protobuf message grpcTodo2_service.File
 */
export interface File {
    /**
     * @generated from protobuf field: int64 Id = 1 [json_name = "Id"];
     */
    Id: number;
    /**
     * @generated from protobuf field: string Name = 2 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: string File = 3 [json_name = "File"];
     */
    File: string;
    /**
     * @generated from protobuf field: string Path = 4 [json_name = "Path"];
     */
    Path: string;
    /**
     * @generated from protobuf field: string Hash = 5 [json_name = "Hash"];
     */
    Hash: string;
    /**
     * @generated from protobuf field: bool Gallery = 6 [json_name = "Gallery"];
     */
    Gallery: boolean;
}
/**
 * @generated from protobuf message grpcTodo2_service.ExternalFilter
 */
export interface ExternalFilter {
    /**
     * @generated from protobuf field: string Topic = 1 [json_name = "Topic"];
     */
    Topic: string;
    /**
     * @generated from protobuf field: bool Todo = 2 [json_name = "Todo"];
     */
    Todo: boolean;
    /**
     * @generated from protobuf field: repeated grpcTodo2_service.Option Status = 3 [json_name = "Status"];
     */
    Status: Option[];
    /**
     * @generated from protobuf field: repeated string Assigned = 4 [json_name = "Assigned"];
     */
    Assigned: string[];
    /**
     * @generated from protobuf field: repeated string Priority = 5 [json_name = "Priority"];
     */
    Priority: string[];
}
/**
 * @generated from protobuf message grpcTodo2_service.EditTodoInput
 */
export interface EditTodoInput {
    /**
     * @generated from protobuf field: grpcTodo2_service.Todo Todo = 1 [json_name = "Todo"];
     */
    Todo?: Todo;
}
// @generated message type with reflection information, may provide speed optimized methods
class TodoTag$Type extends MessageType<TodoTag> {
    constructor() {
        super("grpcTodo2_service.TodoTag", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Label", kind: "scalar", localName: "Label", jsonName: "Label", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TodoTag>): TodoTag {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.Label = "";
        if (value !== undefined)
            reflectionMergePartial<TodoTag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TodoTag): TodoTag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* string Label = 2 [json_name = "Label"];*/ 2:
                    message.Label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TodoTag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* string Label = 2 [json_name = "Label"]; */
        if (message.Label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.TodoTag
 */
export const TodoTag = new TodoTag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTodoTagValue$Type extends MessageType<ListTodoTagValue> {
    constructor() {
        super("grpcTodo2_service.ListTodoTagValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => TodoTag }
        ]);
    }
    create(value?: PartialMessage<ListTodoTagValue>): ListTodoTagValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListTodoTagValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTodoTagValue): ListTodoTagValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.TodoTag Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(TodoTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTodoTagValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.TodoTag Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            TodoTag.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListTodoTagValue
 */
export const ListTodoTagValue = new ListTodoTagValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTodoValue$Type extends MessageType<ListTodoValue> {
    constructor() {
        super("grpcTodo2_service.ListTodoValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Todo }
        ]);
    }
    create(value?: PartialMessage<ListTodoValue>): ListTodoValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListTodoValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTodoValue): ListTodoValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.Todo Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Todo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTodoValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.Todo Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Todo.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListTodoValue
 */
export const ListTodoValue = new ListTodoValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddNewTagOutput$Type extends MessageType<AddNewTagOutput> {
    constructor() {
        super("grpcTodo2_service.AddNewTagOutput", [
            { no: 1, name: "Tag", kind: "message", localName: "Tag", jsonName: "Tag", T: () => TodoTag }
        ]);
    }
    create(value?: PartialMessage<AddNewTagOutput>): AddNewTagOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AddNewTagOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddNewTagOutput): AddNewTagOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcTodo2_service.TodoTag Tag = 1 [json_name = "Tag"];*/ 1:
                    message.Tag = TodoTag.internalBinaryRead(reader, reader.uint32(), options, message.Tag);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddNewTagOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcTodo2_service.TodoTag Tag = 1 [json_name = "Tag"]; */
        if (message.Tag)
            TodoTag.internalBinaryWrite(message.Tag, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.AddNewTagOutput
 */
export const AddNewTagOutput = new AddNewTagOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("grpcTodo2_service.User", [
            { no: 1, name: "UserId", kind: "scalar", localName: "UserId", jsonName: "UserId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Login", kind: "scalar", localName: "Login", jsonName: "Login", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "IsMod", kind: "scalar", localName: "IsMod", jsonName: "IsMod", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.UserId = 0;
        message.Login = "";
        message.IsMod = false;
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 UserId = 1 [json_name = "UserId"];*/ 1:
                    message.UserId = reader.int64().toNumber();
                    break;
                case /* string Login = 2 [json_name = "Login"];*/ 2:
                    message.Login = reader.string();
                    break;
                case /* bool IsMod = 3 [json_name = "IsMod"];*/ 3:
                    message.IsMod = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 UserId = 1 [json_name = "UserId"]; */
        if (message.UserId !== 0)
            writer.tag(1, WireType.Varint).int64(message.UserId);
        /* string Login = 2 [json_name = "Login"]; */
        if (message.Login !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Login);
        /* bool IsMod = 3 [json_name = "IsMod"]; */
        if (message.IsMod !== false)
            writer.tag(3, WireType.Varint).bool(message.IsMod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFileValue$Type extends MessageType<ListFileValue> {
    constructor() {
        super("grpcTodo2_service.ListFileValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => File }
        ]);
    }
    create(value?: PartialMessage<ListFileValue>): ListFileValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListFileValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFileValue): ListFileValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.File Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(File.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFileValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.File Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            File.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListFileValue
 */
export const ListFileValue = new ListFileValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetHistoryRowValue$Type extends MessageType<ListGetHistoryRowValue> {
    constructor() {
        super("grpcTodo2_service.ListGetHistoryRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetHistoryRow }
        ]);
    }
    create(value?: PartialMessage<ListGetHistoryRowValue>): ListGetHistoryRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetHistoryRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetHistoryRowValue): ListGetHistoryRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.GetHistoryRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetHistoryRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetHistoryRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.GetHistoryRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetHistoryRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListGetHistoryRowValue
 */
export const ListGetHistoryRowValue = new ListGetHistoryRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTodosInput$Type extends MessageType<GetTodosInput> {
    constructor() {
        super("grpcTodo2_service.GetTodosInput", [
            { no: 1, name: "ListId", kind: "scalar", localName: "ListId", jsonName: "ListId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Filter", kind: "message", localName: "Filter", jsonName: "Filter", T: () => AgGridQueryData },
            { no: 3, name: "ExtFilter", kind: "message", localName: "ExtFilter", jsonName: "ExtFilter", T: () => ExternalFilter }
        ]);
    }
    create(value?: PartialMessage<GetTodosInput>): GetTodosInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ListId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTodosInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTodosInput): GetTodosInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ListId = 1 [json_name = "ListId"];*/ 1:
                    message.ListId = reader.int64().toNumber();
                    break;
                case /* grpcTodo2_service.AgGridQueryData Filter = 2 [json_name = "Filter"];*/ 2:
                    message.Filter = AgGridQueryData.internalBinaryRead(reader, reader.uint32(), options, message.Filter);
                    break;
                case /* grpcTodo2_service.ExternalFilter ExtFilter = 3 [json_name = "ExtFilter"];*/ 3:
                    message.ExtFilter = ExternalFilter.internalBinaryRead(reader, reader.uint32(), options, message.ExtFilter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTodosInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ListId = 1 [json_name = "ListId"]; */
        if (message.ListId !== 0)
            writer.tag(1, WireType.Varint).int64(message.ListId);
        /* grpcTodo2_service.AgGridQueryData Filter = 2 [json_name = "Filter"]; */
        if (message.Filter)
            AgGridQueryData.internalBinaryWrite(message.Filter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* grpcTodo2_service.ExternalFilter ExtFilter = 3 [json_name = "ExtFilter"]; */
        if (message.ExtFilter)
            ExternalFilter.internalBinaryWrite(message.ExtFilter, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.GetTodosInput
 */
export const GetTodosInput = new GetTodosInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeListOutput$Type extends MessageType<ChangeListOutput> {
    constructor() {
        super("grpcTodo2_service.ChangeListOutput", [
            { no: 1, name: "History", kind: "message", localName: "History", jsonName: "History", repeat: 1 /*RepeatType.PACKED*/, T: () => GetHistoryRow },
            { no: 2, name: "IsSuccess", kind: "scalar", localName: "IsSuccess", jsonName: "IsSuccess", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ChangeListOutput>): ChangeListOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.History = [];
        message.IsSuccess = false;
        if (value !== undefined)
            reflectionMergePartial<ChangeListOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChangeListOutput): ChangeListOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.GetHistoryRow History = 1 [json_name = "History"];*/ 1:
                    message.History.push(GetHistoryRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool IsSuccess = 2 [json_name = "IsSuccess"];*/ 2:
                    message.IsSuccess = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChangeListOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.GetHistoryRow History = 1 [json_name = "History"]; */
        for (let i = 0; i < message.History.length; i++)
            GetHistoryRow.internalBinaryWrite(message.History[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool IsSuccess = 2 [json_name = "IsSuccess"]; */
        if (message.IsSuccess !== false)
            writer.tag(2, WireType.Varint).bool(message.IsSuccess);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ChangeListOutput
 */
export const ChangeListOutput = new ChangeListOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditListInput$Type extends MessageType<EditListInput> {
    constructor() {
        super("grpcTodo2_service.EditListInput", [
            { no: 1, name: "List", kind: "message", localName: "List", jsonName: "List", T: () => TodoList }
        ]);
    }
    create(value?: PartialMessage<EditListInput>): EditListInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EditListInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditListInput): EditListInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcTodo2_service.TodoList List = 1 [json_name = "List"];*/ 1:
                    message.List = TodoList.internalBinaryRead(reader, reader.uint32(), options, message.List);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditListInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcTodo2_service.TodoList List = 1 [json_name = "List"]; */
        if (message.List)
            TodoList.internalBinaryWrite(message.List, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.EditListInput
 */
export const EditListInput = new EditListInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterConditionCl$Type extends MessageType<FilterConditionCl> {
    constructor() {
        super("grpcTodo2_service.FilterConditionCl", [
            { no: 1, name: "Filter", kind: "scalar", localName: "Filter", jsonName: "Filter", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "FilterType", kind: "scalar", localName: "FilterType", jsonName: "FilterType", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FilterConditionCl>): FilterConditionCl {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Filter = "";
        message.FilterType = "";
        message.Type = "";
        if (value !== undefined)
            reflectionMergePartial<FilterConditionCl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterConditionCl): FilterConditionCl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Filter = 1 [json_name = "Filter"];*/ 1:
                    message.Filter = reader.string();
                    break;
                case /* string FilterType = 2 [json_name = "FilterType"];*/ 2:
                    message.FilterType = reader.string();
                    break;
                case /* string Type = 3 [json_name = "Type"];*/ 3:
                    message.Type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterConditionCl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Filter = 1 [json_name = "Filter"]; */
        if (message.Filter !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Filter);
        /* string FilterType = 2 [json_name = "FilterType"]; */
        if (message.FilterType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.FilterType);
        /* string Type = 3 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.FilterConditionCl
 */
export const FilterConditionCl = new FilterConditionCl$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterCondition$Type extends MessageType<FilterCondition> {
    constructor() {
        super("grpcTodo2_service.FilterCondition", [
            { no: 1, name: "Operator", kind: "scalar", localName: "Operator", jsonName: "Operator", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Conditions", kind: "message", localName: "Conditions", jsonName: "Conditions", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterConditionCl }
        ]);
    }
    create(value?: PartialMessage<FilterCondition>): FilterCondition {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Operator = "";
        message.Conditions = [];
        if (value !== undefined)
            reflectionMergePartial<FilterCondition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterCondition): FilterCondition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Operator = 1 [json_name = "Operator"];*/ 1:
                    message.Operator = reader.string();
                    break;
                case /* repeated grpcTodo2_service.FilterConditionCl Conditions = 2 [json_name = "Conditions"];*/ 2:
                    message.Conditions.push(FilterConditionCl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterCondition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Operator = 1 [json_name = "Operator"]; */
        if (message.Operator !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Operator);
        /* repeated grpcTodo2_service.FilterConditionCl Conditions = 2 [json_name = "Conditions"]; */
        for (let i = 0; i < message.Conditions.length; i++)
            FilterConditionCl.internalBinaryWrite(message.Conditions[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.FilterCondition
 */
export const FilterCondition = new FilterCondition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditTodoOutput$Type extends MessageType<EditTodoOutput> {
    constructor() {
        super("grpcTodo2_service.EditTodoOutput", [
            { no: 1, name: "IsSuccess", kind: "scalar", localName: "IsSuccess", jsonName: "IsSuccess", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "IsAssigned", kind: "scalar", localName: "IsAssigned", jsonName: "IsAssigned", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "History", kind: "message", localName: "History", jsonName: "History", repeat: 1 /*RepeatType.PACKED*/, T: () => GetHistoryRow }
        ]);
    }
    create(value?: PartialMessage<EditTodoOutput>): EditTodoOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.IsSuccess = false;
        message.IsAssigned = false;
        message.History = [];
        if (value !== undefined)
            reflectionMergePartial<EditTodoOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditTodoOutput): EditTodoOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool IsSuccess = 1 [json_name = "IsSuccess"];*/ 1:
                    message.IsSuccess = reader.bool();
                    break;
                case /* bool IsAssigned = 2 [json_name = "IsAssigned"];*/ 2:
                    message.IsAssigned = reader.bool();
                    break;
                case /* repeated grpcTodo2_service.GetHistoryRow History = 3 [json_name = "History"];*/ 3:
                    message.History.push(GetHistoryRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditTodoOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool IsSuccess = 1 [json_name = "IsSuccess"]; */
        if (message.IsSuccess !== false)
            writer.tag(1, WireType.Varint).bool(message.IsSuccess);
        /* bool IsAssigned = 2 [json_name = "IsAssigned"]; */
        if (message.IsAssigned !== false)
            writer.tag(2, WireType.Varint).bool(message.IsAssigned);
        /* repeated grpcTodo2_service.GetHistoryRow History = 3 [json_name = "History"]; */
        for (let i = 0; i < message.History.length; i++)
            GetHistoryRow.internalBinaryWrite(message.History[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.EditTodoOutput
 */
export const EditTodoOutput = new EditTodoOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgGridQueryData$Type extends MessageType<AgGridQueryData> {
    constructor() {
        super("grpcTodo2_service.AgGridQueryData", [
            { no: 1, name: "StartRow", kind: "scalar", localName: "StartRow", jsonName: "StartRow", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "EndRow", kind: "scalar", localName: "EndRow", jsonName: "EndRow", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "SortModel", kind: "message", localName: "SortModel", jsonName: "SortModel", repeat: 1 /*RepeatType.PACKED*/, T: () => SortModel },
            { no: 4, name: "GoFilterModel", kind: "message", localName: "GoFilterModel", jsonName: "GoFilterModel", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterModel }
        ]);
    }
    create(value?: PartialMessage<AgGridQueryData>): AgGridQueryData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.StartRow = 0;
        message.EndRow = 0;
        message.SortModel = [];
        message.GoFilterModel = [];
        if (value !== undefined)
            reflectionMergePartial<AgGridQueryData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgGridQueryData): AgGridQueryData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 StartRow = 1 [json_name = "StartRow"];*/ 1:
                    message.StartRow = reader.int64().toNumber();
                    break;
                case /* int64 EndRow = 2 [json_name = "EndRow"];*/ 2:
                    message.EndRow = reader.int64().toNumber();
                    break;
                case /* repeated grpcTodo2_service.SortModel SortModel = 3 [json_name = "SortModel"];*/ 3:
                    message.SortModel.push(SortModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcTodo2_service.FilterModel GoFilterModel = 4 [json_name = "GoFilterModel"];*/ 4:
                    message.GoFilterModel.push(FilterModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgGridQueryData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 StartRow = 1 [json_name = "StartRow"]; */
        if (message.StartRow !== 0)
            writer.tag(1, WireType.Varint).int64(message.StartRow);
        /* int64 EndRow = 2 [json_name = "EndRow"]; */
        if (message.EndRow !== 0)
            writer.tag(2, WireType.Varint).int64(message.EndRow);
        /* repeated grpcTodo2_service.SortModel SortModel = 3 [json_name = "SortModel"]; */
        for (let i = 0; i < message.SortModel.length; i++)
            SortModel.internalBinaryWrite(message.SortModel[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcTodo2_service.FilterModel GoFilterModel = 4 [json_name = "GoFilterModel"]; */
        for (let i = 0; i < message.GoFilterModel.length; i++)
            FilterModel.internalBinaryWrite(message.GoFilterModel[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.AgGridQueryData
 */
export const AgGridQueryData = new AgGridQueryData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddListInput$Type extends MessageType<AddListInput> {
    constructor() {
        super("grpcTodo2_service.AddListInput", [
            { no: 1, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Users", kind: "scalar", localName: "Users", jsonName: "Users", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Mods", kind: "scalar", localName: "Mods", jsonName: "Mods", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "Sort", kind: "scalar", localName: "Sort", jsonName: "Sort", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AddListInput>): AddListInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Name = "";
        message.Users = [];
        message.Mods = [];
        message.Sort = 0;
        if (value !== undefined)
            reflectionMergePartial<AddListInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddListInput): AddListInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Name = 1 [json_name = "Name"];*/ 1:
                    message.Name = reader.string();
                    break;
                case /* repeated int64 Users = 2 [json_name = "Users"];*/ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.Users.push(reader.int64().toNumber());
                    else
                        message.Users.push(reader.int64().toNumber());
                    break;
                case /* repeated int64 Mods = 3 [json_name = "Mods"];*/ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.Mods.push(reader.int64().toNumber());
                    else
                        message.Mods.push(reader.int64().toNumber());
                    break;
                case /* int64 Sort = 4 [json_name = "Sort"];*/ 4:
                    message.Sort = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddListInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Name = 1 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Name);
        /* repeated int64 Users = 2 [json_name = "Users"]; */
        if (message.Users.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.Users.length; i++)
                writer.int64(message.Users[i]);
            writer.join();
        }
        /* repeated int64 Mods = 3 [json_name = "Mods"]; */
        if (message.Mods.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.Mods.length; i++)
                writer.int64(message.Mods[i]);
            writer.join();
        }
        /* int64 Sort = 4 [json_name = "Sort"]; */
        if (message.Sort !== 0)
            writer.tag(4, WireType.Varint).int64(message.Sort);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.AddListInput
 */
export const AddListInput = new AddListInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTodoOutput$Type extends MessageType<GetTodoOutput> {
    constructor() {
        super("grpcTodo2_service.GetTodoOutput", [
            { no: 1, name: "Todo", kind: "message", localName: "Todo", jsonName: "Todo", T: () => Todo },
            { no: 2, name: "Hist", kind: "message", localName: "Hist", jsonName: "Hist", repeat: 1 /*RepeatType.PACKED*/, T: () => GetHistoryRow }
        ]);
    }
    create(value?: PartialMessage<GetTodoOutput>): GetTodoOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Hist = [];
        if (value !== undefined)
            reflectionMergePartial<GetTodoOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTodoOutput): GetTodoOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcTodo2_service.Todo Todo = 1 [json_name = "Todo"];*/ 1:
                    message.Todo = Todo.internalBinaryRead(reader, reader.uint32(), options, message.Todo);
                    break;
                case /* repeated grpcTodo2_service.GetHistoryRow Hist = 2 [json_name = "Hist"];*/ 2:
                    message.Hist.push(GetHistoryRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTodoOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcTodo2_service.Todo Todo = 1 [json_name = "Todo"]; */
        if (message.Todo)
            Todo.internalBinaryWrite(message.Todo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcTodo2_service.GetHistoryRow Hist = 2 [json_name = "Hist"]; */
        for (let i = 0; i < message.Hist.length; i++)
            GetHistoryRow.internalBinaryWrite(message.Hist[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.GetTodoOutput
 */
export const GetTodoOutput = new GetTodoOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeListInput$Type extends MessageType<ChangeListInput> {
    constructor() {
        super("grpcTodo2_service.ChangeListInput", [
            { no: 1, name: "OldListName", kind: "scalar", localName: "OldListName", jsonName: "OldListName", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "NewListName", kind: "scalar", localName: "NewListName", jsonName: "NewListName", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ListId", kind: "scalar", localName: "ListId", jsonName: "ListId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "TodoId", kind: "scalar", localName: "TodoId", jsonName: "TodoId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ChangeListInput>): ChangeListInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.OldListName = "";
        message.NewListName = "";
        message.ListId = 0;
        message.TodoId = 0;
        if (value !== undefined)
            reflectionMergePartial<ChangeListInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChangeListInput): ChangeListInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string OldListName = 1 [json_name = "OldListName"];*/ 1:
                    message.OldListName = reader.string();
                    break;
                case /* string NewListName = 2 [json_name = "NewListName"];*/ 2:
                    message.NewListName = reader.string();
                    break;
                case /* int64 ListId = 3 [json_name = "ListId"];*/ 3:
                    message.ListId = reader.int64().toNumber();
                    break;
                case /* int64 TodoId = 4 [json_name = "TodoId"];*/ 4:
                    message.TodoId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChangeListInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string OldListName = 1 [json_name = "OldListName"]; */
        if (message.OldListName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.OldListName);
        /* string NewListName = 2 [json_name = "NewListName"]; */
        if (message.NewListName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.NewListName);
        /* int64 ListId = 3 [json_name = "ListId"]; */
        if (message.ListId !== 0)
            writer.tag(3, WireType.Varint).int64(message.ListId);
        /* int64 TodoId = 4 [json_name = "TodoId"]; */
        if (message.TodoId !== 0)
            writer.tag(4, WireType.Varint).int64(message.TodoId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ChangeListInput
 */
export const ChangeListInput = new ChangeListInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TodoList$Type extends MessageType<TodoList> {
    constructor() {
        super("grpcTodo2_service.TodoList", [
            { no: 1, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Id", kind: "scalar", localName: "Id", jsonName: "Id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "IsOwner", kind: "scalar", localName: "IsOwner", jsonName: "IsOwner", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "Owner", kind: "message", localName: "Owner", jsonName: "Owner", T: () => User },
            { no: 5, name: "IsMod", kind: "scalar", localName: "IsMod", jsonName: "IsMod", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "NewCount", kind: "scalar", localName: "NewCount", jsonName: "NewCount", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "InProgressCount", kind: "scalar", localName: "InProgressCount", jsonName: "InProgressCount", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "Group", kind: "message", localName: "Group", jsonName: "Group", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 9, name: "Sort", kind: "scalar", localName: "Sort", jsonName: "Sort", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TodoList>): TodoList {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Name = "";
        message.Id = 0;
        message.IsOwner = false;
        message.IsMod = false;
        message.NewCount = 0;
        message.InProgressCount = 0;
        message.Group = [];
        message.Sort = 0;
        if (value !== undefined)
            reflectionMergePartial<TodoList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TodoList): TodoList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Name = 1 [json_name = "Name"];*/ 1:
                    message.Name = reader.string();
                    break;
                case /* int64 Id = 2 [json_name = "Id"];*/ 2:
                    message.Id = reader.int64().toNumber();
                    break;
                case /* bool IsOwner = 3 [json_name = "IsOwner"];*/ 3:
                    message.IsOwner = reader.bool();
                    break;
                case /* grpcTodo2_service.User Owner = 4 [json_name = "Owner"];*/ 4:
                    message.Owner = User.internalBinaryRead(reader, reader.uint32(), options, message.Owner);
                    break;
                case /* bool IsMod = 5 [json_name = "IsMod"];*/ 5:
                    message.IsMod = reader.bool();
                    break;
                case /* int64 NewCount = 6 [json_name = "NewCount"];*/ 6:
                    message.NewCount = reader.int64().toNumber();
                    break;
                case /* int64 InProgressCount = 7 [json_name = "InProgressCount"];*/ 7:
                    message.InProgressCount = reader.int64().toNumber();
                    break;
                case /* repeated grpcTodo2_service.User Group = 8 [json_name = "Group"];*/ 8:
                    message.Group.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 Sort = 9 [json_name = "Sort"];*/ 9:
                    message.Sort = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TodoList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Name = 1 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Name);
        /* int64 Id = 2 [json_name = "Id"]; */
        if (message.Id !== 0)
            writer.tag(2, WireType.Varint).int64(message.Id);
        /* bool IsOwner = 3 [json_name = "IsOwner"]; */
        if (message.IsOwner !== false)
            writer.tag(3, WireType.Varint).bool(message.IsOwner);
        /* grpcTodo2_service.User Owner = 4 [json_name = "Owner"]; */
        if (message.Owner)
            User.internalBinaryWrite(message.Owner, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool IsMod = 5 [json_name = "IsMod"]; */
        if (message.IsMod !== false)
            writer.tag(5, WireType.Varint).bool(message.IsMod);
        /* int64 NewCount = 6 [json_name = "NewCount"]; */
        if (message.NewCount !== 0)
            writer.tag(6, WireType.Varint).int64(message.NewCount);
        /* int64 InProgressCount = 7 [json_name = "InProgressCount"]; */
        if (message.InProgressCount !== 0)
            writer.tag(7, WireType.Varint).int64(message.InProgressCount);
        /* repeated grpcTodo2_service.User Group = 8 [json_name = "Group"]; */
        for (let i = 0; i < message.Group.length; i++)
            User.internalBinaryWrite(message.Group[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int64 Sort = 9 [json_name = "Sort"]; */
        if (message.Sort !== 0)
            writer.tag(9, WireType.Varint).int64(message.Sort);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.TodoList
 */
export const TodoList = new TodoList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SortModel$Type extends MessageType<SortModel> {
    constructor() {
        super("grpcTodo2_service.SortModel", [
            { no: 1, name: "Sort", kind: "scalar", localName: "Sort", jsonName: "Sort", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ColId", kind: "scalar", localName: "ColId", jsonName: "ColId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SortModel>): SortModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Sort = "";
        message.ColId = "";
        if (value !== undefined)
            reflectionMergePartial<SortModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SortModel): SortModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Sort = 1 [json_name = "Sort"];*/ 1:
                    message.Sort = reader.string();
                    break;
                case /* string ColId = 2 [json_name = "ColId"];*/ 2:
                    message.ColId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SortModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Sort = 1 [json_name = "Sort"]; */
        if (message.Sort !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Sort);
        /* string ColId = 2 [json_name = "ColId"]; */
        if (message.ColId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ColId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.SortModel
 */
export const SortModel = new SortModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFilterConditionClValue$Type extends MessageType<ListFilterConditionClValue> {
    constructor() {
        super("grpcTodo2_service.ListFilterConditionClValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterConditionCl }
        ]);
    }
    create(value?: PartialMessage<ListFilterConditionClValue>): ListFilterConditionClValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListFilterConditionClValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFilterConditionClValue): ListFilterConditionClValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.FilterConditionCl Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(FilterConditionCl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFilterConditionClValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.FilterConditionCl Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            FilterConditionCl.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListFilterConditionClValue
 */
export const ListFilterConditionClValue = new ListFilterConditionClValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFilterModelValue$Type extends MessageType<ListFilterModelValue> {
    constructor() {
        super("grpcTodo2_service.ListFilterModelValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterModel }
        ]);
    }
    create(value?: PartialMessage<ListFilterModelValue>): ListFilterModelValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListFilterModelValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFilterModelValue): ListFilterModelValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.FilterModel Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(FilterModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFilterModelValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.FilterModel Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            FilterModel.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListFilterModelValue
 */
export const ListFilterModelValue = new ListFilterModelValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHistoryOutput$Type extends MessageType<GetHistoryOutput> {
    constructor() {
        super("grpcTodo2_service.GetHistoryOutput", [
            { no: 1, name: "History", kind: "message", localName: "History", jsonName: "History", repeat: 1 /*RepeatType.PACKED*/, T: () => GetHistoryRow }
        ]);
    }
    create(value?: PartialMessage<GetHistoryOutput>): GetHistoryOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.History = [];
        if (value !== undefined)
            reflectionMergePartial<GetHistoryOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHistoryOutput): GetHistoryOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.GetHistoryRow History = 1 [json_name = "History"];*/ 1:
                    message.History.push(GetHistoryRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHistoryOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.GetHistoryRow History = 1 [json_name = "History"]; */
        for (let i = 0; i < message.History.length; i++)
            GetHistoryRow.internalBinaryWrite(message.History[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.GetHistoryOutput
 */
export const GetHistoryOutput = new GetHistoryOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInt32Value$Type extends MessageType<ListInt32Value> {
    constructor() {
        super("grpcTodo2_service.ListInt32Value", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListInt32Value>): ListInt32Value {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListInt32Value>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListInt32Value): ListInt32Value {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 Value = 1 [json_name = "Value"];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.Value.push(reader.int64().toNumber());
                    else
                        message.Value.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListInt32Value, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 Value = 1 [json_name = "Value"]; */
        if (message.Value.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.Value.length; i++)
                writer.int64(message.Value[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListInt32Value
 */
export const ListInt32Value = new ListInt32Value$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteTodoInput$Type extends MessageType<DeleteTodoInput> {
    constructor() {
        super("grpcTodo2_service.DeleteTodoInput", [
            { no: 1, name: "Id", kind: "scalar", localName: "Id", jsonName: "Id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteTodoInput>): DeleteTodoInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Id = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteTodoInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteTodoInput): DeleteTodoInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Id = 1 [json_name = "Id"];*/ 1:
                    message.Id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteTodoInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Id = 1 [json_name = "Id"]; */
        if (message.Id !== 0)
            writer.tag(1, WireType.Varint).int64(message.Id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.DeleteTodoInput
 */
export const DeleteTodoInput = new DeleteTodoInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NullString$Type extends MessageType<NullString> {
    constructor() {
        super("grpcTodo2_service.NullString", [
            { no: 1, name: "String", kind: "scalar", localName: "String", jsonName: "String", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Valid", kind: "scalar", localName: "Valid", jsonName: "Valid", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<NullString>): NullString {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.String = "";
        message.Valid = false;
        if (value !== undefined)
            reflectionMergePartial<NullString>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NullString): NullString {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string String = 1 [json_name = "String"];*/ 1:
                    message.String = reader.string();
                    break;
                case /* bool Valid = 2 [json_name = "Valid"];*/ 2:
                    message.Valid = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NullString, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string String = 1 [json_name = "String"]; */
        if (message.String !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.String);
        /* bool Valid = 2 [json_name = "Valid"]; */
        if (message.Valid !== false)
            writer.tag(2, WireType.Varint).bool(message.Valid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.NullString
 */
export const NullString = new NullString$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterModel$Type extends MessageType<FilterModel> {
    constructor() {
        super("grpcTodo2_service.FilterModel", [
            { no: 1, name: "ColId", kind: "scalar", localName: "ColId", jsonName: "ColId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Filter", kind: "message", localName: "Filter", jsonName: "Filter", T: () => FilterCondition }
        ]);
    }
    create(value?: PartialMessage<FilterModel>): FilterModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ColId = "";
        if (value !== undefined)
            reflectionMergePartial<FilterModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterModel): FilterModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ColId = 1 [json_name = "ColId"];*/ 1:
                    message.ColId = reader.string();
                    break;
                case /* grpcTodo2_service.FilterCondition Filter = 2 [json_name = "Filter"];*/ 2:
                    message.Filter = FilterCondition.internalBinaryRead(reader, reader.uint32(), options, message.Filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ColId = 1 [json_name = "ColId"]; */
        if (message.ColId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ColId);
        /* grpcTodo2_service.FilterCondition Filter = 2 [json_name = "Filter"]; */
        if (message.Filter)
            FilterCondition.internalBinaryWrite(message.Filter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.FilterModel
 */
export const FilterModel = new FilterModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHistoryInput$Type extends MessageType<GetHistoryInput> {
    constructor() {
        super("grpcTodo2_service.GetHistoryInput", [
            { no: 1, name: "TodoId", kind: "scalar", localName: "TodoId", jsonName: "TodoId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetHistoryInput>): GetHistoryInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.TodoId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetHistoryInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHistoryInput): GetHistoryInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 TodoId = 1 [json_name = "TodoId"];*/ 1:
                    message.TodoId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHistoryInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 TodoId = 1 [json_name = "TodoId"]; */
        if (message.TodoId !== 0)
            writer.tag(1, WireType.Varint).int64(message.TodoId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.GetHistoryInput
 */
export const GetHistoryInput = new GetHistoryInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Option$Type extends MessageType<Option> {
    constructor() {
        super("grpcTodo2_service.Option", [
            { no: 1, name: "Label", kind: "scalar", localName: "Label", jsonName: "Label", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Option>): Option {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Label = "";
        message.Value = "";
        if (value !== undefined)
            reflectionMergePartial<Option>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Option): Option {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Label = 1 [json_name = "Label"];*/ 1:
                    message.Label = reader.string();
                    break;
                case /* string Value = 2 [json_name = "Value"];*/ 2:
                    message.Value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Option, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Label = 1 [json_name = "Label"]; */
        if (message.Label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Label);
        /* string Value = 2 [json_name = "Value"]; */
        if (message.Value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.Option
 */
export const Option = new Option$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Status$Type extends MessageType<Status> {
    constructor() {
        super("grpcTodo2_service.Status", [
            { no: 1, name: "Other", kind: "message", localName: "Other", jsonName: "Other", repeat: 1 /*RepeatType.PACKED*/, T: () => Option },
            { no: 2, name: "Owner", kind: "message", localName: "Owner", jsonName: "Owner", repeat: 1 /*RepeatType.PACKED*/, T: () => Option },
            { no: 3, name: "Assigned", kind: "message", localName: "Assigned", jsonName: "Assigned", repeat: 1 /*RepeatType.PACKED*/, T: () => Option }
        ]);
    }
    create(value?: PartialMessage<Status>): Status {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Other = [];
        message.Owner = [];
        message.Assigned = [];
        if (value !== undefined)
            reflectionMergePartial<Status>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Status): Status {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.Option Other = 1 [json_name = "Other"];*/ 1:
                    message.Other.push(Option.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcTodo2_service.Option Owner = 2 [json_name = "Owner"];*/ 2:
                    message.Owner.push(Option.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcTodo2_service.Option Assigned = 3 [json_name = "Assigned"];*/ 3:
                    message.Assigned.push(Option.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Status, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.Option Other = 1 [json_name = "Other"]; */
        for (let i = 0; i < message.Other.length; i++)
            Option.internalBinaryWrite(message.Other[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcTodo2_service.Option Owner = 2 [json_name = "Owner"]; */
        for (let i = 0; i < message.Owner.length; i++)
            Option.internalBinaryWrite(message.Owner[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcTodo2_service.Option Assigned = 3 [json_name = "Assigned"]; */
        for (let i = 0; i < message.Assigned.length; i++)
            Option.internalBinaryWrite(message.Assigned[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.Status
 */
export const Status = new Status$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserValue$Type extends MessageType<ListUserValue> {
    constructor() {
        super("grpcTodo2_service.ListUserValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<ListUserValue>): ListUserValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserValue): ListUserValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.User Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.User Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            User.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListUserValue
 */
export const ListUserValue = new ListUserValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTodoInput$Type extends MessageType<GetTodoInput> {
    constructor() {
        super("grpcTodo2_service.GetTodoInput", [
            { no: 1, name: "TodoId", kind: "scalar", localName: "TodoId", jsonName: "TodoId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetTodoInput>): GetTodoInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.TodoId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTodoInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTodoInput): GetTodoInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 TodoId = 1 [json_name = "TodoId"];*/ 1:
                    message.TodoId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTodoInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 TodoId = 1 [json_name = "TodoId"]; */
        if (message.TodoId !== 0)
            writer.tag(1, WireType.Varint).int64(message.TodoId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.GetTodoInput
 */
export const GetTodoInput = new GetTodoInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Todo$Type extends MessageType<Todo> {
    constructor() {
        super("grpcTodo2_service.Todo", [
            { no: 1, name: "Id", kind: "scalar", localName: "Id", jsonName: "Id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Topic", kind: "scalar", localName: "Topic", jsonName: "Topic", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Content", kind: "scalar", localName: "Content", jsonName: "Content", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Created", kind: "scalar", localName: "Created", jsonName: "Created", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Owner", kind: "scalar", localName: "Owner", jsonName: "Owner", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "Status", kind: "message", localName: "Status", jsonName: "Status", T: () => Option },
            { no: 7, name: "Priority", kind: "scalar", localName: "Priority", jsonName: "Priority", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "ListName", kind: "scalar", localName: "ListName", jsonName: "ListName", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "ListId", kind: "scalar", localName: "ListId", jsonName: "ListId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "Begin", kind: "scalar", localName: "Begin", jsonName: "Begin", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "End", kind: "scalar", localName: "End", jsonName: "End", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "Assigned", kind: "message", localName: "Assigned", jsonName: "Assigned", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 13, name: "Tags", kind: "message", localName: "Tags", jsonName: "Tags", repeat: 1 /*RepeatType.PACKED*/, T: () => TodoTag },
            { no: 14, name: "Files", kind: "message", localName: "Files", jsonName: "Files", repeat: 1 /*RepeatType.PACKED*/, T: () => File },
            { no: 15, name: "IsOwner", kind: "scalar", localName: "IsOwner", jsonName: "IsOwner", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "IsAssigned", kind: "scalar", localName: "IsAssigned", jsonName: "IsAssigned", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Todo>): Todo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Id = 0;
        message.Topic = "";
        message.Content = "";
        message.Created = "";
        message.Owner = "";
        message.Priority = "";
        message.ListName = "";
        message.ListId = 0;
        message.Begin = "";
        message.End = "";
        message.Assigned = [];
        message.Tags = [];
        message.Files = [];
        message.IsOwner = false;
        message.IsAssigned = false;
        if (value !== undefined)
            reflectionMergePartial<Todo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Todo): Todo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Id = 1 [json_name = "Id"];*/ 1:
                    message.Id = reader.int64().toNumber();
                    break;
                case /* string Topic = 2 [json_name = "Topic"];*/ 2:
                    message.Topic = reader.string();
                    break;
                case /* string Content = 3 [json_name = "Content"];*/ 3:
                    message.Content = reader.string();
                    break;
                case /* string Created = 4 [json_name = "Created"];*/ 4:
                    message.Created = reader.string();
                    break;
                case /* string Owner = 5 [json_name = "Owner"];*/ 5:
                    message.Owner = reader.string();
                    break;
                case /* grpcTodo2_service.Option Status = 6 [json_name = "Status"];*/ 6:
                    message.Status = Option.internalBinaryRead(reader, reader.uint32(), options, message.Status);
                    break;
                case /* string Priority = 7 [json_name = "Priority"];*/ 7:
                    message.Priority = reader.string();
                    break;
                case /* string ListName = 8 [json_name = "ListName"];*/ 8:
                    message.ListName = reader.string();
                    break;
                case /* int64 ListId = 9 [json_name = "ListId"];*/ 9:
                    message.ListId = reader.int64().toNumber();
                    break;
                case /* string Begin = 10 [json_name = "Begin"];*/ 10:
                    message.Begin = reader.string();
                    break;
                case /* string End = 11 [json_name = "End"];*/ 11:
                    message.End = reader.string();
                    break;
                case /* repeated grpcTodo2_service.User Assigned = 12 [json_name = "Assigned"];*/ 12:
                    message.Assigned.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcTodo2_service.TodoTag Tags = 13 [json_name = "Tags"];*/ 13:
                    message.Tags.push(TodoTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcTodo2_service.File Files = 14 [json_name = "Files"];*/ 14:
                    message.Files.push(File.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool IsOwner = 15 [json_name = "IsOwner"];*/ 15:
                    message.IsOwner = reader.bool();
                    break;
                case /* bool IsAssigned = 16 [json_name = "IsAssigned"];*/ 16:
                    message.IsAssigned = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Todo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Id = 1 [json_name = "Id"]; */
        if (message.Id !== 0)
            writer.tag(1, WireType.Varint).int64(message.Id);
        /* string Topic = 2 [json_name = "Topic"]; */
        if (message.Topic !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Topic);
        /* string Content = 3 [json_name = "Content"]; */
        if (message.Content !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Content);
        /* string Created = 4 [json_name = "Created"]; */
        if (message.Created !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Created);
        /* string Owner = 5 [json_name = "Owner"]; */
        if (message.Owner !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.Owner);
        /* grpcTodo2_service.Option Status = 6 [json_name = "Status"]; */
        if (message.Status)
            Option.internalBinaryWrite(message.Status, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string Priority = 7 [json_name = "Priority"]; */
        if (message.Priority !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.Priority);
        /* string ListName = 8 [json_name = "ListName"]; */
        if (message.ListName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.ListName);
        /* int64 ListId = 9 [json_name = "ListId"]; */
        if (message.ListId !== 0)
            writer.tag(9, WireType.Varint).int64(message.ListId);
        /* string Begin = 10 [json_name = "Begin"]; */
        if (message.Begin !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.Begin);
        /* string End = 11 [json_name = "End"]; */
        if (message.End !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.End);
        /* repeated grpcTodo2_service.User Assigned = 12 [json_name = "Assigned"]; */
        for (let i = 0; i < message.Assigned.length; i++)
            User.internalBinaryWrite(message.Assigned[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcTodo2_service.TodoTag Tags = 13 [json_name = "Tags"]; */
        for (let i = 0; i < message.Tags.length; i++)
            TodoTag.internalBinaryWrite(message.Tags[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcTodo2_service.File Files = 14 [json_name = "Files"]; */
        for (let i = 0; i < message.Files.length; i++)
            File.internalBinaryWrite(message.Files[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* bool IsOwner = 15 [json_name = "IsOwner"]; */
        if (message.IsOwner !== false)
            writer.tag(15, WireType.Varint).bool(message.IsOwner);
        /* bool IsAssigned = 16 [json_name = "IsAssigned"]; */
        if (message.IsAssigned !== false)
            writer.tag(16, WireType.Varint).bool(message.IsAssigned);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.Todo
 */
export const Todo = new Todo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSortModelValue$Type extends MessageType<ListSortModelValue> {
    constructor() {
        super("grpcTodo2_service.ListSortModelValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => SortModel }
        ]);
    }
    create(value?: PartialMessage<ListSortModelValue>): ListSortModelValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListSortModelValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSortModelValue): ListSortModelValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.SortModel Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(SortModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSortModelValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.SortModel Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            SortModel.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListSortModelValue
 */
export const ListSortModelValue = new ListSortModelValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTodoListValue$Type extends MessageType<ListTodoListValue> {
    constructor() {
        super("grpcTodo2_service.ListTodoListValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => TodoList }
        ]);
    }
    create(value?: PartialMessage<ListTodoListValue>): ListTodoListValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListTodoListValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTodoListValue): ListTodoListValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.TodoList Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(TodoList.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTodoListValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.TodoList Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            TodoList.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListTodoListValue
 */
export const ListTodoListValue = new ListTodoListValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddNewTagInput$Type extends MessageType<AddNewTagInput> {
    constructor() {
        super("grpcTodo2_service.AddNewTagInput", [
            { no: 1, name: "Tag", kind: "scalar", localName: "Tag", jsonName: "Tag", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddNewTagInput>): AddNewTagInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Tag = "";
        if (value !== undefined)
            reflectionMergePartial<AddNewTagInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddNewTagInput): AddNewTagInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Tag = 1 [json_name = "Tag"];*/ 1:
                    message.Tag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddNewTagInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Tag = 1 [json_name = "Tag"]; */
        if (message.Tag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Tag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.AddNewTagInput
 */
export const AddNewTagInput = new AddNewTagInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TodoConfig$Type extends MessageType<TodoConfig> {
    constructor() {
        super("grpcTodo2_service.TodoConfig", [
            { no: 1, name: "Tags", kind: "message", localName: "Tags", jsonName: "Tags", repeat: 1 /*RepeatType.PACKED*/, T: () => TodoTag },
            { no: 2, name: "Priority", kind: "scalar", localName: "Priority", jsonName: "Priority", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Status", kind: "message", localName: "Status", jsonName: "Status", T: () => Status },
            { no: 4, name: "IsDev", kind: "scalar", localName: "IsDev", jsonName: "IsDev", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TodoConfig>): TodoConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Tags = [];
        message.Priority = [];
        message.IsDev = false;
        if (value !== undefined)
            reflectionMergePartial<TodoConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TodoConfig): TodoConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.TodoTag Tags = 1 [json_name = "Tags"];*/ 1:
                    message.Tags.push(TodoTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string Priority = 2 [json_name = "Priority"];*/ 2:
                    message.Priority.push(reader.string());
                    break;
                case /* grpcTodo2_service.Status Status = 3 [json_name = "Status"];*/ 3:
                    message.Status = Status.internalBinaryRead(reader, reader.uint32(), options, message.Status);
                    break;
                case /* bool IsDev = 4 [json_name = "IsDev"];*/ 4:
                    message.IsDev = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TodoConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.TodoTag Tags = 1 [json_name = "Tags"]; */
        for (let i = 0; i < message.Tags.length; i++)
            TodoTag.internalBinaryWrite(message.Tags[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string Priority = 2 [json_name = "Priority"]; */
        for (let i = 0; i < message.Priority.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.Priority[i]);
        /* grpcTodo2_service.Status Status = 3 [json_name = "Status"]; */
        if (message.Status)
            Status.internalBinaryWrite(message.Status, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool IsDev = 4 [json_name = "IsDev"]; */
        if (message.IsDev !== false)
            writer.tag(4, WireType.Varint).bool(message.IsDev);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.TodoConfig
 */
export const TodoConfig = new TodoConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHistoryRow$Type extends MessageType<GetHistoryRow> {
    constructor() {
        super("grpcTodo2_service.GetHistoryRow", [
            { no: 1, name: "Created", kind: "scalar", localName: "Created", jsonName: "Created", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Login", kind: "message", localName: "Login", jsonName: "Login", T: () => NullString },
            { no: 3, name: "Content", kind: "scalar", localName: "Content", jsonName: "Content", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetHistoryRow>): GetHistoryRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Created = "";
        message.Content = "";
        if (value !== undefined)
            reflectionMergePartial<GetHistoryRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHistoryRow): GetHistoryRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Created = 1 [json_name = "Created"];*/ 1:
                    message.Created = reader.string();
                    break;
                case /* grpcTodo2_service.NullString Login = 2 [json_name = "Login"];*/ 2:
                    message.Login = NullString.internalBinaryRead(reader, reader.uint32(), options, message.Login);
                    break;
                case /* string Content = 3 [json_name = "Content"];*/ 3:
                    message.Content = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHistoryRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Created = 1 [json_name = "Created"]; */
        if (message.Created !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Created);
        /* grpcTodo2_service.NullString Login = 2 [json_name = "Login"]; */
        if (message.Login)
            NullString.internalBinaryWrite(message.Login, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string Content = 3 [json_name = "Content"]; */
        if (message.Content !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Content);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.GetHistoryRow
 */
export const GetHistoryRow = new GetHistoryRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTodosOutput$Type extends MessageType<GetTodosOutput> {
    constructor() {
        super("grpcTodo2_service.GetTodosOutput", [
            { no: 1, name: "Todos", kind: "message", localName: "Todos", jsonName: "Todos", repeat: 1 /*RepeatType.PACKED*/, T: () => Todo },
            { no: 2, name: "Count", kind: "scalar", localName: "Count", jsonName: "Count", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "TodoLists", kind: "message", localName: "TodoLists", jsonName: "TodoLists", repeat: 1 /*RepeatType.PACKED*/, T: () => TodoList }
        ]);
    }
    create(value?: PartialMessage<GetTodosOutput>): GetTodosOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Todos = [];
        message.Count = 0;
        message.TodoLists = [];
        if (value !== undefined)
            reflectionMergePartial<GetTodosOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTodosOutput): GetTodosOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.Todo Todos = 1 [json_name = "Todos"];*/ 1:
                    message.Todos.push(Todo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 Count = 2 [json_name = "Count"];*/ 2:
                    message.Count = reader.int64().toNumber();
                    break;
                case /* repeated grpcTodo2_service.TodoList TodoLists = 3 [json_name = "TodoLists"];*/ 3:
                    message.TodoLists.push(TodoList.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTodosOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.Todo Todos = 1 [json_name = "Todos"]; */
        for (let i = 0; i < message.Todos.length; i++)
            Todo.internalBinaryWrite(message.Todos[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 Count = 2 [json_name = "Count"]; */
        if (message.Count !== 0)
            writer.tag(2, WireType.Varint).int64(message.Count);
        /* repeated grpcTodo2_service.TodoList TodoLists = 3 [json_name = "TodoLists"]; */
        for (let i = 0; i < message.TodoLists.length; i++)
            TodoList.internalBinaryWrite(message.TodoLists[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.GetTodosOutput
 */
export const GetTodosOutput = new GetTodosOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStringValue$Type extends MessageType<ListStringValue> {
    constructor() {
        super("grpcTodo2_service.ListStringValue", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListStringValue>): ListStringValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListStringValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListStringValue): ListStringValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListStringValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.Value[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListStringValue
 */
export const ListStringValue = new ListStringValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOptionValue$Type extends MessageType<ListOptionValue> {
    constructor() {
        super("grpcTodo2_service.ListOptionValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Option }
        ]);
    }
    create(value?: PartialMessage<ListOptionValue>): ListOptionValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListOptionValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListOptionValue): ListOptionValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcTodo2_service.Option Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Option.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListOptionValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcTodo2_service.Option Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Option.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ListOptionValue
 */
export const ListOptionValue = new ListOptionValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddTodoInput$Type extends MessageType<AddTodoInput> {
    constructor() {
        super("grpcTodo2_service.AddTodoInput", [
            { no: 1, name: "Topic", kind: "scalar", localName: "Topic", jsonName: "Topic", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Content", kind: "scalar", localName: "Content", jsonName: "Content", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Status", kind: "scalar", localName: "Status", jsonName: "Status", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Priority", kind: "scalar", localName: "Priority", jsonName: "Priority", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Assign", kind: "message", localName: "Assign", jsonName: "Assign", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 6, name: "Tags", kind: "message", localName: "Tags", jsonName: "Tags", repeat: 1 /*RepeatType.PACKED*/, T: () => TodoTag },
            { no: 7, name: "ListId", kind: "scalar", localName: "ListId", jsonName: "ListId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AddTodoInput>): AddTodoInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Topic = "";
        message.Content = "";
        message.Status = "";
        message.Priority = "";
        message.Assign = [];
        message.Tags = [];
        message.ListId = 0;
        if (value !== undefined)
            reflectionMergePartial<AddTodoInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddTodoInput): AddTodoInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Topic = 1 [json_name = "Topic"];*/ 1:
                    message.Topic = reader.string();
                    break;
                case /* string Content = 2 [json_name = "Content"];*/ 2:
                    message.Content = reader.string();
                    break;
                case /* string Status = 3 [json_name = "Status"];*/ 3:
                    message.Status = reader.string();
                    break;
                case /* string Priority = 4 [json_name = "Priority"];*/ 4:
                    message.Priority = reader.string();
                    break;
                case /* repeated grpcTodo2_service.User Assign = 5 [json_name = "Assign"];*/ 5:
                    message.Assign.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcTodo2_service.TodoTag Tags = 6 [json_name = "Tags"];*/ 6:
                    message.Tags.push(TodoTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 ListId = 7 [json_name = "ListId"];*/ 7:
                    message.ListId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddTodoInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Topic = 1 [json_name = "Topic"]; */
        if (message.Topic !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Topic);
        /* string Content = 2 [json_name = "Content"]; */
        if (message.Content !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Content);
        /* string Status = 3 [json_name = "Status"]; */
        if (message.Status !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Status);
        /* string Priority = 4 [json_name = "Priority"]; */
        if (message.Priority !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Priority);
        /* repeated grpcTodo2_service.User Assign = 5 [json_name = "Assign"]; */
        for (let i = 0; i < message.Assign.length; i++)
            User.internalBinaryWrite(message.Assign[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcTodo2_service.TodoTag Tags = 6 [json_name = "Tags"]; */
        for (let i = 0; i < message.Tags.length; i++)
            TodoTag.internalBinaryWrite(message.Tags[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int64 ListId = 7 [json_name = "ListId"]; */
        if (message.ListId !== 0)
            writer.tag(7, WireType.Varint).int64(message.ListId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.AddTodoInput
 */
export const AddTodoInput = new AddTodoInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class File$Type extends MessageType<File> {
    constructor() {
        super("grpcTodo2_service.File", [
            { no: 1, name: "Id", kind: "scalar", localName: "Id", jsonName: "Id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "File", kind: "scalar", localName: "File", jsonName: "File", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Path", kind: "scalar", localName: "Path", jsonName: "Path", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Hash", kind: "scalar", localName: "Hash", jsonName: "Hash", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "Gallery", kind: "scalar", localName: "Gallery", jsonName: "Gallery", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<File>): File {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Id = 0;
        message.Name = "";
        message.File = "";
        message.Path = "";
        message.Hash = "";
        message.Gallery = false;
        if (value !== undefined)
            reflectionMergePartial<File>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: File): File {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Id = 1 [json_name = "Id"];*/ 1:
                    message.Id = reader.int64().toNumber();
                    break;
                case /* string Name = 2 [json_name = "Name"];*/ 2:
                    message.Name = reader.string();
                    break;
                case /* string File = 3 [json_name = "File"];*/ 3:
                    message.File = reader.string();
                    break;
                case /* string Path = 4 [json_name = "Path"];*/ 4:
                    message.Path = reader.string();
                    break;
                case /* string Hash = 5 [json_name = "Hash"];*/ 5:
                    message.Hash = reader.string();
                    break;
                case /* bool Gallery = 6 [json_name = "Gallery"];*/ 6:
                    message.Gallery = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: File, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Id = 1 [json_name = "Id"]; */
        if (message.Id !== 0)
            writer.tag(1, WireType.Varint).int64(message.Id);
        /* string Name = 2 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Name);
        /* string File = 3 [json_name = "File"]; */
        if (message.File !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.File);
        /* string Path = 4 [json_name = "Path"]; */
        if (message.Path !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Path);
        /* string Hash = 5 [json_name = "Hash"]; */
        if (message.Hash !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.Hash);
        /* bool Gallery = 6 [json_name = "Gallery"]; */
        if (message.Gallery !== false)
            writer.tag(6, WireType.Varint).bool(message.Gallery);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.File
 */
export const File = new File$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExternalFilter$Type extends MessageType<ExternalFilter> {
    constructor() {
        super("grpcTodo2_service.ExternalFilter", [
            { no: 1, name: "Topic", kind: "scalar", localName: "Topic", jsonName: "Topic", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Todo", kind: "scalar", localName: "Todo", jsonName: "Todo", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "Status", kind: "message", localName: "Status", jsonName: "Status", repeat: 1 /*RepeatType.PACKED*/, T: () => Option },
            { no: 4, name: "Assigned", kind: "scalar", localName: "Assigned", jsonName: "Assigned", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Priority", kind: "scalar", localName: "Priority", jsonName: "Priority", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExternalFilter>): ExternalFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Topic = "";
        message.Todo = false;
        message.Status = [];
        message.Assigned = [];
        message.Priority = [];
        if (value !== undefined)
            reflectionMergePartial<ExternalFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExternalFilter): ExternalFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Topic = 1 [json_name = "Topic"];*/ 1:
                    message.Topic = reader.string();
                    break;
                case /* bool Todo = 2 [json_name = "Todo"];*/ 2:
                    message.Todo = reader.bool();
                    break;
                case /* repeated grpcTodo2_service.Option Status = 3 [json_name = "Status"];*/ 3:
                    message.Status.push(Option.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string Assigned = 4 [json_name = "Assigned"];*/ 4:
                    message.Assigned.push(reader.string());
                    break;
                case /* repeated string Priority = 5 [json_name = "Priority"];*/ 5:
                    message.Priority.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExternalFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Topic = 1 [json_name = "Topic"]; */
        if (message.Topic !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Topic);
        /* bool Todo = 2 [json_name = "Todo"]; */
        if (message.Todo !== false)
            writer.tag(2, WireType.Varint).bool(message.Todo);
        /* repeated grpcTodo2_service.Option Status = 3 [json_name = "Status"]; */
        for (let i = 0; i < message.Status.length; i++)
            Option.internalBinaryWrite(message.Status[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string Assigned = 4 [json_name = "Assigned"]; */
        for (let i = 0; i < message.Assigned.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.Assigned[i]);
        /* repeated string Priority = 5 [json_name = "Priority"]; */
        for (let i = 0; i < message.Priority.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.Priority[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.ExternalFilter
 */
export const ExternalFilter = new ExternalFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditTodoInput$Type extends MessageType<EditTodoInput> {
    constructor() {
        super("grpcTodo2_service.EditTodoInput", [
            { no: 1, name: "Todo", kind: "message", localName: "Todo", jsonName: "Todo", T: () => Todo }
        ]);
    }
    create(value?: PartialMessage<EditTodoInput>): EditTodoInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EditTodoInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditTodoInput): EditTodoInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcTodo2_service.Todo Todo = 1 [json_name = "Todo"];*/ 1:
                    message.Todo = Todo.internalBinaryRead(reader, reader.uint32(), options, message.Todo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditTodoInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcTodo2_service.Todo Todo = 1 [json_name = "Todo"]; */
        if (message.Todo)
            Todo.internalBinaryWrite(message.Todo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTodo2_service.EditTodoInput
 */
export const EditTodoInput = new EditTodoInput$Type();
/**
 * @generated ServiceType for protobuf service grpcTodo2_service.Todo2Service
 */
export const Todo2Service = new ServiceType("grpcTodo2_service.Todo2Service", [
    { name: "AddNewTag", options: {}, I: AddNewTagInput, O: AddNewTagOutput },
    { name: "GetConfigData", options: {}, I: BoolValue, O: TodoConfig },
    { name: "AddList", options: {}, I: AddListInput, O: BoolValue },
    { name: "Test", options: {}, I: Empty, O: BoolValue },
    { name: "DeleteTodo", options: {}, I: DeleteTodoInput, O: BoolValue },
    { name: "AddTodo", options: {}, I: AddTodoInput, O: Int64Value },
    { name: "GetTodo", options: {}, I: GetTodoInput, O: GetTodoOutput },
    { name: "ChangeList", options: {}, I: ChangeListInput, O: ChangeListOutput },
    { name: "EditList", options: {}, I: EditListInput, O: BoolValue },
    { name: "GetTodos", options: {}, I: GetTodosInput, O: GetTodosOutput },
    { name: "GetHistory", options: {}, I: GetHistoryInput, O: GetHistoryOutput },
    { name: "EditTodo", options: {}, I: EditTodoInput, O: EditTodoOutput },
    { name: "DeleteList", options: {}, I: Int64Value, O: BoolValue }
]);
