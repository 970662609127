import { Types, GrpcManager } from "grpc-connect";
import { create } from "zustand";
export type Comment = Types.ErpBackend.CommentsService.GetObjectCommentsRow;

const client = GrpcManager.ErpBackend.getCommentsService();

interface CommentsStore {
    order: "asc" | "desc";
    setOrder: (order: "asc" | "desc") => void;
    comments: Comment[];
    addComment: (elementId: number, model: string, comment: string) => void;
    loadComments: (elementId: number, model: string) => void;
}

export const useCommentsStore = create<CommentsStore>()((set, get) => ({
    order: "desc",
    comments: [],
    setOrder: (order: "asc" | "desc") => {
        set({ order });
    },
    loadComments: async (elementId: number, model: string) => {
        const result = await client.getComments({
            Id: elementId,
            Model: model,
            Order: get().order,
        });

        set({ comments: result.response.Value });
    },
    addComment: async (elementId: number, model: string, comment: string) => {
        const loader = get().loadComments;
        await client.createComment({
            Comment: comment,
            Id: elementId,
            Model: model,
            ParentId: 0,
        });
        loader(elementId, model);
    },
}));
