// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcSearch_by_car_service.proto" (package "grpcSearch_by_car_service", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { SearchByCarService } from "./grpcSearch_by_car_service";
import type { OutputTimePeriods } from "./grpcSearch_by_car_service";
import type { GetModelsOutput } from "./grpcSearch_by_car_service";
import type { GetModelsInput } from "./grpcSearch_by_car_service";
import type { GetCarsForProductOutput } from "./grpcSearch_by_car_service";
import type { GetCarsForProductInput } from "./grpcSearch_by_car_service";
import type { GetCarsOutput } from "./grpcSearch_by_car_service";
import type { GetCarsInput } from "./grpcSearch_by_car_service";
import type { SearchInput } from "./grpcSearch_by_car_service";
import type { ListStringValue } from "./grpcSearch_by_car_service";
import type { Empty } from "./google/protobuf/empty";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { SearchOutput } from "./grpcSearch_by_car_service";
import type { StringValue } from "./google/protobuf/wrappers";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcSearch_by_car_service.SearchByCarService
 */
export interface ISearchByCarServiceClient {
    /**
     * @generated from protobuf rpc: SearchByPhrase(google.protobuf.StringValue) returns (grpcSearch_by_car_service.SearchOutput);
     */
    searchByPhrase(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, SearchOutput>;
    /**
     * @generated from protobuf rpc: GetBrands(google.protobuf.Empty) returns (grpcSearch_by_car_service.ListStringValue);
     */
    getBrands(input: Empty, options?: RpcOptions): UnaryCall<Empty, ListStringValue>;
    /**
     * @generated from protobuf rpc: GetBrandsForProduct(google.protobuf.StringValue) returns (grpcSearch_by_car_service.ListStringValue);
     */
    getBrandsForProduct(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, ListStringValue>;
    /**
     * @generated from protobuf rpc: Search(grpcSearch_by_car_service.SearchInput) returns (grpcSearch_by_car_service.SearchOutput);
     */
    search(input: SearchInput, options?: RpcOptions): UnaryCall<SearchInput, SearchOutput>;
    /**
     * @generated from protobuf rpc: GetCars(grpcSearch_by_car_service.GetCarsInput) returns (grpcSearch_by_car_service.GetCarsOutput);
     */
    getCars(input: GetCarsInput, options?: RpcOptions): UnaryCall<GetCarsInput, GetCarsOutput>;
    /**
     * @generated from protobuf rpc: GetCarsForProduct(grpcSearch_by_car_service.GetCarsForProductInput) returns (grpcSearch_by_car_service.GetCarsForProductOutput);
     */
    getCarsForProduct(input: GetCarsForProductInput, options?: RpcOptions): UnaryCall<GetCarsForProductInput, GetCarsForProductOutput>;
    /**
     * @generated from protobuf rpc: GetModels(grpcSearch_by_car_service.GetModelsInput) returns (grpcSearch_by_car_service.GetModelsOutput);
     */
    getModels(input: GetModelsInput, options?: RpcOptions): UnaryCall<GetModelsInput, GetModelsOutput>;
    /**
     * @generated from protobuf rpc: GetTimePeriods(google.protobuf.StringValue) returns (grpcSearch_by_car_service.OutputTimePeriods);
     */
    getTimePeriods(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, OutputTimePeriods>;
}
/**
 * @generated from protobuf service grpcSearch_by_car_service.SearchByCarService
 */
export class SearchByCarServiceClient implements ISearchByCarServiceClient, ServiceInfo {
    typeName = SearchByCarService.typeName;
    methods = SearchByCarService.methods;
    options = SearchByCarService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: SearchByPhrase(google.protobuf.StringValue) returns (grpcSearch_by_car_service.SearchOutput);
     */
    searchByPhrase(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, SearchOutput> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<StringValue, SearchOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBrands(google.protobuf.Empty) returns (grpcSearch_by_car_service.ListStringValue);
     */
    getBrands(input: Empty, options?: RpcOptions): UnaryCall<Empty, ListStringValue> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, ListStringValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBrandsForProduct(google.protobuf.StringValue) returns (grpcSearch_by_car_service.ListStringValue);
     */
    getBrandsForProduct(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, ListStringValue> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<StringValue, ListStringValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Search(grpcSearch_by_car_service.SearchInput) returns (grpcSearch_by_car_service.SearchOutput);
     */
    search(input: SearchInput, options?: RpcOptions): UnaryCall<SearchInput, SearchOutput> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchInput, SearchOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCars(grpcSearch_by_car_service.GetCarsInput) returns (grpcSearch_by_car_service.GetCarsOutput);
     */
    getCars(input: GetCarsInput, options?: RpcOptions): UnaryCall<GetCarsInput, GetCarsOutput> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCarsInput, GetCarsOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCarsForProduct(grpcSearch_by_car_service.GetCarsForProductInput) returns (grpcSearch_by_car_service.GetCarsForProductOutput);
     */
    getCarsForProduct(input: GetCarsForProductInput, options?: RpcOptions): UnaryCall<GetCarsForProductInput, GetCarsForProductOutput> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCarsForProductInput, GetCarsForProductOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetModels(grpcSearch_by_car_service.GetModelsInput) returns (grpcSearch_by_car_service.GetModelsOutput);
     */
    getModels(input: GetModelsInput, options?: RpcOptions): UnaryCall<GetModelsInput, GetModelsOutput> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetModelsInput, GetModelsOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimePeriods(google.protobuf.StringValue) returns (grpcSearch_by_car_service.OutputTimePeriods);
     */
    getTimePeriods(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, OutputTimePeriods> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<StringValue, OutputTimePeriods>("unary", this._transport, method, opt, input);
    }
}
