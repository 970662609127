// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcProduct_tecdoc.proto" (package "grpcProduct_tecdoc", syntax proto3)
// tslint:disable
import { BoolValue } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcProduct_tecdoc.SearchManyAppsOutput
 */
export interface SearchManyAppsOutput {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.ApplicationRow Data = 1 [json_name = "Data"];
     */
    Data: ApplicationRow[];
    /**
     * @generated from protobuf field: grpcProduct_tecdoc.SearchData AppName = 2 [json_name = "AppName"];
     */
    AppName?: SearchData;
    /**
     * @generated from protobuf field: int64 AppId = 3 [json_name = "AppId"];
     */
    AppId: number;
    /**
     * @generated from protobuf field: int64 AppCount = 4 [json_name = "AppCount"];
     */
    AppCount: number;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.GetAppListInput
 */
export interface GetAppListInput {
    /**
     * @generated from protobuf field: int64 ProductId = 1 [json_name = "ProductId"];
     */
    ProductId: number;
    /**
     * @generated from protobuf field: int64 Block = 2 [json_name = "Block"];
     */
    Block: number;
    /**
     * @generated from protobuf field: grpcProduct_tecdoc.AgGridQueryData Filter = 3 [json_name = "Filter"];
     */
    Filter?: AgGridQueryData;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.IN_QueryData_Filters_Value
 */
export interface IN_QueryData_Filters_Value {
    /**
     * @generated from protobuf field: string Condition = 1 [json_name = "Condition"];
     */
    Condition: string;
    /**
     * @generated from protobuf field: string LabelCondition = 2 [json_name = "LabelCondition"];
     */
    LabelCondition: string;
    /**
     * @generated from protobuf field: string LabelValue = 3 [json_name = "LabelValue"];
     */
    LabelValue: string;
    /**
     * @generated from protobuf field: string Operator = 4 [json_name = "Operator"];
     */
    Operator: string;
    /**
     * @generated from protobuf field: string Value = 5 [json_name = "Value"];
     */
    Value: string;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.QueryData
 */
export interface QueryData {
    /**
     * @generated from protobuf field: repeated string Fields = 1 [json_name = "Fields"];
     */
    Fields: string[];
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.IN_QueryData_Order Order = 2 [json_name = "Order"];
     */
    Order: IN_QueryData_Order[];
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.IN_QueryData_Filters Filters = 3 [json_name = "Filters"];
     */
    Filters: IN_QueryData_Filters[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.AgGridQueryData
 */
export interface AgGridQueryData {
    /**
     * @generated from protobuf field: int64 StartRow = 1 [json_name = "StartRow"];
     */
    StartRow: number;
    /**
     * @generated from protobuf field: int64 EndRow = 2 [json_name = "EndRow"];
     */
    EndRow: number;
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.SortModel SortModel = 3 [json_name = "SortModel"];
     */
    SortModel: SortModel[];
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.FilterModel GoFilterModel = 4 [json_name = "GoFilterModel"];
     */
    GoFilterModel: FilterModel[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListApplicationRowValue
 */
export interface ListApplicationRowValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.ApplicationRow Value = 1 [json_name = "Value"];
     */
    Value: ApplicationRow[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.GetApplicationHistoryOutput
 */
export interface GetApplicationHistoryOutput {
    /**
     * @generated from protobuf field: string Created = 1 [json_name = "Created"];
     */
    Created: string;
    /**
     * @generated from protobuf field: string Login = 2 [json_name = "Login"];
     */
    Login: string;
    /**
     * @generated from protobuf field: string Action = 3 [json_name = "Action"];
     */
    Action: string;
    /**
     * @generated from protobuf field: string Description = 4 [json_name = "Description"];
     */
    Description: string;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ConnectApplicationInput
 */
export interface ConnectApplicationInput {
    /**
     * @generated from protobuf field: int64 ProductId = 1 [json_name = "ProductId"];
     */
    ProductId: number;
    /**
     * @generated from protobuf field: int64 Block = 2 [json_name = "Block"];
     */
    Block: number;
    /**
     * @generated from protobuf field: repeated int64 AppId = 3 [json_name = "AppId"];
     */
    AppId: number[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.FilterConditionCl
 */
export interface FilterConditionCl {
    /**
     * @generated from protobuf field: string Filter = 1 [json_name = "Filter"];
     */
    Filter: string;
    /**
     * @generated from protobuf field: string FilterType = 2 [json_name = "FilterType"];
     */
    FilterType: string;
    /**
     * @generated from protobuf field: string Type = 3 [json_name = "Type"];
     */
    Type: string;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.FilterModel
 */
export interface FilterModel {
    /**
     * @generated from protobuf field: string ColId = 1 [json_name = "ColId"];
     */
    ColId: string;
    /**
     * @generated from protobuf field: grpcProduct_tecdoc.FilterCondition Filter = 2 [json_name = "Filter"];
     */
    Filter?: FilterCondition;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListStringValue
 */
export interface ListStringValue {
    /**
     * @generated from protobuf field: repeated string Value = 1 [json_name = "Value"];
     */
    Value: string[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListSearchDataValue
 */
export interface ListSearchDataValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.SearchData Value = 1 [json_name = "Value"];
     */
    Value: SearchData[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.SearchManyAppsInput
 */
export interface SearchManyAppsInput {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.SearchData Data = 1 [json_name = "Data"];
     */
    Data: SearchData[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.GetUnmergedAppsInput
 */
export interface GetUnmergedAppsInput {
    /**
     * @generated from protobuf field: grpcProduct_tecdoc.AgGridQueryData Filter = 1 [json_name = "Filter"];
     */
    Filter?: AgGridQueryData;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ApplicationResult
 */
export interface ApplicationResult {
    /**
     * @generated from protobuf field: int64 Count = 1 [json_name = "Count"];
     */
    Count: number;
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.ApplicationRow Rows = 2 [json_name = "Rows"];
     */
    Rows: ApplicationRow[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.SearchData
 */
export interface SearchData {
    /**
     * @generated from protobuf field: int64 ProductId = 1 [json_name = "ProductId"];
     */
    ProductId: number;
    /**
     * @generated from protobuf field: string Mark = 2 [json_name = "Mark"];
     */
    Mark: string;
    /**
     * @generated from protobuf field: string Series = 3 [json_name = "Series"];
     */
    Series: string;
    /**
     * @generated from protobuf field: string Model = 4 [json_name = "Model"];
     */
    Model: string;
    /**
     * @generated from protobuf field: string DateFrom = 5 [json_name = "DateFrom"];
     */
    DateFrom: string;
    /**
     * @generated from protobuf field: string DateTo = 6 [json_name = "DateTo"];
     */
    DateTo: string;
    /**
     * @generated from protobuf field: string EngineCode = 7 [json_name = "EngineCode"];
     */
    EngineCode: string;
    /**
     * @generated from protobuf field: bool TecDoc = 8 [json_name = "TecDoc"];
     */
    TecDoc: boolean;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.GetEnginesInput
 */
export interface GetEnginesInput {
    /**
     * @generated from protobuf field: int64 ProductId = 1 [json_name = "ProductId"];
     */
    ProductId: number;
    /**
     * @generated from protobuf field: grpcProduct_tecdoc.AgGridQueryData Filter = 2 [json_name = "Filter"];
     */
    Filter?: AgGridQueryData;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListIN_QueryData_Filters_ValueValue
 */
export interface ListIN_QueryData_Filters_ValueValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.IN_QueryData_Filters_Value Value = 1 [json_name = "Value"];
     */
    Value: IN_QueryData_Filters_Value[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.IN_QueryData_Order
 */
export interface IN_QueryData_Order {
    /**
     * @generated from protobuf field: string Field = 1 [json_name = "Field"];
     */
    Field: string;
    /**
     * @generated from protobuf field: string Caption = 2 [json_name = "Caption"];
     */
    Caption: string;
    /**
     * @generated from protobuf field: string Dir = 3 [json_name = "Dir"];
     */
    Dir: string;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.GetApplicationHistoryInput
 */
export interface GetApplicationHistoryInput {
    /**
     * @generated from protobuf field: int64 ProductId = 1 [json_name = "ProductId"];
     */
    ProductId: number;
    /**
     * @generated from protobuf field: grpcProduct_tecdoc.QueryData QueryData = 2 [json_name = "QueryData"];
     */
    QueryData?: QueryData;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListGetApplicationHistoryOutputValue
 */
export interface ListGetApplicationHistoryOutputValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.GetApplicationHistoryOutput Value = 1 [json_name = "Value"];
     */
    Value: GetApplicationHistoryOutput[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListSortModelValue
 */
export interface ListSortModelValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.SortModel Value = 1 [json_name = "Value"];
     */
    Value: SortModel[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.SortModel
 */
export interface SortModel {
    /**
     * @generated from protobuf field: string Sort = 1 [json_name = "Sort"];
     */
    Sort: string;
    /**
     * @generated from protobuf field: string ColId = 2 [json_name = "ColId"];
     */
    ColId: string;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListFilterConditionClValue
 */
export interface ListFilterConditionClValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.FilterConditionCl Value = 1 [json_name = "Value"];
     */
    Value: FilterConditionCl[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.SearchAppInput
 */
export interface SearchAppInput {
    /**
     * @generated from protobuf field: grpcProduct_tecdoc.SearchData Data = 1 [json_name = "Data"];
     */
    Data?: SearchData;
    /**
     * @generated from protobuf field: grpcProduct_tecdoc.AgGridQueryData Filter = 2 [json_name = "Filter"];
     */
    Filter?: AgGridQueryData;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListGetEnginesRowValue
 */
export interface ListGetEnginesRowValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.GetEnginesRow Value = 1 [json_name = "Value"];
     */
    Value: GetEnginesRow[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListGetLinkedCrossListRowValue
 */
export interface ListGetLinkedCrossListRowValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.GetLinkedCrossListRow Value = 1 [json_name = "Value"];
     */
    Value: GetLinkedCrossListRow[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListInsertManyAppsInputValue
 */
export interface ListInsertManyAppsInputValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.InsertManyAppsInput Value = 1 [json_name = "Value"];
     */
    Value: InsertManyAppsInput[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ApplicationRow
 */
export interface ApplicationRow {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * @generated from protobuf field: int64 TecdocID = 2 [json_name = "TecdocID"];
     */
    TecdocID: number;
    /**
     * @generated from protobuf field: string Mark = 3 [json_name = "Mark"];
     */
    Mark: string;
    /**
     * @generated from protobuf field: string Series = 4 [json_name = "Series"];
     */
    Series: string;
    /**
     * @generated from protobuf field: string Model = 5 [json_name = "Model"];
     */
    Model: string;
    /**
     * @generated from protobuf field: string DateFrom = 6 [json_name = "DateFrom"];
     */
    DateFrom: string;
    /**
     * @generated from protobuf field: string DateTo = 7 [json_name = "DateTo"];
     */
    DateTo: string;
    /**
     * @generated from protobuf field: string EngineCode = 8 [json_name = "EngineCode"];
     */
    EngineCode: string;
    /**
     * @generated from protobuf field: string Description = 9 [json_name = "Description"];
     */
    Description: string;
    /**
     * @generated from protobuf field: int64 Kw = 10 [json_name = "Kw"];
     */
    Kw: number;
    /**
     * @generated from protobuf field: int64 KwUpperLimit = 11 [json_name = "KwUpperLimit"];
     */
    KwUpperLimit: number;
    /**
     * @generated from protobuf field: int64 Hp = 12 [json_name = "Hp"];
     */
    Hp: number;
    /**
     * @generated from protobuf field: int64 HpUpperLimit = 13 [json_name = "HpUpperLimit"];
     */
    HpUpperLimit: number;
    /**
     * @generated from protobuf field: int64 Capacity = 14 [json_name = "Capacity"];
     */
    Capacity: number;
    /**
     * @generated from protobuf field: int64 Cylinders = 15 [json_name = "Cylinders"];
     */
    Cylinders: number;
    /**
     * @generated from protobuf field: int64 Valves = 16 [json_name = "Valves"];
     */
    Valves: number;
    /**
     * @generated from protobuf field: string EngineType = 17 [json_name = "EngineType"];
     */
    EngineType: string;
    /**
     * @generated from protobuf field: string FuelType = 18 [json_name = "FuelType"];
     */
    FuelType: string;
    /**
     * @generated from protobuf field: string Drive = 19 [json_name = "Drive"];
     */
    Drive: string;
    /**
     * @generated from protobuf field: string GearBox = 20 [json_name = "GearBox"];
     */
    GearBox: string;
    /**
     * @generated from protobuf field: string BodyType = 21 [json_name = "BodyType"];
     */
    BodyType: string;
    /**
     * @generated from protobuf field: bool IsBlocked = 22 [json_name = "IsBlocked"];
     */
    IsBlocked: boolean;
    /**
     * @generated from protobuf field: repeated string CrossList = 23 [json_name = "CrossList"];
     */
    CrossList: string[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListSearchManyAppsOutputValue
 */
export interface ListSearchManyAppsOutputValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.SearchManyAppsOutput Value = 1 [json_name = "Value"];
     */
    Value: SearchManyAppsOutput[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.GetEnginesOutput
 */
export interface GetEnginesOutput {
    /**
     * @generated from protobuf field: int64 Count = 1 [json_name = "Count"];
     */
    Count: number;
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.GetEnginesRow Rows = 2 [json_name = "Rows"];
     */
    Rows: GetEnginesRow[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListIN_QueryData_OrderValue
 */
export interface ListIN_QueryData_OrderValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.IN_QueryData_Order Value = 1 [json_name = "Value"];
     */
    Value: IN_QueryData_Order[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListIN_QueryData_FiltersValue
 */
export interface ListIN_QueryData_FiltersValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.IN_QueryData_Filters Value = 1 [json_name = "Value"];
     */
    Value: IN_QueryData_Filters[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.IN_QueryData_Filters
 */
export interface IN_QueryData_Filters {
    /**
     * @generated from protobuf field: string Field = 1 [json_name = "Field"];
     */
    Field: string;
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.IN_QueryData_Filters_Value Value = 2 [json_name = "Value"];
     */
    Value: IN_QueryData_Filters_Value[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.GetLinkedCrossListRow
 */
export interface GetLinkedCrossListRow {
    /**
     * @generated from protobuf field: string Name = 1 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: string Producer = 2 [json_name = "Producer"];
     */
    Producer: string;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.FilterCondition
 */
export interface FilterCondition {
    /**
     * @generated from protobuf field: string Operator = 1 [json_name = "Operator"];
     */
    Operator: string;
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.FilterConditionCl Conditions = 2 [json_name = "Conditions"];
     */
    Conditions: FilterConditionCl[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListFilterModelValue
 */
export interface ListFilterModelValue {
    /**
     * @generated from protobuf field: repeated grpcProduct_tecdoc.FilterModel Value = 1 [json_name = "Value"];
     */
    Value: FilterModel[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.GetEnginesRow
 */
export interface GetEnginesRow {
    /**
     * @generated from protobuf field: int64 ProductID = 1 [json_name = "ProductID"];
     */
    ProductID: number;
    /**
     * @generated from protobuf field: string EngCode = 2 [json_name = "EngCode"];
     */
    EngCode: string;
    /**
     * @generated from protobuf field: string ManName = 3 [json_name = "ManName"];
     */
    ManName: string;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.GetLinkedCrosslistInput
 */
export interface GetLinkedCrosslistInput {
    /**
     * @generated from protobuf field: int64 ProductId = 1 [json_name = "ProductId"];
     */
    ProductId: number;
    /**
     * @generated from protobuf field: int64 Block = 2 [json_name = "Block"];
     */
    Block: number;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.ListInt32Value
 */
export interface ListInt32Value {
    /**
     * @generated from protobuf field: repeated int64 Value = 1 [json_name = "Value"];
     */
    Value: number[];
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.InsertManyAppsInput
 */
export interface InsertManyAppsInput {
    /**
     * @generated from protobuf field: int64 AppId = 1 [json_name = "AppId"];
     */
    AppId: number;
    /**
     * @generated from protobuf field: grpcProduct_tecdoc.SearchData NewApp = 2 [json_name = "NewApp"];
     */
    NewApp?: SearchData;
    /**
     * @generated from protobuf field: int64 ProductId = 3 [json_name = "ProductId"];
     */
    ProductId: number;
}
/**
 * @generated from protobuf message grpcProduct_tecdoc.MergeApplicationsInput
 */
export interface MergeApplicationsInput {
    /**
     * @generated from protobuf field: int64 Old = 1 [json_name = "Old"];
     */
    Old: number;
    /**
     * @generated from protobuf field: repeated int64 New = 2 [json_name = "New"];
     */
    New: number[];
}
// @generated message type with reflection information, may provide speed optimized methods
class SearchManyAppsOutput$Type extends MessageType<SearchManyAppsOutput> {
    constructor() {
        super("grpcProduct_tecdoc.SearchManyAppsOutput", [
            { no: 1, name: "Data", kind: "message", localName: "Data", jsonName: "Data", repeat: 1 /*RepeatType.PACKED*/, T: () => ApplicationRow },
            { no: 2, name: "AppName", kind: "message", localName: "AppName", jsonName: "AppName", T: () => SearchData },
            { no: 3, name: "AppId", kind: "scalar", localName: "AppId", jsonName: "AppId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "AppCount", kind: "scalar", localName: "AppCount", jsonName: "AppCount", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SearchManyAppsOutput>): SearchManyAppsOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Data = [];
        message.AppId = 0;
        message.AppCount = 0;
        if (value !== undefined)
            reflectionMergePartial<SearchManyAppsOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchManyAppsOutput): SearchManyAppsOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.ApplicationRow Data = 1 [json_name = "Data"];*/ 1:
                    message.Data.push(ApplicationRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* grpcProduct_tecdoc.SearchData AppName = 2 [json_name = "AppName"];*/ 2:
                    message.AppName = SearchData.internalBinaryRead(reader, reader.uint32(), options, message.AppName);
                    break;
                case /* int64 AppId = 3 [json_name = "AppId"];*/ 3:
                    message.AppId = reader.int64().toNumber();
                    break;
                case /* int64 AppCount = 4 [json_name = "AppCount"];*/ 4:
                    message.AppCount = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchManyAppsOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.ApplicationRow Data = 1 [json_name = "Data"]; */
        for (let i = 0; i < message.Data.length; i++)
            ApplicationRow.internalBinaryWrite(message.Data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* grpcProduct_tecdoc.SearchData AppName = 2 [json_name = "AppName"]; */
        if (message.AppName)
            SearchData.internalBinaryWrite(message.AppName, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 AppId = 3 [json_name = "AppId"]; */
        if (message.AppId !== 0)
            writer.tag(3, WireType.Varint).int64(message.AppId);
        /* int64 AppCount = 4 [json_name = "AppCount"]; */
        if (message.AppCount !== 0)
            writer.tag(4, WireType.Varint).int64(message.AppCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.SearchManyAppsOutput
 */
export const SearchManyAppsOutput = new SearchManyAppsOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAppListInput$Type extends MessageType<GetAppListInput> {
    constructor() {
        super("grpcProduct_tecdoc.GetAppListInput", [
            { no: 1, name: "ProductId", kind: "scalar", localName: "ProductId", jsonName: "ProductId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Block", kind: "scalar", localName: "Block", jsonName: "Block", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Filter", kind: "message", localName: "Filter", jsonName: "Filter", T: () => AgGridQueryData }
        ]);
    }
    create(value?: PartialMessage<GetAppListInput>): GetAppListInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ProductId = 0;
        message.Block = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAppListInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAppListInput): GetAppListInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ProductId = 1 [json_name = "ProductId"];*/ 1:
                    message.ProductId = reader.int64().toNumber();
                    break;
                case /* int64 Block = 2 [json_name = "Block"];*/ 2:
                    message.Block = reader.int64().toNumber();
                    break;
                case /* grpcProduct_tecdoc.AgGridQueryData Filter = 3 [json_name = "Filter"];*/ 3:
                    message.Filter = AgGridQueryData.internalBinaryRead(reader, reader.uint32(), options, message.Filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAppListInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ProductId = 1 [json_name = "ProductId"]; */
        if (message.ProductId !== 0)
            writer.tag(1, WireType.Varint).int64(message.ProductId);
        /* int64 Block = 2 [json_name = "Block"]; */
        if (message.Block !== 0)
            writer.tag(2, WireType.Varint).int64(message.Block);
        /* grpcProduct_tecdoc.AgGridQueryData Filter = 3 [json_name = "Filter"]; */
        if (message.Filter)
            AgGridQueryData.internalBinaryWrite(message.Filter, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.GetAppListInput
 */
export const GetAppListInput = new GetAppListInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IN_QueryData_Filters_Value$Type extends MessageType<IN_QueryData_Filters_Value> {
    constructor() {
        super("grpcProduct_tecdoc.IN_QueryData_Filters_Value", [
            { no: 1, name: "Condition", kind: "scalar", localName: "Condition", jsonName: "Condition", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "LabelCondition", kind: "scalar", localName: "LabelCondition", jsonName: "LabelCondition", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "LabelValue", kind: "scalar", localName: "LabelValue", jsonName: "LabelValue", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Operator", kind: "scalar", localName: "Operator", jsonName: "Operator", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IN_QueryData_Filters_Value>): IN_QueryData_Filters_Value {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Condition = "";
        message.LabelCondition = "";
        message.LabelValue = "";
        message.Operator = "";
        message.Value = "";
        if (value !== undefined)
            reflectionMergePartial<IN_QueryData_Filters_Value>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IN_QueryData_Filters_Value): IN_QueryData_Filters_Value {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Condition = 1 [json_name = "Condition"];*/ 1:
                    message.Condition = reader.string();
                    break;
                case /* string LabelCondition = 2 [json_name = "LabelCondition"];*/ 2:
                    message.LabelCondition = reader.string();
                    break;
                case /* string LabelValue = 3 [json_name = "LabelValue"];*/ 3:
                    message.LabelValue = reader.string();
                    break;
                case /* string Operator = 4 [json_name = "Operator"];*/ 4:
                    message.Operator = reader.string();
                    break;
                case /* string Value = 5 [json_name = "Value"];*/ 5:
                    message.Value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IN_QueryData_Filters_Value, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Condition = 1 [json_name = "Condition"]; */
        if (message.Condition !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Condition);
        /* string LabelCondition = 2 [json_name = "LabelCondition"]; */
        if (message.LabelCondition !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.LabelCondition);
        /* string LabelValue = 3 [json_name = "LabelValue"]; */
        if (message.LabelValue !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.LabelValue);
        /* string Operator = 4 [json_name = "Operator"]; */
        if (message.Operator !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Operator);
        /* string Value = 5 [json_name = "Value"]; */
        if (message.Value !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.Value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.IN_QueryData_Filters_Value
 */
export const IN_QueryData_Filters_Value = new IN_QueryData_Filters_Value$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryData$Type extends MessageType<QueryData> {
    constructor() {
        super("grpcProduct_tecdoc.QueryData", [
            { no: 1, name: "Fields", kind: "scalar", localName: "Fields", jsonName: "Fields", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Order", kind: "message", localName: "Order", jsonName: "Order", repeat: 1 /*RepeatType.PACKED*/, T: () => IN_QueryData_Order },
            { no: 3, name: "Filters", kind: "message", localName: "Filters", jsonName: "Filters", repeat: 1 /*RepeatType.PACKED*/, T: () => IN_QueryData_Filters }
        ]);
    }
    create(value?: PartialMessage<QueryData>): QueryData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Fields = [];
        message.Order = [];
        message.Filters = [];
        if (value !== undefined)
            reflectionMergePartial<QueryData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryData): QueryData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string Fields = 1 [json_name = "Fields"];*/ 1:
                    message.Fields.push(reader.string());
                    break;
                case /* repeated grpcProduct_tecdoc.IN_QueryData_Order Order = 2 [json_name = "Order"];*/ 2:
                    message.Order.push(IN_QueryData_Order.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcProduct_tecdoc.IN_QueryData_Filters Filters = 3 [json_name = "Filters"];*/ 3:
                    message.Filters.push(IN_QueryData_Filters.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string Fields = 1 [json_name = "Fields"]; */
        for (let i = 0; i < message.Fields.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.Fields[i]);
        /* repeated grpcProduct_tecdoc.IN_QueryData_Order Order = 2 [json_name = "Order"]; */
        for (let i = 0; i < message.Order.length; i++)
            IN_QueryData_Order.internalBinaryWrite(message.Order[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcProduct_tecdoc.IN_QueryData_Filters Filters = 3 [json_name = "Filters"]; */
        for (let i = 0; i < message.Filters.length; i++)
            IN_QueryData_Filters.internalBinaryWrite(message.Filters[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.QueryData
 */
export const QueryData = new QueryData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgGridQueryData$Type extends MessageType<AgGridQueryData> {
    constructor() {
        super("grpcProduct_tecdoc.AgGridQueryData", [
            { no: 1, name: "StartRow", kind: "scalar", localName: "StartRow", jsonName: "StartRow", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "EndRow", kind: "scalar", localName: "EndRow", jsonName: "EndRow", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "SortModel", kind: "message", localName: "SortModel", jsonName: "SortModel", repeat: 1 /*RepeatType.PACKED*/, T: () => SortModel },
            { no: 4, name: "GoFilterModel", kind: "message", localName: "GoFilterModel", jsonName: "GoFilterModel", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterModel }
        ]);
    }
    create(value?: PartialMessage<AgGridQueryData>): AgGridQueryData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.StartRow = 0;
        message.EndRow = 0;
        message.SortModel = [];
        message.GoFilterModel = [];
        if (value !== undefined)
            reflectionMergePartial<AgGridQueryData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgGridQueryData): AgGridQueryData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 StartRow = 1 [json_name = "StartRow"];*/ 1:
                    message.StartRow = reader.int64().toNumber();
                    break;
                case /* int64 EndRow = 2 [json_name = "EndRow"];*/ 2:
                    message.EndRow = reader.int64().toNumber();
                    break;
                case /* repeated grpcProduct_tecdoc.SortModel SortModel = 3 [json_name = "SortModel"];*/ 3:
                    message.SortModel.push(SortModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcProduct_tecdoc.FilterModel GoFilterModel = 4 [json_name = "GoFilterModel"];*/ 4:
                    message.GoFilterModel.push(FilterModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgGridQueryData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 StartRow = 1 [json_name = "StartRow"]; */
        if (message.StartRow !== 0)
            writer.tag(1, WireType.Varint).int64(message.StartRow);
        /* int64 EndRow = 2 [json_name = "EndRow"]; */
        if (message.EndRow !== 0)
            writer.tag(2, WireType.Varint).int64(message.EndRow);
        /* repeated grpcProduct_tecdoc.SortModel SortModel = 3 [json_name = "SortModel"]; */
        for (let i = 0; i < message.SortModel.length; i++)
            SortModel.internalBinaryWrite(message.SortModel[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcProduct_tecdoc.FilterModel GoFilterModel = 4 [json_name = "GoFilterModel"]; */
        for (let i = 0; i < message.GoFilterModel.length; i++)
            FilterModel.internalBinaryWrite(message.GoFilterModel[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.AgGridQueryData
 */
export const AgGridQueryData = new AgGridQueryData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListApplicationRowValue$Type extends MessageType<ListApplicationRowValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListApplicationRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => ApplicationRow }
        ]);
    }
    create(value?: PartialMessage<ListApplicationRowValue>): ListApplicationRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListApplicationRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListApplicationRowValue): ListApplicationRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.ApplicationRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(ApplicationRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListApplicationRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.ApplicationRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            ApplicationRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListApplicationRowValue
 */
export const ListApplicationRowValue = new ListApplicationRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetApplicationHistoryOutput$Type extends MessageType<GetApplicationHistoryOutput> {
    constructor() {
        super("grpcProduct_tecdoc.GetApplicationHistoryOutput", [
            { no: 1, name: "Created", kind: "scalar", localName: "Created", jsonName: "Created", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Login", kind: "scalar", localName: "Login", jsonName: "Login", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Action", kind: "scalar", localName: "Action", jsonName: "Action", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Description", kind: "scalar", localName: "Description", jsonName: "Description", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetApplicationHistoryOutput>): GetApplicationHistoryOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Created = "";
        message.Login = "";
        message.Action = "";
        message.Description = "";
        if (value !== undefined)
            reflectionMergePartial<GetApplicationHistoryOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetApplicationHistoryOutput): GetApplicationHistoryOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Created = 1 [json_name = "Created"];*/ 1:
                    message.Created = reader.string();
                    break;
                case /* string Login = 2 [json_name = "Login"];*/ 2:
                    message.Login = reader.string();
                    break;
                case /* string Action = 3 [json_name = "Action"];*/ 3:
                    message.Action = reader.string();
                    break;
                case /* string Description = 4 [json_name = "Description"];*/ 4:
                    message.Description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetApplicationHistoryOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Created = 1 [json_name = "Created"]; */
        if (message.Created !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Created);
        /* string Login = 2 [json_name = "Login"]; */
        if (message.Login !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Login);
        /* string Action = 3 [json_name = "Action"]; */
        if (message.Action !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Action);
        /* string Description = 4 [json_name = "Description"]; */
        if (message.Description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.GetApplicationHistoryOutput
 */
export const GetApplicationHistoryOutput = new GetApplicationHistoryOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConnectApplicationInput$Type extends MessageType<ConnectApplicationInput> {
    constructor() {
        super("grpcProduct_tecdoc.ConnectApplicationInput", [
            { no: 1, name: "ProductId", kind: "scalar", localName: "ProductId", jsonName: "ProductId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Block", kind: "scalar", localName: "Block", jsonName: "Block", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "AppId", kind: "scalar", localName: "AppId", jsonName: "AppId", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ConnectApplicationInput>): ConnectApplicationInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ProductId = 0;
        message.Block = 0;
        message.AppId = [];
        if (value !== undefined)
            reflectionMergePartial<ConnectApplicationInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConnectApplicationInput): ConnectApplicationInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ProductId = 1 [json_name = "ProductId"];*/ 1:
                    message.ProductId = reader.int64().toNumber();
                    break;
                case /* int64 Block = 2 [json_name = "Block"];*/ 2:
                    message.Block = reader.int64().toNumber();
                    break;
                case /* repeated int64 AppId = 3 [json_name = "AppId"];*/ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.AppId.push(reader.int64().toNumber());
                    else
                        message.AppId.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConnectApplicationInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ProductId = 1 [json_name = "ProductId"]; */
        if (message.ProductId !== 0)
            writer.tag(1, WireType.Varint).int64(message.ProductId);
        /* int64 Block = 2 [json_name = "Block"]; */
        if (message.Block !== 0)
            writer.tag(2, WireType.Varint).int64(message.Block);
        /* repeated int64 AppId = 3 [json_name = "AppId"]; */
        if (message.AppId.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.AppId.length; i++)
                writer.int64(message.AppId[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ConnectApplicationInput
 */
export const ConnectApplicationInput = new ConnectApplicationInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterConditionCl$Type extends MessageType<FilterConditionCl> {
    constructor() {
        super("grpcProduct_tecdoc.FilterConditionCl", [
            { no: 1, name: "Filter", kind: "scalar", localName: "Filter", jsonName: "Filter", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "FilterType", kind: "scalar", localName: "FilterType", jsonName: "FilterType", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FilterConditionCl>): FilterConditionCl {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Filter = "";
        message.FilterType = "";
        message.Type = "";
        if (value !== undefined)
            reflectionMergePartial<FilterConditionCl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterConditionCl): FilterConditionCl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Filter = 1 [json_name = "Filter"];*/ 1:
                    message.Filter = reader.string();
                    break;
                case /* string FilterType = 2 [json_name = "FilterType"];*/ 2:
                    message.FilterType = reader.string();
                    break;
                case /* string Type = 3 [json_name = "Type"];*/ 3:
                    message.Type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterConditionCl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Filter = 1 [json_name = "Filter"]; */
        if (message.Filter !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Filter);
        /* string FilterType = 2 [json_name = "FilterType"]; */
        if (message.FilterType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.FilterType);
        /* string Type = 3 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.FilterConditionCl
 */
export const FilterConditionCl = new FilterConditionCl$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterModel$Type extends MessageType<FilterModel> {
    constructor() {
        super("grpcProduct_tecdoc.FilterModel", [
            { no: 1, name: "ColId", kind: "scalar", localName: "ColId", jsonName: "ColId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Filter", kind: "message", localName: "Filter", jsonName: "Filter", T: () => FilterCondition }
        ]);
    }
    create(value?: PartialMessage<FilterModel>): FilterModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ColId = "";
        if (value !== undefined)
            reflectionMergePartial<FilterModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterModel): FilterModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ColId = 1 [json_name = "ColId"];*/ 1:
                    message.ColId = reader.string();
                    break;
                case /* grpcProduct_tecdoc.FilterCondition Filter = 2 [json_name = "Filter"];*/ 2:
                    message.Filter = FilterCondition.internalBinaryRead(reader, reader.uint32(), options, message.Filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ColId = 1 [json_name = "ColId"]; */
        if (message.ColId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ColId);
        /* grpcProduct_tecdoc.FilterCondition Filter = 2 [json_name = "Filter"]; */
        if (message.Filter)
            FilterCondition.internalBinaryWrite(message.Filter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.FilterModel
 */
export const FilterModel = new FilterModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStringValue$Type extends MessageType<ListStringValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListStringValue", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListStringValue>): ListStringValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListStringValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListStringValue): ListStringValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListStringValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.Value[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListStringValue
 */
export const ListStringValue = new ListStringValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSearchDataValue$Type extends MessageType<ListSearchDataValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListSearchDataValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchData }
        ]);
    }
    create(value?: PartialMessage<ListSearchDataValue>): ListSearchDataValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListSearchDataValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSearchDataValue): ListSearchDataValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.SearchData Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(SearchData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSearchDataValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.SearchData Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            SearchData.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListSearchDataValue
 */
export const ListSearchDataValue = new ListSearchDataValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchManyAppsInput$Type extends MessageType<SearchManyAppsInput> {
    constructor() {
        super("grpcProduct_tecdoc.SearchManyAppsInput", [
            { no: 1, name: "Data", kind: "message", localName: "Data", jsonName: "Data", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchData }
        ]);
    }
    create(value?: PartialMessage<SearchManyAppsInput>): SearchManyAppsInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Data = [];
        if (value !== undefined)
            reflectionMergePartial<SearchManyAppsInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchManyAppsInput): SearchManyAppsInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.SearchData Data = 1 [json_name = "Data"];*/ 1:
                    message.Data.push(SearchData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchManyAppsInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.SearchData Data = 1 [json_name = "Data"]; */
        for (let i = 0; i < message.Data.length; i++)
            SearchData.internalBinaryWrite(message.Data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.SearchManyAppsInput
 */
export const SearchManyAppsInput = new SearchManyAppsInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUnmergedAppsInput$Type extends MessageType<GetUnmergedAppsInput> {
    constructor() {
        super("grpcProduct_tecdoc.GetUnmergedAppsInput", [
            { no: 1, name: "Filter", kind: "message", localName: "Filter", jsonName: "Filter", T: () => AgGridQueryData }
        ]);
    }
    create(value?: PartialMessage<GetUnmergedAppsInput>): GetUnmergedAppsInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUnmergedAppsInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUnmergedAppsInput): GetUnmergedAppsInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcProduct_tecdoc.AgGridQueryData Filter = 1 [json_name = "Filter"];*/ 1:
                    message.Filter = AgGridQueryData.internalBinaryRead(reader, reader.uint32(), options, message.Filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUnmergedAppsInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcProduct_tecdoc.AgGridQueryData Filter = 1 [json_name = "Filter"]; */
        if (message.Filter)
            AgGridQueryData.internalBinaryWrite(message.Filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.GetUnmergedAppsInput
 */
export const GetUnmergedAppsInput = new GetUnmergedAppsInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplicationResult$Type extends MessageType<ApplicationResult> {
    constructor() {
        super("grpcProduct_tecdoc.ApplicationResult", [
            { no: 1, name: "Count", kind: "scalar", localName: "Count", jsonName: "Count", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Rows", kind: "message", localName: "Rows", jsonName: "Rows", repeat: 1 /*RepeatType.PACKED*/, T: () => ApplicationRow }
        ]);
    }
    create(value?: PartialMessage<ApplicationResult>): ApplicationResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Count = 0;
        message.Rows = [];
        if (value !== undefined)
            reflectionMergePartial<ApplicationResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplicationResult): ApplicationResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Count = 1 [json_name = "Count"];*/ 1:
                    message.Count = reader.int64().toNumber();
                    break;
                case /* repeated grpcProduct_tecdoc.ApplicationRow Rows = 2 [json_name = "Rows"];*/ 2:
                    message.Rows.push(ApplicationRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplicationResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Count = 1 [json_name = "Count"]; */
        if (message.Count !== 0)
            writer.tag(1, WireType.Varint).int64(message.Count);
        /* repeated grpcProduct_tecdoc.ApplicationRow Rows = 2 [json_name = "Rows"]; */
        for (let i = 0; i < message.Rows.length; i++)
            ApplicationRow.internalBinaryWrite(message.Rows[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ApplicationResult
 */
export const ApplicationResult = new ApplicationResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchData$Type extends MessageType<SearchData> {
    constructor() {
        super("grpcProduct_tecdoc.SearchData", [
            { no: 1, name: "ProductId", kind: "scalar", localName: "ProductId", jsonName: "ProductId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Mark", kind: "scalar", localName: "Mark", jsonName: "Mark", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Series", kind: "scalar", localName: "Series", jsonName: "Series", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "DateFrom", kind: "scalar", localName: "DateFrom", jsonName: "DateFrom", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "DateTo", kind: "scalar", localName: "DateTo", jsonName: "DateTo", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "EngineCode", kind: "scalar", localName: "EngineCode", jsonName: "EngineCode", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "TecDoc", kind: "scalar", localName: "TecDoc", jsonName: "TecDoc", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SearchData>): SearchData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ProductId = 0;
        message.Mark = "";
        message.Series = "";
        message.Model = "";
        message.DateFrom = "";
        message.DateTo = "";
        message.EngineCode = "";
        message.TecDoc = false;
        if (value !== undefined)
            reflectionMergePartial<SearchData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchData): SearchData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ProductId = 1 [json_name = "ProductId"];*/ 1:
                    message.ProductId = reader.int64().toNumber();
                    break;
                case /* string Mark = 2 [json_name = "Mark"];*/ 2:
                    message.Mark = reader.string();
                    break;
                case /* string Series = 3 [json_name = "Series"];*/ 3:
                    message.Series = reader.string();
                    break;
                case /* string Model = 4 [json_name = "Model"];*/ 4:
                    message.Model = reader.string();
                    break;
                case /* string DateFrom = 5 [json_name = "DateFrom"];*/ 5:
                    message.DateFrom = reader.string();
                    break;
                case /* string DateTo = 6 [json_name = "DateTo"];*/ 6:
                    message.DateTo = reader.string();
                    break;
                case /* string EngineCode = 7 [json_name = "EngineCode"];*/ 7:
                    message.EngineCode = reader.string();
                    break;
                case /* bool TecDoc = 8 [json_name = "TecDoc"];*/ 8:
                    message.TecDoc = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ProductId = 1 [json_name = "ProductId"]; */
        if (message.ProductId !== 0)
            writer.tag(1, WireType.Varint).int64(message.ProductId);
        /* string Mark = 2 [json_name = "Mark"]; */
        if (message.Mark !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Mark);
        /* string Series = 3 [json_name = "Series"]; */
        if (message.Series !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Series);
        /* string Model = 4 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Model);
        /* string DateFrom = 5 [json_name = "DateFrom"]; */
        if (message.DateFrom !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.DateFrom);
        /* string DateTo = 6 [json_name = "DateTo"]; */
        if (message.DateTo !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.DateTo);
        /* string EngineCode = 7 [json_name = "EngineCode"]; */
        if (message.EngineCode !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.EngineCode);
        /* bool TecDoc = 8 [json_name = "TecDoc"]; */
        if (message.TecDoc !== false)
            writer.tag(8, WireType.Varint).bool(message.TecDoc);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.SearchData
 */
export const SearchData = new SearchData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEnginesInput$Type extends MessageType<GetEnginesInput> {
    constructor() {
        super("grpcProduct_tecdoc.GetEnginesInput", [
            { no: 1, name: "ProductId", kind: "scalar", localName: "ProductId", jsonName: "ProductId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Filter", kind: "message", localName: "Filter", jsonName: "Filter", T: () => AgGridQueryData }
        ]);
    }
    create(value?: PartialMessage<GetEnginesInput>): GetEnginesInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ProductId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetEnginesInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEnginesInput): GetEnginesInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ProductId = 1 [json_name = "ProductId"];*/ 1:
                    message.ProductId = reader.int64().toNumber();
                    break;
                case /* grpcProduct_tecdoc.AgGridQueryData Filter = 2 [json_name = "Filter"];*/ 2:
                    message.Filter = AgGridQueryData.internalBinaryRead(reader, reader.uint32(), options, message.Filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEnginesInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ProductId = 1 [json_name = "ProductId"]; */
        if (message.ProductId !== 0)
            writer.tag(1, WireType.Varint).int64(message.ProductId);
        /* grpcProduct_tecdoc.AgGridQueryData Filter = 2 [json_name = "Filter"]; */
        if (message.Filter)
            AgGridQueryData.internalBinaryWrite(message.Filter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.GetEnginesInput
 */
export const GetEnginesInput = new GetEnginesInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIN_QueryData_Filters_ValueValue$Type extends MessageType<ListIN_QueryData_Filters_ValueValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListIN_QueryData_Filters_ValueValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => IN_QueryData_Filters_Value }
        ]);
    }
    create(value?: PartialMessage<ListIN_QueryData_Filters_ValueValue>): ListIN_QueryData_Filters_ValueValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListIN_QueryData_Filters_ValueValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListIN_QueryData_Filters_ValueValue): ListIN_QueryData_Filters_ValueValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.IN_QueryData_Filters_Value Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(IN_QueryData_Filters_Value.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListIN_QueryData_Filters_ValueValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.IN_QueryData_Filters_Value Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            IN_QueryData_Filters_Value.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListIN_QueryData_Filters_ValueValue
 */
export const ListIN_QueryData_Filters_ValueValue = new ListIN_QueryData_Filters_ValueValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IN_QueryData_Order$Type extends MessageType<IN_QueryData_Order> {
    constructor() {
        super("grpcProduct_tecdoc.IN_QueryData_Order", [
            { no: 1, name: "Field", kind: "scalar", localName: "Field", jsonName: "Field", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Caption", kind: "scalar", localName: "Caption", jsonName: "Caption", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Dir", kind: "scalar", localName: "Dir", jsonName: "Dir", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IN_QueryData_Order>): IN_QueryData_Order {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Field = "";
        message.Caption = "";
        message.Dir = "";
        if (value !== undefined)
            reflectionMergePartial<IN_QueryData_Order>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IN_QueryData_Order): IN_QueryData_Order {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Field = 1 [json_name = "Field"];*/ 1:
                    message.Field = reader.string();
                    break;
                case /* string Caption = 2 [json_name = "Caption"];*/ 2:
                    message.Caption = reader.string();
                    break;
                case /* string Dir = 3 [json_name = "Dir"];*/ 3:
                    message.Dir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IN_QueryData_Order, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Field = 1 [json_name = "Field"]; */
        if (message.Field !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Field);
        /* string Caption = 2 [json_name = "Caption"]; */
        if (message.Caption !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Caption);
        /* string Dir = 3 [json_name = "Dir"]; */
        if (message.Dir !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Dir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.IN_QueryData_Order
 */
export const IN_QueryData_Order = new IN_QueryData_Order$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetApplicationHistoryInput$Type extends MessageType<GetApplicationHistoryInput> {
    constructor() {
        super("grpcProduct_tecdoc.GetApplicationHistoryInput", [
            { no: 1, name: "ProductId", kind: "scalar", localName: "ProductId", jsonName: "ProductId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "QueryData", kind: "message", localName: "QueryData", jsonName: "QueryData", T: () => QueryData }
        ]);
    }
    create(value?: PartialMessage<GetApplicationHistoryInput>): GetApplicationHistoryInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ProductId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetApplicationHistoryInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetApplicationHistoryInput): GetApplicationHistoryInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ProductId = 1 [json_name = "ProductId"];*/ 1:
                    message.ProductId = reader.int64().toNumber();
                    break;
                case /* grpcProduct_tecdoc.QueryData QueryData = 2 [json_name = "QueryData"];*/ 2:
                    message.QueryData = QueryData.internalBinaryRead(reader, reader.uint32(), options, message.QueryData);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetApplicationHistoryInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ProductId = 1 [json_name = "ProductId"]; */
        if (message.ProductId !== 0)
            writer.tag(1, WireType.Varint).int64(message.ProductId);
        /* grpcProduct_tecdoc.QueryData QueryData = 2 [json_name = "QueryData"]; */
        if (message.QueryData)
            QueryData.internalBinaryWrite(message.QueryData, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.GetApplicationHistoryInput
 */
export const GetApplicationHistoryInput = new GetApplicationHistoryInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetApplicationHistoryOutputValue$Type extends MessageType<ListGetApplicationHistoryOutputValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListGetApplicationHistoryOutputValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetApplicationHistoryOutput }
        ]);
    }
    create(value?: PartialMessage<ListGetApplicationHistoryOutputValue>): ListGetApplicationHistoryOutputValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetApplicationHistoryOutputValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetApplicationHistoryOutputValue): ListGetApplicationHistoryOutputValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.GetApplicationHistoryOutput Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetApplicationHistoryOutput.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetApplicationHistoryOutputValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.GetApplicationHistoryOutput Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetApplicationHistoryOutput.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListGetApplicationHistoryOutputValue
 */
export const ListGetApplicationHistoryOutputValue = new ListGetApplicationHistoryOutputValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSortModelValue$Type extends MessageType<ListSortModelValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListSortModelValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => SortModel }
        ]);
    }
    create(value?: PartialMessage<ListSortModelValue>): ListSortModelValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListSortModelValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSortModelValue): ListSortModelValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.SortModel Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(SortModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSortModelValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.SortModel Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            SortModel.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListSortModelValue
 */
export const ListSortModelValue = new ListSortModelValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SortModel$Type extends MessageType<SortModel> {
    constructor() {
        super("grpcProduct_tecdoc.SortModel", [
            { no: 1, name: "Sort", kind: "scalar", localName: "Sort", jsonName: "Sort", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ColId", kind: "scalar", localName: "ColId", jsonName: "ColId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SortModel>): SortModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Sort = "";
        message.ColId = "";
        if (value !== undefined)
            reflectionMergePartial<SortModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SortModel): SortModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Sort = 1 [json_name = "Sort"];*/ 1:
                    message.Sort = reader.string();
                    break;
                case /* string ColId = 2 [json_name = "ColId"];*/ 2:
                    message.ColId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SortModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Sort = 1 [json_name = "Sort"]; */
        if (message.Sort !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Sort);
        /* string ColId = 2 [json_name = "ColId"]; */
        if (message.ColId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ColId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.SortModel
 */
export const SortModel = new SortModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFilterConditionClValue$Type extends MessageType<ListFilterConditionClValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListFilterConditionClValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterConditionCl }
        ]);
    }
    create(value?: PartialMessage<ListFilterConditionClValue>): ListFilterConditionClValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListFilterConditionClValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFilterConditionClValue): ListFilterConditionClValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.FilterConditionCl Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(FilterConditionCl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFilterConditionClValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.FilterConditionCl Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            FilterConditionCl.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListFilterConditionClValue
 */
export const ListFilterConditionClValue = new ListFilterConditionClValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchAppInput$Type extends MessageType<SearchAppInput> {
    constructor() {
        super("grpcProduct_tecdoc.SearchAppInput", [
            { no: 1, name: "Data", kind: "message", localName: "Data", jsonName: "Data", T: () => SearchData },
            { no: 2, name: "Filter", kind: "message", localName: "Filter", jsonName: "Filter", T: () => AgGridQueryData }
        ]);
    }
    create(value?: PartialMessage<SearchAppInput>): SearchAppInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SearchAppInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchAppInput): SearchAppInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcProduct_tecdoc.SearchData Data = 1 [json_name = "Data"];*/ 1:
                    message.Data = SearchData.internalBinaryRead(reader, reader.uint32(), options, message.Data);
                    break;
                case /* grpcProduct_tecdoc.AgGridQueryData Filter = 2 [json_name = "Filter"];*/ 2:
                    message.Filter = AgGridQueryData.internalBinaryRead(reader, reader.uint32(), options, message.Filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchAppInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcProduct_tecdoc.SearchData Data = 1 [json_name = "Data"]; */
        if (message.Data)
            SearchData.internalBinaryWrite(message.Data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* grpcProduct_tecdoc.AgGridQueryData Filter = 2 [json_name = "Filter"]; */
        if (message.Filter)
            AgGridQueryData.internalBinaryWrite(message.Filter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.SearchAppInput
 */
export const SearchAppInput = new SearchAppInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetEnginesRowValue$Type extends MessageType<ListGetEnginesRowValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListGetEnginesRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetEnginesRow }
        ]);
    }
    create(value?: PartialMessage<ListGetEnginesRowValue>): ListGetEnginesRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetEnginesRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetEnginesRowValue): ListGetEnginesRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.GetEnginesRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetEnginesRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetEnginesRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.GetEnginesRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetEnginesRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListGetEnginesRowValue
 */
export const ListGetEnginesRowValue = new ListGetEnginesRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGetLinkedCrossListRowValue$Type extends MessageType<ListGetLinkedCrossListRowValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListGetLinkedCrossListRowValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => GetLinkedCrossListRow }
        ]);
    }
    create(value?: PartialMessage<ListGetLinkedCrossListRowValue>): ListGetLinkedCrossListRowValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListGetLinkedCrossListRowValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGetLinkedCrossListRowValue): ListGetLinkedCrossListRowValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.GetLinkedCrossListRow Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(GetLinkedCrossListRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGetLinkedCrossListRowValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.GetLinkedCrossListRow Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            GetLinkedCrossListRow.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListGetLinkedCrossListRowValue
 */
export const ListGetLinkedCrossListRowValue = new ListGetLinkedCrossListRowValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInsertManyAppsInputValue$Type extends MessageType<ListInsertManyAppsInputValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListInsertManyAppsInputValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => InsertManyAppsInput }
        ]);
    }
    create(value?: PartialMessage<ListInsertManyAppsInputValue>): ListInsertManyAppsInputValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListInsertManyAppsInputValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListInsertManyAppsInputValue): ListInsertManyAppsInputValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.InsertManyAppsInput Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(InsertManyAppsInput.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListInsertManyAppsInputValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.InsertManyAppsInput Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            InsertManyAppsInput.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListInsertManyAppsInputValue
 */
export const ListInsertManyAppsInputValue = new ListInsertManyAppsInputValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplicationRow$Type extends MessageType<ApplicationRow> {
    constructor() {
        super("grpcProduct_tecdoc.ApplicationRow", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "TecdocID", kind: "scalar", localName: "TecdocID", jsonName: "TecdocID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Mark", kind: "scalar", localName: "Mark", jsonName: "Mark", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Series", kind: "scalar", localName: "Series", jsonName: "Series", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Model", kind: "scalar", localName: "Model", jsonName: "Model", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "DateFrom", kind: "scalar", localName: "DateFrom", jsonName: "DateFrom", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "DateTo", kind: "scalar", localName: "DateTo", jsonName: "DateTo", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "EngineCode", kind: "scalar", localName: "EngineCode", jsonName: "EngineCode", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "Description", kind: "scalar", localName: "Description", jsonName: "Description", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "Kw", kind: "scalar", localName: "Kw", jsonName: "Kw", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "KwUpperLimit", kind: "scalar", localName: "KwUpperLimit", jsonName: "KwUpperLimit", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "Hp", kind: "scalar", localName: "Hp", jsonName: "Hp", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "HpUpperLimit", kind: "scalar", localName: "HpUpperLimit", jsonName: "HpUpperLimit", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "Capacity", kind: "scalar", localName: "Capacity", jsonName: "Capacity", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "Cylinders", kind: "scalar", localName: "Cylinders", jsonName: "Cylinders", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "Valves", kind: "scalar", localName: "Valves", jsonName: "Valves", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "EngineType", kind: "scalar", localName: "EngineType", jsonName: "EngineType", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "FuelType", kind: "scalar", localName: "FuelType", jsonName: "FuelType", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "Drive", kind: "scalar", localName: "Drive", jsonName: "Drive", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "GearBox", kind: "scalar", localName: "GearBox", jsonName: "GearBox", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "BodyType", kind: "scalar", localName: "BodyType", jsonName: "BodyType", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "IsBlocked", kind: "scalar", localName: "IsBlocked", jsonName: "IsBlocked", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "CrossList", kind: "scalar", localName: "CrossList", jsonName: "CrossList", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ApplicationRow>): ApplicationRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.TecdocID = 0;
        message.Mark = "";
        message.Series = "";
        message.Model = "";
        message.DateFrom = "";
        message.DateTo = "";
        message.EngineCode = "";
        message.Description = "";
        message.Kw = 0;
        message.KwUpperLimit = 0;
        message.Hp = 0;
        message.HpUpperLimit = 0;
        message.Capacity = 0;
        message.Cylinders = 0;
        message.Valves = 0;
        message.EngineType = "";
        message.FuelType = "";
        message.Drive = "";
        message.GearBox = "";
        message.BodyType = "";
        message.IsBlocked = false;
        message.CrossList = [];
        if (value !== undefined)
            reflectionMergePartial<ApplicationRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplicationRow): ApplicationRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* int64 TecdocID = 2 [json_name = "TecdocID"];*/ 2:
                    message.TecdocID = reader.int64().toNumber();
                    break;
                case /* string Mark = 3 [json_name = "Mark"];*/ 3:
                    message.Mark = reader.string();
                    break;
                case /* string Series = 4 [json_name = "Series"];*/ 4:
                    message.Series = reader.string();
                    break;
                case /* string Model = 5 [json_name = "Model"];*/ 5:
                    message.Model = reader.string();
                    break;
                case /* string DateFrom = 6 [json_name = "DateFrom"];*/ 6:
                    message.DateFrom = reader.string();
                    break;
                case /* string DateTo = 7 [json_name = "DateTo"];*/ 7:
                    message.DateTo = reader.string();
                    break;
                case /* string EngineCode = 8 [json_name = "EngineCode"];*/ 8:
                    message.EngineCode = reader.string();
                    break;
                case /* string Description = 9 [json_name = "Description"];*/ 9:
                    message.Description = reader.string();
                    break;
                case /* int64 Kw = 10 [json_name = "Kw"];*/ 10:
                    message.Kw = reader.int64().toNumber();
                    break;
                case /* int64 KwUpperLimit = 11 [json_name = "KwUpperLimit"];*/ 11:
                    message.KwUpperLimit = reader.int64().toNumber();
                    break;
                case /* int64 Hp = 12 [json_name = "Hp"];*/ 12:
                    message.Hp = reader.int64().toNumber();
                    break;
                case /* int64 HpUpperLimit = 13 [json_name = "HpUpperLimit"];*/ 13:
                    message.HpUpperLimit = reader.int64().toNumber();
                    break;
                case /* int64 Capacity = 14 [json_name = "Capacity"];*/ 14:
                    message.Capacity = reader.int64().toNumber();
                    break;
                case /* int64 Cylinders = 15 [json_name = "Cylinders"];*/ 15:
                    message.Cylinders = reader.int64().toNumber();
                    break;
                case /* int64 Valves = 16 [json_name = "Valves"];*/ 16:
                    message.Valves = reader.int64().toNumber();
                    break;
                case /* string EngineType = 17 [json_name = "EngineType"];*/ 17:
                    message.EngineType = reader.string();
                    break;
                case /* string FuelType = 18 [json_name = "FuelType"];*/ 18:
                    message.FuelType = reader.string();
                    break;
                case /* string Drive = 19 [json_name = "Drive"];*/ 19:
                    message.Drive = reader.string();
                    break;
                case /* string GearBox = 20 [json_name = "GearBox"];*/ 20:
                    message.GearBox = reader.string();
                    break;
                case /* string BodyType = 21 [json_name = "BodyType"];*/ 21:
                    message.BodyType = reader.string();
                    break;
                case /* bool IsBlocked = 22 [json_name = "IsBlocked"];*/ 22:
                    message.IsBlocked = reader.bool();
                    break;
                case /* repeated string CrossList = 23 [json_name = "CrossList"];*/ 23:
                    message.CrossList.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplicationRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* int64 TecdocID = 2 [json_name = "TecdocID"]; */
        if (message.TecdocID !== 0)
            writer.tag(2, WireType.Varint).int64(message.TecdocID);
        /* string Mark = 3 [json_name = "Mark"]; */
        if (message.Mark !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Mark);
        /* string Series = 4 [json_name = "Series"]; */
        if (message.Series !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Series);
        /* string Model = 5 [json_name = "Model"]; */
        if (message.Model !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.Model);
        /* string DateFrom = 6 [json_name = "DateFrom"]; */
        if (message.DateFrom !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.DateFrom);
        /* string DateTo = 7 [json_name = "DateTo"]; */
        if (message.DateTo !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.DateTo);
        /* string EngineCode = 8 [json_name = "EngineCode"]; */
        if (message.EngineCode !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.EngineCode);
        /* string Description = 9 [json_name = "Description"]; */
        if (message.Description !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.Description);
        /* int64 Kw = 10 [json_name = "Kw"]; */
        if (message.Kw !== 0)
            writer.tag(10, WireType.Varint).int64(message.Kw);
        /* int64 KwUpperLimit = 11 [json_name = "KwUpperLimit"]; */
        if (message.KwUpperLimit !== 0)
            writer.tag(11, WireType.Varint).int64(message.KwUpperLimit);
        /* int64 Hp = 12 [json_name = "Hp"]; */
        if (message.Hp !== 0)
            writer.tag(12, WireType.Varint).int64(message.Hp);
        /* int64 HpUpperLimit = 13 [json_name = "HpUpperLimit"]; */
        if (message.HpUpperLimit !== 0)
            writer.tag(13, WireType.Varint).int64(message.HpUpperLimit);
        /* int64 Capacity = 14 [json_name = "Capacity"]; */
        if (message.Capacity !== 0)
            writer.tag(14, WireType.Varint).int64(message.Capacity);
        /* int64 Cylinders = 15 [json_name = "Cylinders"]; */
        if (message.Cylinders !== 0)
            writer.tag(15, WireType.Varint).int64(message.Cylinders);
        /* int64 Valves = 16 [json_name = "Valves"]; */
        if (message.Valves !== 0)
            writer.tag(16, WireType.Varint).int64(message.Valves);
        /* string EngineType = 17 [json_name = "EngineType"]; */
        if (message.EngineType !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.EngineType);
        /* string FuelType = 18 [json_name = "FuelType"]; */
        if (message.FuelType !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.FuelType);
        /* string Drive = 19 [json_name = "Drive"]; */
        if (message.Drive !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.Drive);
        /* string GearBox = 20 [json_name = "GearBox"]; */
        if (message.GearBox !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.GearBox);
        /* string BodyType = 21 [json_name = "BodyType"]; */
        if (message.BodyType !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.BodyType);
        /* bool IsBlocked = 22 [json_name = "IsBlocked"]; */
        if (message.IsBlocked !== false)
            writer.tag(22, WireType.Varint).bool(message.IsBlocked);
        /* repeated string CrossList = 23 [json_name = "CrossList"]; */
        for (let i = 0; i < message.CrossList.length; i++)
            writer.tag(23, WireType.LengthDelimited).string(message.CrossList[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ApplicationRow
 */
export const ApplicationRow = new ApplicationRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSearchManyAppsOutputValue$Type extends MessageType<ListSearchManyAppsOutputValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListSearchManyAppsOutputValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchManyAppsOutput }
        ]);
    }
    create(value?: PartialMessage<ListSearchManyAppsOutputValue>): ListSearchManyAppsOutputValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListSearchManyAppsOutputValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSearchManyAppsOutputValue): ListSearchManyAppsOutputValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.SearchManyAppsOutput Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(SearchManyAppsOutput.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSearchManyAppsOutputValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.SearchManyAppsOutput Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            SearchManyAppsOutput.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListSearchManyAppsOutputValue
 */
export const ListSearchManyAppsOutputValue = new ListSearchManyAppsOutputValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEnginesOutput$Type extends MessageType<GetEnginesOutput> {
    constructor() {
        super("grpcProduct_tecdoc.GetEnginesOutput", [
            { no: 1, name: "Count", kind: "scalar", localName: "Count", jsonName: "Count", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Rows", kind: "message", localName: "Rows", jsonName: "Rows", repeat: 1 /*RepeatType.PACKED*/, T: () => GetEnginesRow }
        ]);
    }
    create(value?: PartialMessage<GetEnginesOutput>): GetEnginesOutput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Count = 0;
        message.Rows = [];
        if (value !== undefined)
            reflectionMergePartial<GetEnginesOutput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEnginesOutput): GetEnginesOutput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Count = 1 [json_name = "Count"];*/ 1:
                    message.Count = reader.int64().toNumber();
                    break;
                case /* repeated grpcProduct_tecdoc.GetEnginesRow Rows = 2 [json_name = "Rows"];*/ 2:
                    message.Rows.push(GetEnginesRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEnginesOutput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Count = 1 [json_name = "Count"]; */
        if (message.Count !== 0)
            writer.tag(1, WireType.Varint).int64(message.Count);
        /* repeated grpcProduct_tecdoc.GetEnginesRow Rows = 2 [json_name = "Rows"]; */
        for (let i = 0; i < message.Rows.length; i++)
            GetEnginesRow.internalBinaryWrite(message.Rows[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.GetEnginesOutput
 */
export const GetEnginesOutput = new GetEnginesOutput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIN_QueryData_OrderValue$Type extends MessageType<ListIN_QueryData_OrderValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListIN_QueryData_OrderValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => IN_QueryData_Order }
        ]);
    }
    create(value?: PartialMessage<ListIN_QueryData_OrderValue>): ListIN_QueryData_OrderValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListIN_QueryData_OrderValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListIN_QueryData_OrderValue): ListIN_QueryData_OrderValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.IN_QueryData_Order Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(IN_QueryData_Order.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListIN_QueryData_OrderValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.IN_QueryData_Order Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            IN_QueryData_Order.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListIN_QueryData_OrderValue
 */
export const ListIN_QueryData_OrderValue = new ListIN_QueryData_OrderValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIN_QueryData_FiltersValue$Type extends MessageType<ListIN_QueryData_FiltersValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListIN_QueryData_FiltersValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => IN_QueryData_Filters }
        ]);
    }
    create(value?: PartialMessage<ListIN_QueryData_FiltersValue>): ListIN_QueryData_FiltersValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListIN_QueryData_FiltersValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListIN_QueryData_FiltersValue): ListIN_QueryData_FiltersValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.IN_QueryData_Filters Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(IN_QueryData_Filters.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListIN_QueryData_FiltersValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.IN_QueryData_Filters Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            IN_QueryData_Filters.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListIN_QueryData_FiltersValue
 */
export const ListIN_QueryData_FiltersValue = new ListIN_QueryData_FiltersValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IN_QueryData_Filters$Type extends MessageType<IN_QueryData_Filters> {
    constructor() {
        super("grpcProduct_tecdoc.IN_QueryData_Filters", [
            { no: 1, name: "Field", kind: "scalar", localName: "Field", jsonName: "Field", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => IN_QueryData_Filters_Value }
        ]);
    }
    create(value?: PartialMessage<IN_QueryData_Filters>): IN_QueryData_Filters {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Field = "";
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<IN_QueryData_Filters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IN_QueryData_Filters): IN_QueryData_Filters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Field = 1 [json_name = "Field"];*/ 1:
                    message.Field = reader.string();
                    break;
                case /* repeated grpcProduct_tecdoc.IN_QueryData_Filters_Value Value = 2 [json_name = "Value"];*/ 2:
                    message.Value.push(IN_QueryData_Filters_Value.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IN_QueryData_Filters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Field = 1 [json_name = "Field"]; */
        if (message.Field !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Field);
        /* repeated grpcProduct_tecdoc.IN_QueryData_Filters_Value Value = 2 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            IN_QueryData_Filters_Value.internalBinaryWrite(message.Value[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.IN_QueryData_Filters
 */
export const IN_QueryData_Filters = new IN_QueryData_Filters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLinkedCrossListRow$Type extends MessageType<GetLinkedCrossListRow> {
    constructor() {
        super("grpcProduct_tecdoc.GetLinkedCrossListRow", [
            { no: 1, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Producer", kind: "scalar", localName: "Producer", jsonName: "Producer", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetLinkedCrossListRow>): GetLinkedCrossListRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Name = "";
        message.Producer = "";
        if (value !== undefined)
            reflectionMergePartial<GetLinkedCrossListRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLinkedCrossListRow): GetLinkedCrossListRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Name = 1 [json_name = "Name"];*/ 1:
                    message.Name = reader.string();
                    break;
                case /* string Producer = 2 [json_name = "Producer"];*/ 2:
                    message.Producer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLinkedCrossListRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Name = 1 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Name);
        /* string Producer = 2 [json_name = "Producer"]; */
        if (message.Producer !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Producer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.GetLinkedCrossListRow
 */
export const GetLinkedCrossListRow = new GetLinkedCrossListRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterCondition$Type extends MessageType<FilterCondition> {
    constructor() {
        super("grpcProduct_tecdoc.FilterCondition", [
            { no: 1, name: "Operator", kind: "scalar", localName: "Operator", jsonName: "Operator", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Conditions", kind: "message", localName: "Conditions", jsonName: "Conditions", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterConditionCl }
        ]);
    }
    create(value?: PartialMessage<FilterCondition>): FilterCondition {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Operator = "";
        message.Conditions = [];
        if (value !== undefined)
            reflectionMergePartial<FilterCondition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterCondition): FilterCondition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Operator = 1 [json_name = "Operator"];*/ 1:
                    message.Operator = reader.string();
                    break;
                case /* repeated grpcProduct_tecdoc.FilterConditionCl Conditions = 2 [json_name = "Conditions"];*/ 2:
                    message.Conditions.push(FilterConditionCl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterCondition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Operator = 1 [json_name = "Operator"]; */
        if (message.Operator !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Operator);
        /* repeated grpcProduct_tecdoc.FilterConditionCl Conditions = 2 [json_name = "Conditions"]; */
        for (let i = 0; i < message.Conditions.length; i++)
            FilterConditionCl.internalBinaryWrite(message.Conditions[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.FilterCondition
 */
export const FilterCondition = new FilterCondition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFilterModelValue$Type extends MessageType<ListFilterModelValue> {
    constructor() {
        super("grpcProduct_tecdoc.ListFilterModelValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterModel }
        ]);
    }
    create(value?: PartialMessage<ListFilterModelValue>): ListFilterModelValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListFilterModelValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFilterModelValue): ListFilterModelValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcProduct_tecdoc.FilterModel Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(FilterModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFilterModelValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcProduct_tecdoc.FilterModel Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            FilterModel.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListFilterModelValue
 */
export const ListFilterModelValue = new ListFilterModelValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEnginesRow$Type extends MessageType<GetEnginesRow> {
    constructor() {
        super("grpcProduct_tecdoc.GetEnginesRow", [
            { no: 1, name: "ProductID", kind: "scalar", localName: "ProductID", jsonName: "ProductID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "EngCode", kind: "scalar", localName: "EngCode", jsonName: "EngCode", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ManName", kind: "scalar", localName: "ManName", jsonName: "ManName", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetEnginesRow>): GetEnginesRow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ProductID = 0;
        message.EngCode = "";
        message.ManName = "";
        if (value !== undefined)
            reflectionMergePartial<GetEnginesRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEnginesRow): GetEnginesRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ProductID = 1 [json_name = "ProductID"];*/ 1:
                    message.ProductID = reader.int64().toNumber();
                    break;
                case /* string EngCode = 2 [json_name = "EngCode"];*/ 2:
                    message.EngCode = reader.string();
                    break;
                case /* string ManName = 3 [json_name = "ManName"];*/ 3:
                    message.ManName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEnginesRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ProductID = 1 [json_name = "ProductID"]; */
        if (message.ProductID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ProductID);
        /* string EngCode = 2 [json_name = "EngCode"]; */
        if (message.EngCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.EngCode);
        /* string ManName = 3 [json_name = "ManName"]; */
        if (message.ManName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.ManName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.GetEnginesRow
 */
export const GetEnginesRow = new GetEnginesRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLinkedCrosslistInput$Type extends MessageType<GetLinkedCrosslistInput> {
    constructor() {
        super("grpcProduct_tecdoc.GetLinkedCrosslistInput", [
            { no: 1, name: "ProductId", kind: "scalar", localName: "ProductId", jsonName: "ProductId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Block", kind: "scalar", localName: "Block", jsonName: "Block", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetLinkedCrosslistInput>): GetLinkedCrosslistInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ProductId = 0;
        message.Block = 0;
        if (value !== undefined)
            reflectionMergePartial<GetLinkedCrosslistInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLinkedCrosslistInput): GetLinkedCrosslistInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ProductId = 1 [json_name = "ProductId"];*/ 1:
                    message.ProductId = reader.int64().toNumber();
                    break;
                case /* int64 Block = 2 [json_name = "Block"];*/ 2:
                    message.Block = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLinkedCrosslistInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ProductId = 1 [json_name = "ProductId"]; */
        if (message.ProductId !== 0)
            writer.tag(1, WireType.Varint).int64(message.ProductId);
        /* int64 Block = 2 [json_name = "Block"]; */
        if (message.Block !== 0)
            writer.tag(2, WireType.Varint).int64(message.Block);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.GetLinkedCrosslistInput
 */
export const GetLinkedCrosslistInput = new GetLinkedCrosslistInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInt32Value$Type extends MessageType<ListInt32Value> {
    constructor() {
        super("grpcProduct_tecdoc.ListInt32Value", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListInt32Value>): ListInt32Value {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListInt32Value>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListInt32Value): ListInt32Value {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 Value = 1 [json_name = "Value"];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.Value.push(reader.int64().toNumber());
                    else
                        message.Value.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListInt32Value, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 Value = 1 [json_name = "Value"]; */
        if (message.Value.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.Value.length; i++)
                writer.int64(message.Value[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.ListInt32Value
 */
export const ListInt32Value = new ListInt32Value$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsertManyAppsInput$Type extends MessageType<InsertManyAppsInput> {
    constructor() {
        super("grpcProduct_tecdoc.InsertManyAppsInput", [
            { no: 1, name: "AppId", kind: "scalar", localName: "AppId", jsonName: "AppId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "NewApp", kind: "message", localName: "NewApp", jsonName: "NewApp", T: () => SearchData },
            { no: 3, name: "ProductId", kind: "scalar", localName: "ProductId", jsonName: "ProductId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<InsertManyAppsInput>): InsertManyAppsInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.AppId = 0;
        message.ProductId = 0;
        if (value !== undefined)
            reflectionMergePartial<InsertManyAppsInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsertManyAppsInput): InsertManyAppsInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 AppId = 1 [json_name = "AppId"];*/ 1:
                    message.AppId = reader.int64().toNumber();
                    break;
                case /* grpcProduct_tecdoc.SearchData NewApp = 2 [json_name = "NewApp"];*/ 2:
                    message.NewApp = SearchData.internalBinaryRead(reader, reader.uint32(), options, message.NewApp);
                    break;
                case /* int64 ProductId = 3 [json_name = "ProductId"];*/ 3:
                    message.ProductId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InsertManyAppsInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 AppId = 1 [json_name = "AppId"]; */
        if (message.AppId !== 0)
            writer.tag(1, WireType.Varint).int64(message.AppId);
        /* grpcProduct_tecdoc.SearchData NewApp = 2 [json_name = "NewApp"]; */
        if (message.NewApp)
            SearchData.internalBinaryWrite(message.NewApp, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 ProductId = 3 [json_name = "ProductId"]; */
        if (message.ProductId !== 0)
            writer.tag(3, WireType.Varint).int64(message.ProductId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.InsertManyAppsInput
 */
export const InsertManyAppsInput = new InsertManyAppsInput$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MergeApplicationsInput$Type extends MessageType<MergeApplicationsInput> {
    constructor() {
        super("grpcProduct_tecdoc.MergeApplicationsInput", [
            { no: 1, name: "Old", kind: "scalar", localName: "Old", jsonName: "Old", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "New", kind: "scalar", localName: "New", jsonName: "New", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MergeApplicationsInput>): MergeApplicationsInput {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Old = 0;
        message.New = [];
        if (value !== undefined)
            reflectionMergePartial<MergeApplicationsInput>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MergeApplicationsInput): MergeApplicationsInput {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Old = 1 [json_name = "Old"];*/ 1:
                    message.Old = reader.int64().toNumber();
                    break;
                case /* repeated int64 New = 2 [json_name = "New"];*/ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.New.push(reader.int64().toNumber());
                    else
                        message.New.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MergeApplicationsInput, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Old = 1 [json_name = "Old"]; */
        if (message.Old !== 0)
            writer.tag(1, WireType.Varint).int64(message.Old);
        /* repeated int64 New = 2 [json_name = "New"]; */
        if (message.New.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.New.length; i++)
                writer.int64(message.New[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProduct_tecdoc.MergeApplicationsInput
 */
export const MergeApplicationsInput = new MergeApplicationsInput$Type();
/**
 * @generated ServiceType for protobuf service grpcProduct_tecdoc.ProductEditService
 */
export const ProductEditService = new ServiceType("grpcProduct_tecdoc.ProductEditService", [
    { name: "GetUnmergedApps", options: {}, I: GetUnmergedAppsInput, O: ApplicationResult },
    { name: "SearchManyApps", options: {}, I: SearchManyAppsInput, O: ListSearchManyAppsOutputValue },
    { name: "GetApplicationList", options: {}, I: GetAppListInput, O: ApplicationResult },
    { name: "SearchApp", options: {}, I: SearchAppInput, O: ApplicationResult },
    { name: "GetEngines", options: {}, I: GetEnginesInput, O: GetEnginesOutput },
    { name: "GetApplicationHistory", options: {}, I: GetApplicationHistoryInput, O: ListGetApplicationHistoryOutputValue },
    { name: "GetLinkedCrosslist", options: {}, I: GetLinkedCrosslistInput, O: ListGetLinkedCrossListRowValue },
    { name: "InsertNewApplication", options: {}, I: SearchData, O: BoolValue },
    { name: "ConnectApllication", options: {}, I: ConnectApplicationInput, O: BoolValue },
    { name: "InsertManyApps", options: {}, I: ListInsertManyAppsInputValue, O: BoolValue },
    { name: "MergeApplications", options: {}, I: MergeApplicationsInput, O: BoolValue }
]);
