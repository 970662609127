import { AG_GRID_LOCALE_PL } from "@ag-grid-community/locale";
import { GridReadyEvent } from "ag-grid-enterprise";
import { IOption } from "./Columns";

export const infoPanelGenerator = (event: GridReadyEvent, eGui: HTMLDivElement) => {
    const filterModel = event.api.getFilterModel(); // Pobiera wszystkie aktywne filtry
    let filterInfoText = "";
    const translation = AG_GRID_LOCALE_PL;

    eGui.innerHTML = `
            <div class="bg-[lightblue] p-2 flex space-x-2 items-stretch children:content-center w-full top-panel-container">
            </div>
        `;

    const container = eGui.querySelector(".top-panel-container") as HTMLDivElement;

    // Sprawdzenie, czy są jakieś aktywne filtry
    if (Object.keys(filterModel).length > 0) {
        filterInfoText = "";

        for (const [column, filterDef] of Object.entries(filterModel)) {
            const header = event.api.getColumnDef(column)?.headerName;

            const filterList = filterDef.filterModels !== undefined ? filterDef.filterModels : [filterDef];
            let filterValue = "";
            // even ag grid don't know what is inside filter
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filterList.forEach((filter: any, index: number) => {
                if (filter === null) return;

                if (filter.filterType === "set") {
                    const values: IOption[] = event.api.getColumnDef(column)?.filterParams.values;
                    console.log(filter.values);
                    console.log(values);

                    const val = filter.values.map((el: number | string | boolean) => values && values.find((element: IOption) => element.value === el)?.label).join("', '");
                    filterValue = `<div class='inline-block border border-neutral-800 px-2 py-1 mx-1  bg-neutral-100 rounded-sm'>'${val}'</div>`;
                } else {
                    filterValue = (filter.conditions ? filter.conditions : [filter])
                        .map((c: { type: keyof typeof AG_GRID_LOCALE_PL; filter: string | boolean; filterTo: string }) => {
                            console.log(c);

                            let displayedValue = c.filter ? c.filter : "";

                            if (c.filter === true) {
                                displayedValue = translation.true;
                            } else if (c.filter === false) {
                                displayedValue = translation.false;
                            }

                            return `<div class='inline-block border border-neutral-800 px-2 py-1 mx-1  bg-neutral-100 rounded-sm'><span class='lowercase'>${translation[c.type]}</span>  <b>${displayedValue} ${c.filterTo ? " - " + c.filterTo : ""}</b></div>`;
                        })
                        .join(` <span class="font-bold lowercase">${filter.operator == "OR" ? translation.orCondition : translation.andCondition} </span>`);
                }
                filterInfoText += `<div class='pr-4'>${filter.customTitle !== undefined ? filter.customTitle : header}: ${filterValue} <a data-column="${column}" data-index="${index}" class="filters-one cursor-pointer hover:text-red-800"><i>&#x2715;</i></a></div>`;
            });
        }
    }

    container.innerHTML = filterInfoText;

    const sortModel = event.api.getColumnState().filter((col) => col.sort);

    if (sortModel.length > 0) {
        const sortInfoText = sortModel
            .map((sort) => {
                const header = event.api.getColumnDef(sort.colId)?.headerName;
                const sortDirection = sort.sort === "asc" ? `<i class="ag-icon ag-icon-asc "></i>` : `<i class="ag-icon ag-icon-desc "></i>`;
                return `
                <div class="flex border border-neutral-800 px-2 py-1 mx-1  bg-neutral-100 rounded-sm">${header} ${sortDirection} </div>
                <a data-column="${sort.colId}" class="sort-one cursor-pointer hover:text-red-800"><i>&#x2715;</i></a>
            `;
            })
            .join("");

        container.innerHTML += sortInfoText;
    }

    if (filterInfoText.length === 0 && sortModel.length === 0) {
        eGui.innerHTML = "";
    } else {
        container.innerHTML += `<div class=' pl-5  font-bold ml-auto  grow text-right pr-3' ><div class=" delete-all cursor-pointer hover:text-red-800 inline-block">Usuń</div></div>`;
        attachEvents(event, eGui);
    }

    return eGui;
};

const attachEvents = (event: GridReadyEvent, eGui: HTMLDivElement) => {
    eGui.querySelector(".delete-all")?.addEventListener("click", () => {
        event.api.setFilterModel(null);
        const cols = event.api.getColumnState().map((col) => {
            return { ...col, sort: null };
        });
        event.api.applyColumnState({
            state: cols,
            applyOrder: true,
        });
    });
    eGui.querySelectorAll(".filters-one").forEach((el) => {
        el.addEventListener("click", (e) => {
            const column = (e.currentTarget as HTMLElement).getAttribute("data-column");
            const index: number = parseInt((e.currentTarget as HTMLElement).getAttribute("data-index") ?? "0");

            if (column) {
                const newModel = event.api.getFilterModel();
                console.log(newModel[column]);

                if (newModel[column].filterModels) {
                    newModel[column].filterModels[index] = null;
                } else {
                    newModel[column] = null;
                }
                event.api.setFilterModel(newModel);
                event.api.onFilterChanged();
            }
            //event.api.getFilterInstance(column)?.setModel(null)
        });
    });

    eGui.querySelectorAll(".sort-one").forEach((el) => {
        el.addEventListener("click", (e) => {
            const column = (e.currentTarget as HTMLElement).getAttribute("data-column");

            if (column) {
                const cols = event.api.getColumnState().map((col) => {
                    if (col.colId === column) {
                        return { ...col, sort: null };
                    }
                    return col;
                });
                event.api.applyColumnState({
                    state: cols,
                    applyOrder: true,
                });
            }
        });
    });
};
