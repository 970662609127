// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcProduct_tecdoc.proto" (package "grpcProduct_tecdoc", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ProductEditService } from "./grpcProduct_tecdoc";
import type { MergeApplicationsInput } from "./grpcProduct_tecdoc";
import type { ListInsertManyAppsInputValue } from "./grpcProduct_tecdoc";
import type { ConnectApplicationInput } from "./grpcProduct_tecdoc";
import type { BoolValue } from "./google/protobuf/wrappers";
import type { SearchData } from "./grpcProduct_tecdoc";
import type { ListGetLinkedCrossListRowValue } from "./grpcProduct_tecdoc";
import type { GetLinkedCrosslistInput } from "./grpcProduct_tecdoc";
import type { ListGetApplicationHistoryOutputValue } from "./grpcProduct_tecdoc";
import type { GetApplicationHistoryInput } from "./grpcProduct_tecdoc";
import type { GetEnginesOutput } from "./grpcProduct_tecdoc";
import type { GetEnginesInput } from "./grpcProduct_tecdoc";
import type { SearchAppInput } from "./grpcProduct_tecdoc";
import type { GetAppListInput } from "./grpcProduct_tecdoc";
import type { ListSearchManyAppsOutputValue } from "./grpcProduct_tecdoc";
import type { SearchManyAppsInput } from "./grpcProduct_tecdoc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ApplicationResult } from "./grpcProduct_tecdoc";
import type { GetUnmergedAppsInput } from "./grpcProduct_tecdoc";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcProduct_tecdoc.ProductEditService
 */
export interface IProductEditServiceClient {
    /**
     * @generated from protobuf rpc: GetUnmergedApps(grpcProduct_tecdoc.GetUnmergedAppsInput) returns (grpcProduct_tecdoc.ApplicationResult);
     */
    getUnmergedApps(input: GetUnmergedAppsInput, options?: RpcOptions): UnaryCall<GetUnmergedAppsInput, ApplicationResult>;
    /**
     * @generated from protobuf rpc: SearchManyApps(grpcProduct_tecdoc.SearchManyAppsInput) returns (grpcProduct_tecdoc.ListSearchManyAppsOutputValue);
     */
    searchManyApps(input: SearchManyAppsInput, options?: RpcOptions): UnaryCall<SearchManyAppsInput, ListSearchManyAppsOutputValue>;
    /**
     * @generated from protobuf rpc: GetApplicationList(grpcProduct_tecdoc.GetAppListInput) returns (grpcProduct_tecdoc.ApplicationResult);
     */
    getApplicationList(input: GetAppListInput, options?: RpcOptions): UnaryCall<GetAppListInput, ApplicationResult>;
    /**
     * @generated from protobuf rpc: SearchApp(grpcProduct_tecdoc.SearchAppInput) returns (grpcProduct_tecdoc.ApplicationResult);
     */
    searchApp(input: SearchAppInput, options?: RpcOptions): UnaryCall<SearchAppInput, ApplicationResult>;
    /**
     * @generated from protobuf rpc: GetEngines(grpcProduct_tecdoc.GetEnginesInput) returns (grpcProduct_tecdoc.GetEnginesOutput);
     */
    getEngines(input: GetEnginesInput, options?: RpcOptions): UnaryCall<GetEnginesInput, GetEnginesOutput>;
    /**
     * @generated from protobuf rpc: GetApplicationHistory(grpcProduct_tecdoc.GetApplicationHistoryInput) returns (grpcProduct_tecdoc.ListGetApplicationHistoryOutputValue);
     */
    getApplicationHistory(input: GetApplicationHistoryInput, options?: RpcOptions): UnaryCall<GetApplicationHistoryInput, ListGetApplicationHistoryOutputValue>;
    /**
     * @generated from protobuf rpc: GetLinkedCrosslist(grpcProduct_tecdoc.GetLinkedCrosslistInput) returns (grpcProduct_tecdoc.ListGetLinkedCrossListRowValue);
     */
    getLinkedCrosslist(input: GetLinkedCrosslistInput, options?: RpcOptions): UnaryCall<GetLinkedCrosslistInput, ListGetLinkedCrossListRowValue>;
    /**
     * @generated from protobuf rpc: InsertNewApplication(grpcProduct_tecdoc.SearchData) returns (google.protobuf.BoolValue);
     */
    insertNewApplication(input: SearchData, options?: RpcOptions): UnaryCall<SearchData, BoolValue>;
    /**
     * @generated from protobuf rpc: ConnectApllication(grpcProduct_tecdoc.ConnectApplicationInput) returns (google.protobuf.BoolValue);
     */
    connectApllication(input: ConnectApplicationInput, options?: RpcOptions): UnaryCall<ConnectApplicationInput, BoolValue>;
    /**
     * @generated from protobuf rpc: InsertManyApps(grpcProduct_tecdoc.ListInsertManyAppsInputValue) returns (google.protobuf.BoolValue);
     */
    insertManyApps(input: ListInsertManyAppsInputValue, options?: RpcOptions): UnaryCall<ListInsertManyAppsInputValue, BoolValue>;
    /**
     * @generated from protobuf rpc: MergeApplications(grpcProduct_tecdoc.MergeApplicationsInput) returns (google.protobuf.BoolValue);
     */
    mergeApplications(input: MergeApplicationsInput, options?: RpcOptions): UnaryCall<MergeApplicationsInput, BoolValue>;
}
/**
 * @generated from protobuf service grpcProduct_tecdoc.ProductEditService
 */
export class ProductEditServiceClient implements IProductEditServiceClient, ServiceInfo {
    typeName = ProductEditService.typeName;
    methods = ProductEditService.methods;
    options = ProductEditService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetUnmergedApps(grpcProduct_tecdoc.GetUnmergedAppsInput) returns (grpcProduct_tecdoc.ApplicationResult);
     */
    getUnmergedApps(input: GetUnmergedAppsInput, options?: RpcOptions): UnaryCall<GetUnmergedAppsInput, ApplicationResult> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUnmergedAppsInput, ApplicationResult>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchManyApps(grpcProduct_tecdoc.SearchManyAppsInput) returns (grpcProduct_tecdoc.ListSearchManyAppsOutputValue);
     */
    searchManyApps(input: SearchManyAppsInput, options?: RpcOptions): UnaryCall<SearchManyAppsInput, ListSearchManyAppsOutputValue> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchManyAppsInput, ListSearchManyAppsOutputValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetApplicationList(grpcProduct_tecdoc.GetAppListInput) returns (grpcProduct_tecdoc.ApplicationResult);
     */
    getApplicationList(input: GetAppListInput, options?: RpcOptions): UnaryCall<GetAppListInput, ApplicationResult> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAppListInput, ApplicationResult>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchApp(grpcProduct_tecdoc.SearchAppInput) returns (grpcProduct_tecdoc.ApplicationResult);
     */
    searchApp(input: SearchAppInput, options?: RpcOptions): UnaryCall<SearchAppInput, ApplicationResult> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchAppInput, ApplicationResult>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEngines(grpcProduct_tecdoc.GetEnginesInput) returns (grpcProduct_tecdoc.GetEnginesOutput);
     */
    getEngines(input: GetEnginesInput, options?: RpcOptions): UnaryCall<GetEnginesInput, GetEnginesOutput> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetEnginesInput, GetEnginesOutput>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetApplicationHistory(grpcProduct_tecdoc.GetApplicationHistoryInput) returns (grpcProduct_tecdoc.ListGetApplicationHistoryOutputValue);
     */
    getApplicationHistory(input: GetApplicationHistoryInput, options?: RpcOptions): UnaryCall<GetApplicationHistoryInput, ListGetApplicationHistoryOutputValue> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetApplicationHistoryInput, ListGetApplicationHistoryOutputValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLinkedCrosslist(grpcProduct_tecdoc.GetLinkedCrosslistInput) returns (grpcProduct_tecdoc.ListGetLinkedCrossListRowValue);
     */
    getLinkedCrosslist(input: GetLinkedCrosslistInput, options?: RpcOptions): UnaryCall<GetLinkedCrosslistInput, ListGetLinkedCrossListRowValue> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetLinkedCrosslistInput, ListGetLinkedCrossListRowValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: InsertNewApplication(grpcProduct_tecdoc.SearchData) returns (google.protobuf.BoolValue);
     */
    insertNewApplication(input: SearchData, options?: RpcOptions): UnaryCall<SearchData, BoolValue> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchData, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ConnectApllication(grpcProduct_tecdoc.ConnectApplicationInput) returns (google.protobuf.BoolValue);
     */
    connectApllication(input: ConnectApplicationInput, options?: RpcOptions): UnaryCall<ConnectApplicationInput, BoolValue> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ConnectApplicationInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: InsertManyApps(grpcProduct_tecdoc.ListInsertManyAppsInputValue) returns (google.protobuf.BoolValue);
     */
    insertManyApps(input: ListInsertManyAppsInputValue, options?: RpcOptions): UnaryCall<ListInsertManyAppsInputValue, BoolValue> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListInsertManyAppsInputValue, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: MergeApplications(grpcProduct_tecdoc.MergeApplicationsInput) returns (google.protobuf.BoolValue);
     */
    mergeApplications(input: MergeApplicationsInput, options?: RpcOptions): UnaryCall<MergeApplicationsInput, BoolValue> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<MergeApplicationsInput, BoolValue>("unary", this._transport, method, opt, input);
    }
}
