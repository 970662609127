import { IServerSideGetRowsRequest } from "ag-grid-enterprise";
import { z } from "zod";

export const $FilterConditionCl = z.object({
    Filter: z.string(),
    FilterType: z.string(),
    Type: z.string(),
});

export const $FilterCondition = z.object({
    Operator: z.string(),
    Conditions: z.array($FilterConditionCl),
});

export const $GoFilterModel = z.object({
    ColId: z.string(),
    Filter: $FilterCondition,
});

export type TGoFilterModel = z.infer<typeof $GoFilterModel>;

export const $GoFilter = z.object({
    StartRow: z.number(),
    EndRow: z.number(),
    SortModel: z.array(z.object({ Sort: z.string(), ColId: z.string() })),
    GoFilterModel: z.array($GoFilterModel),
});

export type TGoFilter = z.infer<typeof $GoFilter>;

export const translateFilter = (data: IServerSideGetRowsRequest): TGoFilter => {
    return {
        StartRow: data.startRow ?? 0,
        EndRow: data.endRow ?? 0,
        SortModel: data.sortModel.map((sort) => ({ Sort: sort.sort, ColId: sort.colId })),
        GoFilterModel: data.filterModel
            ? Object.entries(data.filterModel).flatMap(([key, val]) => {
                  if (val.filterType === "multi" && val.filterModels) {
                      return val.filterModels.map(
                          // @ts-ignore ag-grid types
                          (subFilter) => {
                              if (subFilter) {
                                  return {
                                      ColId: key,
                                      Filter: {
                                          Operator: subFilter.operator ? subFilter.operator : subFilter.values && subFilter.values.length > 0 ? "OR" : "",
                                          Conditions:
                                              subFilter.conditions && subFilter.conditions.length > 0
                                                  ? // @ts-ignore ag-grid types
                                                    subFilter.conditions.map((cond) => ({
                                                        Filter: `${cond.filter}${cond.filterTo ? `,${cond.filterTo}` : ""}`,
                                                        FilterType: cond.filterType,
                                                        Type: cond.type,
                                                    }))
                                                  : subFilter.values && subFilter.values.length > 0
                                                    ? // @ts-ignore ag-grid types
                                                      subFilter.values.map((setVal) => ({
                                                          Filter: setVal,
                                                          FilterType: subFilter.filterType,
                                                          Type: `equals`,
                                                      }))
                                                    : [
                                                          {
                                                              Filter: `${subFilter.filter}${subFilter.filterTo ? `,${subFilter.filterTo}` : ""}`,
                                                              FilterType: subFilter.filterType,
                                                              Type: subFilter.type,
                                                          },
                                                      ],
                                      },
                                  } as TGoFilterModel;
                              } else {
                                  return;
                              }
                          },
                      );
                  } else {
                      return [
                          {
                              ColId: key,
                              Filter: {
                                  Operator: val.operator ? val.operator : val.values && val.values.length > 0 ? "OR" : "",
                                  Conditions:
                                      val.conditions && val.conditions.length > 0
                                          ? // @ts-ignore ag-grid types
                                            val.conditions.map((cond) => ({
                                                Filter: `${cond.filter}${cond.filterTo ? `,${cond.filterTo}` : ""}`,
                                                FilterType: cond.filterType,
                                                Type: cond.type,
                                            }))
                                          : val.values && val.values.length > 0
                                            ? // @ts-ignore ag-grid types
                                              val.values.map((setVal) => ({
                                                  Filter: setVal,
                                                  FilterType: val.filterType,
                                                  Type: `equals`,
                                              }))
                                            : [
                                                  {
                                                      Filter: `${val.filter}${val.filterTo ? `,${val.filterTo}` : ""}`,
                                                      FilterType: val.filterType,
                                                      Type: val.type,
                                                  },
                                              ],
                              },
                          } as TGoFilterModel,
                      ];
                  }
              })
            : [],
    };
};
